import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  knownCaveFields,
  organizedCaveFields,
  keywordsCaveFields,
} from './dicts';

class MappingInput extends React.Component {
  constructor(props) {
    super(props);

    this.selector = React.createRef();
    this.handleChange = this.handleChange.bind(this);

    // Code pour Finder Vision
    this._availableVisionGroups = !this.props.forVision ? [] : ['Légal'];
    if (this.props.visionType && this.props.visionType === 'all')
      this._availableVisionGroups.push('Informations primaires');

    this._availableVisionFields = !this.props.forVision ? [] : ['siret'];
    if (this.props.visionType && this.props.visionType === 'all')
      this._availableVisionFields.push('bottlCommercialStatus');
  }

  handleChange(evt) {
    this.props.onChange(evt.target.value, this.props.csvColumn);
  }

  componentDidMount() {
    // Auto-mapping
    setTimeout(() => {
      const suppliedColumn = this.props.csvColumn
        .toLowerCase()
        .trim()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');
      for (let i = 0; i < keywordsCaveFields.length; i++) {
        const attempt = keywordsCaveFields[i];

        if (!attempt.keywords.includes(suppliedColumn)) continue;

        if (this.props.forVision)
          if (!this._availableVisionFields.includes(attempt.field)) continue;

        if (window._unavailableFields.includes(attempt.field)) continue;

        const event = new Event('change', { bubbles: true });
        event.simulated = true;
        this.selector.current.value = attempt.field;
        this.selector.current.dispatchEvent(event);
        break;
      }
    }, 125);
  }

  render() {
    const { current } = this.props;
    const unavailableFields = window._unavailableFields;

    return (
      <select
        ref={this.selector}
        onChange={this.handleChange}
        defaultValue={current}
      >
        <option>Ne pas importer</option>
        {organizedCaveFields
          .filter(
            !this.props.forVision
              ? () => true
              : (g) => this._availableVisionGroups.includes(g.name)
          )
          .map((g, k) => (
            <optgroup key={`${g}_${k}`} label={g.name}>
              {g.fields
                .filter(
                  !this.props.forVision
                    ? () => true
                    : (f) => this._availableVisionFields.includes(f)
                )
                .map((f, ki) => (
                  <option
                    key={`${f}_${ki}`}
                    value={f}
                    disabled={
                      unavailableFields.includes(f) && f !== '__custom__'
                    }
                  >
                    {knownCaveFields[f]}
                  </option>
                ))}
            </optgroup>
          ))}
      </select>
    );
  }
}

export default MappingInput;
