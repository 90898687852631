export const knownCaveFields = {
  name: 'Nom de la cave',
  phone: 'Numéro de téléphone',
  importemail: 'Adresse email',
  website: 'URL du site internet',
  formatted_address: 'Adresse de la cave',
  imported_image_url: "Lien vers l'image de la cave",
  imported_image_filename: 'Fichier image de la cave',
  gerant_firstname: 'Prénom du gérant',
  gerant_lastname: 'Nom du gérant',
  route: 'Rue',
  street_number: 'Numéro de rue',
  locality: 'Ville',
  postal_code: 'Code postal',
  lat: 'Latitude',
  lng: 'Longitude',
  formejuridique: 'Forme juridique',
  raisonsociale: 'Raison sociale',
  siret: 'Numéro SIRET',
  siren: 'Numéro SIREN',
  fbAssumedURL: 'URL de la page Facebook',
  instagramAssumedURL: 'URL de la page Instagram',
  administrative_area_level_1: 'Région',
  tva: 'Numéro de TVA',
  iris: 'Code IRIS',
  insee: 'Numéro INSEE',
  description: 'Description',
  createdAt: 'Date de création',
  bottlCommercialStatus: 'Statut commercial',
  __custom__: 'Créer un champ personnalisé',
};

export const organizedCaveFields = [
  {
    name: 'Informations primaires',
    fields: [
      'name',
      'phone',
      'importemail',
      'website',
      'description',
      'imported_image_url',
      'bottlCommercialStatus',
    ],
  },
  {
    name: 'Légal',
    fields: [
      'formejuridique',
      'raisonsociale',
      'siren',
      'siret',
      'tva',
      'iris',
      'insee',
      'createdAt',
    ],
  },
  {
    name: 'Géographie',
    fields: [
      'formatted_address',
      'lat',
      'lng',
      'administrative_area_level_1',
      'postal_code',
      'locality',
      'route',
      'street_number',
    ],
  },
  {
    name: 'Gérant',
    fields: ['gerant_lastname', 'gerant_firstname'],
  },
  {
    name: 'Réseaux sociaux',
    fields: ['instagramAssumedURL', 'fbAssumedURL'],
  },
  {
    name: 'Avancé',
    fields: ['__custom__'],
  },
];

export const keywordsCaveFields = [
  {
    field: 'name',
    keywords: ['nom', 'cave', 'raison sociale', 'nom du commerce'],
  },
  {
    field: 'phone',
    keywords: [
      'telephone',
      'phone',
      'tel',
      'numero de telephone',
      'numero',
      'n° de telephone',
    ],
  },
  {
    field: 'website',
    keywords: ['lien', 'site', 'website'],
  },
  {
    field: 'siret',
    keywords: ['siret', 'numero siret'],
  },
  {
    field: 'siren',
    keywords: ['siren', 'numero siren'],
  },
  {
    field: 'locality',
    keywords: ['ville', 'city'],
  },
  {
    field: 'postal_code',
    keywords: ['code postal', 'cp', 'zip'],
  },
  {
    field: 'importemail',
    keywords: ['email', 'mail', 'courriel', 'adresse email'],
  },
  {
    field: 'formatted_address',
    keywords: ['adresse'],
  },
  {
    field: 'lat',
    keywords: ['lat', 'latitude'],
  },
  {
    field: 'lng',
    keywords: ['lng', 'longitude'],
  },
  {
    field: 'createdAt',
    keywords: [
      'created',
      'createdat',
      'date de creation',
      'creation',
      'date creation',
    ],
  },
  {
    field: 'tva',
    keywords: ['vat', 'tva', 'numero de tva', 'numero tva'],
  },
  {
    field: 'iris',
    keywords: ['iris', 'numero iris', 'code iris'],
  },
  {
    field: 'insee',
    keywords: ['insee', 'numero insee', 'code insee'],
  },
  {
    field: 'fbAssumedURL',
    keywords: ['fb', 'facebook'],
  },
  {
    field: 'instagramAssumedURL',
    keywords: ['ig', 'instagram'],
  },
  {
    field: 'bottlCommercialStatus',
    keywords: ['statut', 'type'],
  },
  {
    field: 'gerant_lastname',
    keywords: ['nom de famille', 'nom du gerant', 'nom gerant'],
  },
  {
    field: 'gerant_firstname',
    keywords: ['prenom', 'gerant', 'prenom du gerant', 'prenom gerant'],
  },
  {
    field: 'imported_image_url',
    keywords: ['image', 'img', 'avatar', 'picture', 'photo'],
  },
];
