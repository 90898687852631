import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import '../../scss/containers/welcome.scss';

class Welcome extends React.Component {
  state = {};

  render() {
    return (
      <Container className="dashboard as-welcome-finder">
        <h3 className="as-welcome-title">Bienvenue dans votre équipe Finder</h3>
        <p>
          À partir de maintenant, vous avez accès au moteur de recherche, aux
          inmails, et à la gestion de votre abonnement sur Finder
        </p>
        <a
          href={`${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/`}
          type="button"
          target="_blank"
          className="btn btn-primary"
        >
          Accéder au moteur de recherche
        </a>
        <div className="sub-links">
          <a href="/finder/inmails">Consulter mes inmails</a>
          <a href="/finder/subscription">Gérer mon abonnement</a>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Welcome);
