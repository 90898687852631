import React from 'react';
import Block from './Block';

export default ({ blocks, onCloseBlock }) => {
  const anyBlockVisible = blocks.filter((b) => b['visible'] == '1').length > 0;

  if (!anyBlockVisible) return null;

  const handleClose = (blockId) => {
    let ignoredBlocks = localStorage.getItem('newsfeed_ignored_blocks');

    if (!ignoredBlocks) ignoredBlocks = [];
    else ignoredBlocks = JSON.parse(ignoredBlocks);

    ignoredBlocks.push(blockId);
    localStorage.setItem(
      'newsfeed_ignored_blocks',
      JSON.stringify(ignoredBlocks)
    );
    onCloseBlock(blockId);
  };

  return (
    <div className="as-feed">
      <h3 className="page-title">News du moment</h3>
      <p className="as-page-subtitle">
        Voici les informations importantes que nous voulons vous partager{' '}
      </p>
      <div className="as-wrapper">
        {blocks
          .filter((block) => block.visible == true)
          .map((block, idx) => (
            <Block
              isOpen={idx === 0 ? true : false}
              key={block.pk}
              block={block}
              onClose={handleClose}
            />
          ))}
      </div>
    </div>
  );
};
