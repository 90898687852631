import React from 'react';
import { useState } from 'react';

export default ({
  isOpen,
  block,
  onClose,
  showNoPictureAtAll,
  hideMinimizer,
}) => {
  const [open, setOpen] = useState(isOpen || false);

  return (
    <div key={block.pk} className={`as-block ${open ? 'as-active' : ''}`}>
      <div className="as-controls">
        {!hideMinimizer && (
          <>
            <button onClick={() => setOpen(false)} className="as-minimizer">
              <img src="/img/icons/minus.svg" />
            </button>
            <button onClick={() => setOpen(true)} className="as-maximizer">
              <img src="/img/icons/plus.svg" />
            </button>
          </>
        )}
        <button onClick={() => onClose(block.pk)} className="as-closer">
          <img src="/img/icons/close.svg" />
        </button>
      </div>

      <div className="as-block-content">
        <div className="as-cols">
          {!showNoPictureAtAll && block.picture && (
            <div className="as-left">
              <img
                src={`${process.env.REACT_APP_BOTTL_WEBROOT}/assets/img/newsfeed/${block.picture}`}
                alt={`Visuel - ${block.title}`}
              />
            </div>
          )}

          <div className="as-right">
            <div className="as-block-header">
              {!showNoPictureAtAll && !block.picture && (
                <img
                  className="as-smaller"
                  src={`${process.env.REACT_APP_BOTTL_WEBROOT}/assets/img/favicon.png`}
                  alt={`Visuel - ${block.title}`}
                />
              )}

              <div className="as-heading">
                {block.title && <h3>{block.title}</h3>}

                {block.subtitle && <h4>{block.subtitle}</h4>}
              </div>
            </div>

            <div className="as-block-inner">
              {block.content && <p>{block.content}</p>}

              {block.cta_link && (
                <a href={block.cta_link} target="_blank">
                  {block.cta_text}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
