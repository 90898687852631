import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';

class Subscription extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    availablePlans: [],
    newPlan: null,
    isPaying: false,
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    // this._stripeApiKey = 'pk_test_GUCioDjvj6bYsK6duQJsj6j600YKKpgrE8';
    this._stripeApiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    console.log(process.env);

    // this.handleTrialCancel = this.handleTrialCancel.bind(this);
    this.handleSubscriptionCancel = this.handleSubscriptionCancel.bind(this);
    this.handlePlanPreview = this.handlePlanPreview.bind(this);
    this.handlePlanChangeConfirm = this.handlePlanChangeConfirm.bind(this);

    this.shouldSuggestUpgrade = window.location.search.includes('upgrade=1');
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState(
          {
            subscription: res.data.content.subscription,
          },
          () => {
            axios
              .post(
                `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/plans/get`,
                {
                  id: user.id,
                  token: user.token,
                  passphrase: user.passphrase,
                }
              )
              .then((res) => {
                this.setState(
                  {
                    availablePlans: res.data.content.plans,
                    loaded: true,
                  },
                  () => {
                    if (this.shouldSuggestUpgrade) {
                      // Cas Essential
                      if (this.state.subscription.plan.pk == 1)
                        document.querySelector(
                          `.as-subscription-wrapper select`
                        ).value = 3;
                      else
                        document.querySelector(
                          `.as-subscription-wrapper select`
                        ).value = parseInt(this.state.subscription.plan.pk) + 1;

                      setTimeout(() => {
                        document
                          .querySelector(`.as-subscription-wrapper select`)
                          .dispatchEvent(
                            new Event('change', {
                              bubbles: true,
                              simulated: true,
                              value:
                                parseInt(this.state.subscription.plan.pk) + 1,
                            })
                          );
                      }, 300);
                    }
                  }
                );
              });
          }
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  handlePlanPreview(evt) {
    if (!evt.target.value) this.setState({ newPlan: null });
    else
      this.setState({
        newPlan: this.state.availablePlans.filter(
          (p) => p.pk == evt.target.value
        )[0],
      });
  }

  async handlePlanChangeConfirm() {
    const { subscription, newPlan } = this.state;

    Swal.fire({
      title: `Information`,
      // prettier-ignore
      html: `Vous vous apprêtez à modifier votre abonnement.<br />
             Vous allez passer du Plan ${subscription.plan.name} au Plan ${newPlan.name}, soit de ${subscription.plan.amount}€ à ${newPlan.amount}€ par mois.<br /><br />
             ${parseInt(subscription.plan.amount) < parseInt(newPlan.amount) ? 'La différence vous sera facturée immédiatement.' : 'Un membre de notre équipe va vous recontacter pour procéder au changement.'}`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then(async (result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      // Case when user subscribed to a free plan
      if (subscription.plan.is_free == true) {
        const { clientSecret, intentId } = await this.requestStripePayment(
          newPlan.pk
        );
        this.createStripeForm(clientSecret, newPlan.pk, intentId);
      }
      // Case when user already subscribed to a paid plan
      else {
        const {
          authentication: { user },
        } = this.props;
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/teams/update-subscription`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              plan: newPlan.pk,
            }
          )
          .then((res) => {
            this.setState(
              {
                loaded: true,
                loading: false,
              },
              () => {
                if (
                  parseInt(subscription.plan.amount) < parseInt(newPlan.amount)
                )
                  this._showNotification(
                    'Votre abonnement a bien été mis à jour'
                  );
                else
                  this._showNotification(
                    'Un membre de notre équipe va vous recontacter pour procéder au changement'
                  );

                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              }
            );
          });
      }
    });
  }

  handleTrialCancel() {
    Swal.fire({
      title: 'Attention',
      text: `L'annulation de votre période d'essai est irréversible, et mettra fin à votre accès à la plateforme Finder`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });
      const {
        authentication: { user },
      } = this.props;

      axios
        .post(
          `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/teams/cancel-trial`,
          {
            id: user.id,
            token: user.token,
            passphrase: user.passphrase,
          }
        )
        .then((res) => {
          this.setState(
            {
              loaded: true,
              loading: false,
              subscription: null,
            },
            () => {
              this._showNotification(
                "Votre période d'essai a bien été annulée"
              );
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          );
        });
    });
  }
  handleSubscriptionCancel() {
    Swal.fire({
      title: 'Attention',
      text: `L'annulation de votre abonnement est irréversible, et mettra fin à votre accès à la plateforme Finder`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });
      const {
        authentication: { user },
      } = this.props;

      axios
        .post(
          `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/teams/cancel-subscription`,
          {
            id: user.id,
            token: user.token,
            passphrase: user.passphrase,
          }
        )
        .then((res) => {
          this.setState(
            {
              loaded: true,
              loading: false,
            },
            () => {
              this._showNotification(
                "Une notification a été envoyée à l'équipe Finder, vous allez être rappelé."
              );
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          );
        });
    });
  }

  async createStripeForm(clientSecret, planId, intentId) {
    const stripe = Stripe(this._stripeApiKey);
    const options = { clientSecret };
    const elements = stripe.elements(options);

    // Create and mount the Payment Element
    this.setState({ isPaying: true, loading: false, loaded: true });
    const paymentForm = document.querySelector('#payment-form');
    const paymentElement = elements.create('payment');
    paymentElement.mount('#payment-element');

    paymentForm.addEventListener('submit', async (event) => {
      event.preventDefault();

      const { error } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        this._showError(error.message);
      } else {
        await this.confirmStripePayment(planId, intentId);

        Swal.fire({
          icon: 'success',
          title: `Félicitations`,
          // prettier-ignore
          html: `Bravo ! Votre abonnement est confirmé.<br /><br />Vous allez recevoir un email de confirmation.`,
          showCancelButton: false,
          confirmButtonText: `D'accord`,
        }).then(async (result) => {
          if (result.isDenied) return;
          if (!result.isConfirmed) return;
          window.location.reload();
        });
      }
    });
  }

  async requestStripePayment(planId) {
    const {
      authentication: { user },
    } = this.props;
    let response = await fetch(
      `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/subscribe`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          planId,
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        }),
      }
    )
      .then((r) => r.json())
      .catch((e) => console.log(e));

    if (response.code >= 400) {
      this._showNotification(
        "Une erreur est survenue. Veuillez nous recontacter pour plus d'informations."
      );
      // window.location = '/';
      return;
    }

    return {
      clientSecret: response.content.stripe_client_secret,
      intentId: response.content.stripe_intent_id,
    };
  }

  async confirmStripePayment(planId, intentId) {
    const {
      authentication: { user },
    } = this.props;

    const response = await fetch(
      `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/subscribe/confirm`,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          planId,
          intentId,
        }),
      }
    ).then((r) => r.json());

    return response;
  }

  render() {
    let {
      loaded,
      loading,
      subscription,
      availablePlans,
      newPlan,
      isPaying,
    } = this.state;

    // let inTrial =
    //   loaded && subscription && subscription.team
    //     ? subscription.team.stripe_trial_ends_at &&
    //       moment(subscription.team.stripe_trial_ends_at).isAfter()
    //     : false;

    let recentlyDowngraded =
      loaded && subscription && subscription.team
        ? subscription.team.downgrade_requested_at &&
          moment(subscription.team.downgrade_requested_at).isAfter(
            moment().subtract(1, 'day')
          )
        : false;

    let recentlyUpgraded =
      loaded && subscription && subscription.team
        ? subscription.team.plan_last_changed_at &&
          moment(subscription.team.plan_last_changed_at).isAfter(
            moment().subtract(1, 'day')
          )
        : false;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {loaded && (
          <Row>
            <Col md={12}>
              <h3 className="page-title">Mon abonnement</h3>
            </Col>
          </Row>
        )}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            <Row>
              {/* CARD 1 */}
              <div className="as-subscription-wrapper">
                <div className="header">
                  <span>Plan</span>
                  <span>{subscription.plan.name}</span>
                  <div className="badge">
                    {subscription.is_owner == true
                      ? 'Propriétaire'
                      : 'Membre invité'}
                  </div>
                </div>
                <div className="body">
                  {subscription.plan.features.map((f, idx) => (
                    <span key={idx}>✓&nbsp;{f.label}</span>
                  ))}
                  {subscription.is_owner == true && (
                    <p className="subscription-price">
                      <br />
                      {`${
                        subscription.plan.is_free == true
                          ? 'Gratuit'
                          : `${subscription.plan.amount}€/mois`
                      }`}
                    </p>
                  )}
                </div>
                {subscription.is_owner && (
                  <div className="footer">
                    <span>
                      <strong>Date de souscription:</strong>
                      {subscription.subscription_date}
                    </span>
                    {/* {!inTrial && ( */}
                    {true && (
                      <React.Fragment>
                        {!subscription.team.cancel_requested_at && (
                          <React.Fragment>
                            <span>
                              <strong>Date de renouvellement:</strong>
                              {subscription.renew_date}
                            </span>
                            <button
                              className="as-finder-cancel-subscription-link"
                              onClick={this.handleSubscriptionCancel}
                            >
                              <u>Je demande une annulation de mon plan</u>
                            </button>
                          </React.Fragment>
                        )}
                        {subscription.team.cancel_requested_at && (
                          <span className="as-finder-subscription-cancel-request">
                            Annulation demandée le{' '}
                            {moment(
                              subscription.team.cancel_requested_at
                            ).format('DD/MM/Y')}
                          </span>
                        )}
                      </React.Fragment>
                    )}
                    {/* {inTrial && ( */}
                    {false && (
                      <React.Fragment>
                        <span>
                          <strong>Date de fin de la période d'essai:</strong>
                          {moment(
                            subscription.team.stripe_trial_ends_at
                          ).format('DD/MM/Y')}
                        </span>
                        <button
                          className="as-finder-cancel-trial-link"
                          onClick={this.handleTrialCancel}
                        >
                          <u>Mettre fin à ma période d'essai</u>
                        </button>
                      </React.Fragment>
                    )}
                    {/* {subscription.is_owner && ( */}
                    {/*   <a */}
                    {/*     className="btn btn-primary" */}
                    {/*     href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`} */}
                    {/*   > */}
                    {/*     Voir les plans disponibles */}
                    {/*   </a> */}
                    {/* )} */}
                  </div>
                )}
              </div>
              {/* CARD 2 - PICKER */}
              {subscription.is_owner == true &&
                // !inTrial &&
                true &&
                !subscription.team.cancel_requested_at &&
                !recentlyDowngraded &&
                !recentlyUpgraded && (
                  <div className="as-subscription-wrapper as-bordered">
                    <div className="header">
                      <span className="title">
                        Vous souhaitez modifier votre abonnement ?
                      </span>
                      <select onChange={this.handlePlanPreview}>
                        <option>Sélectionnez un nouvel abonnement</option>
                        {availablePlans &&
                          availablePlans
                            .filter((p) => p.pk != subscription.plan.pk)
                            .filter((p) => p.is_readily_available == true)
                            .map((p, k) => (
                              <option key={k} value={p.pk}>
                                {p.name}
                              </option>
                            ))}
                      </select>
                    </div>
                    {!newPlan && (
                      <span className="plan-notice">
                        Veuillez sélectionner un plan pour en voir les détails
                      </span>
                    )}
                    {newPlan && (
                      <div className="body">
                        {newPlan.features.map((f, k) => (
                          <span key={k}>✓&nbsp;{f.label}</span>
                        ))}
                        <p>{newPlan.amount}€/mois</p>
                        <button onClick={this.handlePlanChangeConfirm}>
                          Choisir cet abonnement
                        </button>
                      </div>
                    )}
                  </div>
                )}
              {/* CARD 2 - DOWNGRADE */}
              {subscription.is_owner == true &&
                // !inTrial &&
                true &&
                !subscription.team.cancel_requested_at &&
                recentlyDowngraded && (
                  <div className="as-subscription-wrapper as-bordered as-message">
                    <div className="header">
                      <span className="title">Modification de mon Plan</span>
                    </div>
                    <div className="body light-text">
                      <p>
                        J'ai fait une demande de changement de Plan, je serai
                        rencontacté par Bottl dans les prochaines heures pour
                        procéder à la modification de mon Plan.
                      </p>
                    </div>
                  </div>
                )}
              {/* CARD 2 - UPGRADE */}
              {subscription.is_owner == true &&
                // !inTrial &&
                true &&
                !subscription.team.cancel_requested_at &&
                recentlyUpgraded && (
                  <div className="as-subscription-wrapper as-bordered as-message">
                    <div className="header">
                      <span className="title">Amélioration de votre Plan</span>
                    </div>
                    <div className="body light-text">
                      <p>
                        Vous avez fait le choix d'améliorer votre Plan Finder en
                        choisissant le Plan {subscription.plan.name} le{' '}
                        {moment(subscription.team.plan_last_changed_at).format(
                          'DD/MM/Y'
                        )}
                      </p>
                    </div>
                  </div>
                )}
              {/* CARD 2 - CANCEL */}
              {subscription.is_owner == true &&
                true &&
                subscription.team.cancel_requested_at && (
                  <div className="as-subscription-wrapper as-bordered as-message">
                    <div className="header">
                      <span className="title">Annulation de votre Plan</span>
                    </div>
                    <div className="body light-text">
                      <p>
                        Vous avez demandé une annulation de votre Plan le{' '}
                        {moment(subscription.team.cancel_requested_at).format(
                          'DD/MM/Y'
                        )}
                        . Un membre de notre équipe Bottl va vous recontacter
                        dans les prochaines heures pour faire le point avec vous
                        sur votre demande d'annulation.
                      </p>
                    </div>
                  </div>
                )}
            </Row>
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
        {/* POPUP - PAYMENT */}
        <div className={`as-payment-popup ${!isPaying ? 'hidden' : ''}`}>
          <div className="as-popup-content">
            <div className="header">
              <span className="title">Souscription à l'abonnement</span>
            </div>
            <div className="body">
              <form id="payment-form">
                <div id="payment-element"></div>
                <button
                  id="submit"
                  class="finder-cta as-submit-btn as-submit-cta"
                  type="submit"
                >
                  Confirmer le paiement
                </button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Subscription);
