import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Dashboard extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    history: [],
  };

  openShop(id) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/${id}-.html`,
      '_blank'
    );
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/history`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((res) => {
            this.setState({
              history: res.data.content.history,
              loaded: true,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  render() {
    let { loaded, loading, subscription, history } = this.state;

    // Quotas restants / En pourcentage
    let remainingQuotas = {
      inmails: subscription
        ? (subscription.quotas.inmails * 100) / subscription.plan.quota_inmails
        : null,
      display: subscription
        ? (subscription.quotas.display * 100) / subscription.plan.quota_display
        : null,
    };

    const classes = {
      th:
        'MuiTableCell-root MuiTableCell-head material-table__cell material-table__cell--sort material-table__cell-right MuiTableCell-alignLeft MuiTableCell-paddingNone',
      tr: 'MuiTableRow-root MuiTableRow-head',
      thead: 'MuiTableHead-root',
      table: 'MuiTable-root material-table',
      tbody: 'MuiTableBody-root',
      span:
        'MuiButtonBase-root MuiTableSortLabel-root material-table__sort-label',
    };

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            {/* 1ST ROW */}
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <h3 className="page-title">Mon espace Finder</h3>
                  </Col>
                  <Col md={6} align="right">
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      href="/finder/subscription"
                    >
                      Abonnement
                    </Button>
                    {subscription.is_owner && !subscription.plan.is_free && (
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        href="/finder/team"
                      >
                        Équipe
                      </Button>
                    )}
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      href="/finder/inmails"
                    >
                      Inmails
                    </Button>
                    {['Professional', 'Entreprise'].includes(
                      subscription.plan.name
                    ) && (
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        href="/finder/intelligence"
                      >
                        Mes bases
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* UPSELL SI 80% CONSOMMÉS */}
            {(remainingQuotas.display || remainingQuotas.inmails) &&
              (remainingQuotas.display < 20 || remainingQuotas.inmails < 20) &&
              subscription &&
              subscription.plan.name != 'Entreprise' &&
              subscription.plan.name != 'Professional' &&
              subscription.is_owner == true && (
                <Row>
                  <Col>
                    <Row>
                      <Col md={12} xl={12} lg={12} xs={12}>
                        <p className="as-finder-upsell">
                          {(remainingQuotas.display < 20 ||
                            remainingQuotas.inmails < 20) && (
                            <>
                              Vous avez consommé 80% de vos crédits (fiches ou
                              inmails). Pour bénéficier de plus de crédits,
                              passez au prochain plan en{' '}
                              <a href="/finder/subscription/?upgrade=1">
                                cliquant ici
                              </a>
                              .
                            </>
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

            {/* 2ND ROW */}
            <Row>
              <Col>
                <Row>
                  {/* METRIC 1 */}
                  <Col md={12} xl={4} lg={6} xs={12}>
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Plan</h5>
                          </div>
                          <div className="dashboard__total">
                            <p className="dashboard__total-stat">
                              {subscription.plan.name}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* METRIC 2 */}
                  {subscription.is_owner && (
                    <Col md={12} xl={4} lg={6} xs={12}>
                      <Card>
                        <CardBody className="dashboard__card-widget">
                          <div>
                            <div className="card__title">
                              <h5 className="bold-text">Montant</h5>
                            </div>
                            <div className="dashboard__total">
                              <p className="dashboard__total-stat">
                                {subscription.plan.amount}€
                                {parseInt(subscription.team.members_count) >
                                  0 && (
                                  <>
                                    {' '}
                                    + ({subscription.team.members_count} x{' '}
                                    {subscription.plan.subplan.amount}€)
                                  </>
                                )}{' '}
                                par mois
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  {/* METRIC 3 */}
                  {subscription.is_owner && (
                    <Col md={12} xl={4} lg={6} xs={12}>
                      <Card>
                        <CardBody className="dashboard__card-widget">
                          <div>
                            <div className="card__title">
                              <h5 className="bold-text">Membres</h5>
                            </div>
                            <div className="dashboard__total">
                              <p className="dashboard__total-stat">
                                {parseInt(subscription.team.members_count) > 0
                                  ? `${subscription.team.members_count} membre${
                                      subscription.team.members_count > 1
                                        ? 's'
                                        : ''
                                    }`
                                  : 'Aucun'}
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  {/* METRIC 4 */}
                  <Col
                    md={12}
                    xl={subscription.is_owner ? 6 : 4}
                    lg={6}
                    xs={12}
                  >
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Fiches consultées</h5>
                          </div>
                          <div className="dashboard__total">
                            {/* prettier-ignore */}
                            <svg style={{ marginRight: 8 }}xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="20" fill="#01efba" viewBox="0 0 512 512"><path d="M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 278.719c-51.442 0-93.292-41.851-93.292-93.293S204.559 92.134 256 92.134s93.291 41.851 93.291 93.293-41.85 93.292-93.291 93.292z"/></svg>
                            <p className="dashboard__total-stat">
                              {subscription.plan.quota_display -
                                subscription.quotas.display}{' '}
                              / {subscription.plan.quota_display}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* METRIC 5 */}
                  <Col
                    md={12}
                    xl={subscription.is_owner ? 6 : 4}
                    lg={6}
                    xs={12}
                  >
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Inmails envoyés</h5>
                          </div>
                          <div className="dashboard__total">
                            {/* prettier-ignore */}
                            <svg style={{marginRight: 10 }}enable-background="new 0 0 512 512" viewBox="0 0 512 512" width="20" fill="#01efba" xmlns="http://www.w3.org/2000/svg"><path d="m298.789 313.693c-12.738 8.492-27.534 12.981-42.789 12.981-15.254 0-30.05-4.489-42.788-12.981l-209.803-139.873c-1.164-.776-2.298-1.585-3.409-2.417v229.197c0 26.278 21.325 47.133 47.133 47.133h417.733c26.278 0 47.133-21.325 47.133-47.133v-229.198c-1.113.834-2.249 1.645-3.416 2.422z"/><path d="m20.05 148.858 209.803 139.874c7.942 5.295 17.044 7.942 26.146 7.942 9.103 0 18.206-2.648 26.148-7.942l209.803-139.874c12.555-8.365 20.05-22.365 20.05-37.475 0-25.981-21.137-47.117-47.117-47.117h-417.766c-25.98.001-47.117 21.137-47.117 47.142 0 15.085 7.496 29.085 20.05 37.45z"/></svg>
                            <p className="dashboard__total-stat">
                              {subscription.plan.quota_inmails -
                                subscription.quotas.inmails}{' '}
                              / {subscription.plan.quota_inmails}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* TABLE */}
                  <Col md={12} xl={12} lg={12} xs={12}>
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">
                              Fiches récemment consultées
                            </h5>
                          </div>
                          <div className="material-table__wrap">
                            {history.length > 0 && (
                              <Table className="material-table">
                                <thead className={classes.thead}>
                                  <tr className={classes.tr}>
                                    <TableCell
                                      className="material-table__cell material-table__cell--sort material-table__cell-right"
                                      align={`left`}
                                      padding={'default'}
                                    >
                                      VILLE
                                    </TableCell>
                                    <TableCell
                                      className="material-table__cell material-table__cell--sort material-table__cell-right"
                                      align={`left`}
                                      padding={'default'}
                                    >
                                      CAVE
                                    </TableCell>
                                    <TableCell
                                      className="material-table__cell material-table__cell--sort material-table__cell-right"
                                      align={`left`}
                                      padding={'default'}
                                    >
                                      LE
                                    </TableCell>
                                  </tr>
                                </thead>
                                <TableBody>
                                  {history.map((row) => (
                                    <TableRow
                                      key={row.pk}
                                      className="material-table__row material-table__parent-row"
                                      onClick={() => this.openShop(row.id)}
                                    >
                                      <TableCell>{row.locality}</TableCell>
                                      <TableCell>
                                        {row.name}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="w-6 h-6"
                                          width="16"
                                          style={{
                                            marginLeft: 8,
                                            color: 'blue',
                                          }}
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                                          />
                                        </svg>
                                      </TableCell>
                                      <TableCell>
                                        {moment(row.at).format(
                                          'DD/MM/Y à HH:mm'
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                            {history.length === 0 && (
                              <div className="history-placeholder">
                                <p>Aucune fiche consultée pour le moment</p>
                                <Button
                                  color="primary"
                                  outline
                                  size="md"
                                  target="_blank"
                                  href={`${process.env.REACT_APP_BOTTL_WEBROOT}/public.html`}
                                >
                                  Accéder à Finder
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Dashboard);
