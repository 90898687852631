import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { 
  Col, 
  Container, 
  Row,
  // Card,
  // CardBody,
  // Badge, 
  // Table,
} from 'reactstrap';
import Loading from '../App/Loading';
// import Alert from '../../shared/components/Alert';
// import { NavLink } from 'react-router-dom';
// import Panel from '../../shared/components/Panel';
// import Modal from '../../shared/components/Modal';
import Summary from './components/Summary';
import { Link } from 'react-router-dom';
import { BasicNotification } from '../../shared/components/Notification';


let notifications = null;
const showNotification = ({ notification }) => {
  notifications.notice({
    content: notification,
    duration: 15,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: `right-up`,
  });
};

class BrandPagesSubscription extends React.Component {

  state = {
    loaded: false,
    loading: true,
    canceled: false,
    loadingCancel: false,
    data: {}
  }

  getSubscription = () => {
    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/subscriber/subscription/`, {
      token: this.props.authentication.user.token,
      passphrase: this.props.authentication.user.passphrase,
      subscriptionid: this.props.match.params.id
    }).then(res => {
      this.setState({ 
        loaded: true, 
        loading: false,
        canceled: !res.data.subscription.active,
        data: res.data
      });
    });
  }

  componentDidMount() {
    this.getSubscription();
  }

  changeSubscription = (e) => {
    e.preventDefault();
  }


  cancel = () => {
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notifications = n);
    const _self = this;
    this.setState({ loadingCancel: true });
    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/subscriber/subscription/cancel/`, {
      userid: this.props.authentication.user.id,
      token: this.props.authentication.user.token,
      passphrase: this.props.authentication.user.passphrase,
      subscriptionid: this.props.match.params.id
    }).then(res => {
      _self.showNotification(`Votre résiliation a bien été prise en compte`);
      _self.setState({canceled: true, loadingCancel: false});
    });
    // setTimeout(() => {
    //   _self.showNotification(`Votre résiliation a bien été prise en compte`);
    //   _self.setState({canceled: true, loadingCancel: false});
    // }, 2000);
  }

  showNotification = (message) => {
    return showNotification({
      notification: <BasicNotification color="success" title="Informations" message={message} />
    });
  };

  render() {
    const { loaded, loading } = this.state;
    
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    Mon abonnement {this.state.canceled ? '(Réslilié)' : ''}
                  </h3>
                  <h3 className="page-subhead subhead">
                    {this.state.data.subscription._services.brandpage.name && (
                      <React.Fragment>
                        pour la page <Link to={`/brandpage/${this.state.data.subscription._services.brandpage.id}`}>
                          {this.state.data.subscription._services.brandpage.name}
                        </Link>
                      </React.Fragment>
                    )}
                  </h3>
                </Col>
              </Row>
              <Summary 
                {...this.state.data} 
                subscriptionid={parseInt(this.props.match.params.id)}
                cancel={this.cancel} 
                canceled={this.state.canceled}
                loadingCancel={this.state.loadingCancel}
                changeSubscription={this.changeSubscription}
                user={this.props.authentication.user}
              />
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(BrandPagesSubscription);
