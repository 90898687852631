import React from 'react';
import Panel from '../../../../shared/components/Panel';

class ParticipationsMap extends React.Component {

  componentDidMount() {
    
    const { items } = this.props;

    let map = L.map('map').setView([47.2211422, 1.757412], 6);

    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 6,
        minZoom: 6,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(map);

    if (items.length > 0) {
      items.forEach(item => L.marker([item.lat, item.lng]).addTo(map));
    }

    document.querySelector('.leaflet-control-container').style.display = 'none';
  }

  render() {

    return (
      <Panel lg={8} xl={8} md={12} xs={12} title="Géographie" style={{
        padding: 0
      }}>
        <div id="map" style={{
          height: '600px'
        }}></div>
      </Panel>
    )
  }
}

export default ParticipationsMap;