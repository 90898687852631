import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import TableSamplesFilterButton from './TableSamplesFilterButton';

const TableSamplesToolbar = ({ onRequestSort }) => (
  <div className="material-table__toolbar-wrap">
    <Toolbar className="material-table__toolbar">
      <div>
        <TableSamplesFilterButton onRequestSort={onRequestSort} />
      </div>
    </Toolbar>
  </div>
);

TableSamplesToolbar.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

export default TableSamplesToolbar;
