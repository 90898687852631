import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const rows = [
  {
    id: 'city',
    disablePadding: false,
    label: 'Ville',
  },
  {
    id: 'count_identified',
    disablePadding: false,
    label: 'Caves identifiées',
    textCenter: true,
  },
  {
    id: 'total',
    disablePadding: false,
    label: 'Caves existantes',
    textCenter: true,
  },
  {
    id: 'rate_cover',
    disablePadding: false,
    label: 'Taux de couverture',
    textCenter: true,
  },
];

class MatTableHead extends PureComponent {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rtl } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                className={`material-table__cell material-table__cell--sort material-table__cell-right ${
                  row.textCenter ? 'text-center' : ''
                }`}
                key={row.id}
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(MatTableHead);
