import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr'
import { Card, CardBody, Col } from 'reactstrap';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';

moment.locale("fr");

const TotalPageViews = ({ stats }) => {
  
  const data = Object.keys(stats).map((item, index) => {
    return {
      id: index,
      name: moment.unix(item).format('MMMM YYYY'),
      amt: parseInt(stats[item].total_pages_views) + 1
    }
  })

  const [activeIndex, setActiveIndex] = useState(5);
  const activeItem = data[activeIndex];

  const handleClick = (item) => {
    const index = data.indexOf(item.payload);
    setActiveIndex(index);
  };

  return (
    <Col md={12} xl={4} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="card__title">
            <h5 className="bold-text">Pages vues</h5>
          </div>
          <div className="dashboard__total">
            {/* {
              data[5].amt < data[4].amt ? (
                <TrendingDownIcon className="dashboard__trend-icon" />
              ) : (
                <TrendingUpIcon className="dashboard__trend-icon" />
              )
            }
            <p className="dashboard__total-stat">
              {parseInt(activeItem.amt) - 1}
            </p> */}

            <TrendingUpIcon className="dashboard__trend-icon" />
            {
              typeof activeItem !== 'undefined' && (
                <p className="dashboard__total-stat">
                  {parseInt(activeItem.amt) - 1}
                </p>
              )
            }
            <div className="dashboard__chart-container">
              <ResponsiveContainer height={50}>
                <BarChart data={data}>
                  <Bar dataKey="amt" onClick={handleClick}>
                    {data.map((entry, index) => (
                      <Cell
                        key={entry.id}
                        cursor="pointer"
                        fill={index === activeIndex ? '#4ce1b6' : '#70bbfd'}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TotalPageViews;
