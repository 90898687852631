import React, { useState } from 'react';
import { Card, CardBody, Col, Badge, Button } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableSamplesHead from './tables/TableSamplesHead';
import TableSamplesToolbar from './tables/TableSamplesToolbar';
import moment from 'moment';
moment.locale("fr");

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const TableSamples = ({ data, handleValidateSamplesRequest }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody style={{ paddingLeft: 0, paddingRight: 0}}>
          <div className="card__title" style={{paddingLeft: 30}}>
            <h5 className="bold-text">Demandes d'échantillons</h5>
          </div>
          <TableSamplesToolbar
            onRequestSort={handleRequestSort}
          />
          <div className="material-table__wrap">
            <Table className="material-table">
              <TableSamplesHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {data
                  .sort(getSorting(order, orderBy))
                  .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                  .map((d, index) => {
                    return (
                      <DataRow 
                        key={`order-${index}`} data={d}
                        handleValidateSamplesRequest={handleValidateSamplesRequest}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Page précedente' }}
            nextIconButtonProps={{ 'aria-label': 'Page suivante' }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            labelDisplayedRows={
              ({ from, to, count }) => {
                return '' + from + '-' + to + ' sur ' + count
              }
            }
            labelRowsPerPage="Lignes par page"
            dir="ltr"
            SelectProps={{
              inputProps: { 'aria-label': 'lignes par page' },
              native: true,
            }}
          />
        </CardBody>
      </Card>
    </Col>
  );
};

const DataRow = ({ data, handleValidateSamplesRequest }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow
        className="material-table__row material-table__parent-row"
        key={data.id}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          className="material-table__cell material-table__cell-right"
          component="th"
          scope="row"
          padding="none"
        >
          {`btl-PR${data.createdAt}`}
        </TableCell>
        <TableCell
          className="material-table__cell material-table__cell-right"
        >{moment.unix(data.createdAt).utc(true).format('Do MMMM YYYY à hh:mm')}
        </TableCell>
        <TableCell
          className="material-table__cell material-table__cell-right"
        >{data.shop_name}
        </TableCell>
        <TableCell
          className="material-table__cell material-table__cell-right"
        >
          <Badge color={data.processed ? 'success' : 'primary'}>
            {data.processed ? 'Traitée' : 'En attente'}
          </Badge>
        </TableCell>
      </TableRow>

      <TableRow style={{ background: 'white' }}>
        <TableCell 
          style={{ 
            padding: 0, 
            border: 0
          }} 
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box 
              margin={0} 
              style={{
                background: '#f0f0f0',
                padding: '2rem'
              }}
            >
              <h4>Informations</h4>
              <div style={{ margin: '1rem 0 2rem'}}>
                <p>Caviste: <strong>{`${data.shopkeeper_user_firstname} ${data.shopkeeper_user_lastname}`}</strong></p>
                <p>Numéro de téléphone: <strong>{data.shop_phone}</strong></p>
                <p>Adresse email: <strong>{data.shopkeeper_user_email}</strong></p>
              </div>
              <h4>Références demandées</h4>
              <Table 
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>ID du Produit</TableCell>
                    <TableCell>Produit</TableCell>
                    <TableCell>Degré d'alcool</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.products.map((item, index) => {
                      return (
                        <TableRow key={`product-${item.id}`}>
                          <TableCell>
                            {item.id}
                          </TableCell>
                          <TableCell>
                            {item.name}
                          </TableCell>
                          <TableCell>
                            {item.alcool_degrees}°
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
                {
                  data.processed ? (
                    <Button disabled>J'ai traité cette demande</Button>
                  ) : (
                    <Button 
                      color="primary"
                      onClick={() => handleValidateSamplesRequest(data.id)}
                    >J'ai traité cette demande</Button>
                  )
                }
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

    </React.Fragment>
  )
}

export default TableSamples;
