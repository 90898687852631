import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const rows = [
  {
    id: 'name',
    disablePadding: false,
    label: 'Commerce',
  },
  {
    id: 'locality',
    disablePadding: false,
    label: 'Ville',
  },
  {
    id: 'postal_code',
    disablePadding: false,
    label: 'Code postal',
  },
  {
    id: 'gerant_firstname',
    disablePadding: false,
    label: 'Gérant',
  },
];

class MatTableHead extends PureComponent {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rtl } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={row.id}
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(MatTableHead);
