import React from 'react';
import { Table } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';

const InsightResponseTable = ({ columns, rows, col, label }) => {
  return (
    <Panel lg={col ? col : 8} xl={col ? col : 8} md={col ? col : 8} xs={12} title={label}>
      <Table responsive striped className="dashboard__table-orders notStripped">
        <thead>
          <tr>
            <th></th>
            {
              columns.map((item, index) => {
                return (
                  <th key={`column-${index}`}>
                    {item.label}
                  </th>
                )
              })
            }
          </tr>
        </thead>

        <tbody>
          {
            rows.map((item, index) => {
              return (
                <tr key={`row-${index}`}>
                  <td><strong>{item.label}</strong></td>
                  <td>{item.eur.toFixed(0)}</td>
                  <td>{item.unit.toFixed(0)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    </Panel>
  )
}

export default InsightResponseTable;
