import React from 'react';
// import PropTypes from 'prop-types';
// import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title,
  icon,
  newLink,
  route,
  onClick,
  style,
  dist,
  target,
}) => (
  <React.Fragment>
    {dist ? (
      <a href={route} target={target}>
        <li className="sidebar__link">
          {icon ? (
            <span
              style={style}
              className={`sidebar__link-icon lnr lnr-${icon}`}
            />
          ) : (
            ''
          )}
          <p className="sidebar__link-title" style={style}>
            {title}
            {newLink ? <span className="sidebar__category-new"></span> : ''}
          </p>
        </li>
      </a>
    ) : (
      <NavLink
        to={route}
        onClick={onClick}
        activeClassName="sidebar__link-active"
      >
        <li className="sidebar__link">
          {icon ? (
            <span
              style={style}
              className={`sidebar__link-icon lnr lnr-${icon}`}
            />
          ) : (
            ''
          )}
          <p className="sidebar__link-title" style={style}>
            {title}
            {newLink ? <span className="sidebar__category-new"></span> : ''}
          </p>
        </li>
      </NavLink>
    )}
  </React.Fragment>
);

// SidebarLink.propTypes = {
//   title: PropTypes.string.isRequired,
//   icon: PropTypes.string,
//   newLink: PropTypes.bool,
//   route: PropTypes.string,
//   onClick: PropTypes.func,
// };

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;
