import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ImporterFile from '../../Intelligence/Import/importer.file';
import ImporterCopyPaste from '../../Intelligence/Import/importer.copypaste';

class Import extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    importType: null,
    importMethod: null,
  };

  constructor(props) {
    super(props);

    this.pickImportType = this.pickImportType.bind(this);
    this.resetImportType = this.resetImportType.bind(this);
    this.pickImportMethod = this.pickImportMethod.bind(this);
    this.resetImportMethod = this.resetImportMethod.bind(this);
    this.handleUploadFinish = this.handleUploadFinish.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
          loaded: true,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  pickImportType(type) {
    this.setState({ importType: type });
  }

  resetImportType() {
    this.setState({ importType: null, importMethod: null });
  }

  pickImportMethod(method) {
    this.setState({ importMethod: method });
  }

  resetImportMethod() {
    this.setState({ importMethod: null });
  }

  handleUploadFinish() {
    this.props.history.push('/finder/vision/imports');
  }

  render() {
    let {
      loaded,
      loading,
      subscription,
      importType,
      importMethod,
    } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            {/* 1ST ROW */}
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <h3 className="page-title">Importez votre base</h3>
                  </Col>
                  <Col md={6} align="right">
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      onClick={() => {
                        this.props.history.push('/finder/vision');
                      }}
                    >
                      Retour
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* MAIN CONTENT */}
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <p className="as-import-intro">
                      Vous pouvez importer une base de cavistes prospects ou une
                      base de cavistes déjà clients ou encore une base mixte de
                      cavistes prospects et clients.
                      <br />
                      Vous devez impérativement avoir une colonne SIRET pour
                      permettre la comparaison des bases.
                    </p>
                    {/* IMPORT TYPE */}
                    {!importType && (
                      <Row className="as-import-types">
                        <Col md={4}>
                          <Card
                            className="as-import-option"
                            onClick={() => {
                              this.pickImportType('prospects');
                            }}
                          >
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  {' '}
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                  />{' '}
                                </svg>

                                <div className="card__title">
                                  <h5 className="bold-text">Prospects</h5>
                                </div>
                                <div className="card__body">
                                  <p>
                                    Importez la liste de vos prospects, et
                                    comparez-là avec la liste des caves de
                                    Finder{' '}
                                  </p>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: '10pt',
                                      textAlign: 'left',
                                      color: '#273644',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <strong>
                                      <u>Nota bene : </u>
                                    </strong>
                                    Votre base doit contenir le numéro de SIRET
                                    de vos prospects
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* Option 2 */}
                        <Col md={4}>
                          <Card
                            className="as-import-option"
                            onClick={() => {
                              this.pickImportType('clients');
                            }}
                          >
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                                  />
                                </svg>

                                <div className="card__title">
                                  <h5 className="bold-text">Clients</h5>
                                </div>
                                <div className="card__body">
                                  <p>
                                    Importez la liste de vos clients, et
                                    comparez-là avec la liste des caves de
                                    Finder{' '}
                                  </p>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: '10pt',
                                      textAlign: 'left',
                                      color: '#273644',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <strong>
                                      <u>Nota bene : </u>
                                    </strong>
                                    Votre base doit contenir le numéro de SIRET
                                    de vos clients
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* Option 3 */}
                        <Col md={4}>
                          <Card
                            className="as-import-option"
                            onClick={() => {
                              this.pickImportType('all');
                            }}
                          >
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                                  />
                                </svg>

                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Prospects et Clients
                                  </h5>
                                </div>
                                <div className="card__body">
                                  <p>
                                    Importez la liste de vos prospects et
                                    clients, et comparez-là avec la liste des
                                    caves de Finder{' '}
                                  </p>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: '10pt',
                                      textAlign: 'left',
                                      color: '#273644',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <strong>
                                      <u>Nota bene : </u>
                                    </strong>
                                    Votre base doit contenir le numéro de SIRET
                                    des commerces
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {/* CONFIRM IMPORT TYPE */}
                    {importType && (
                      <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="as-import-success as-next">
                          <h5>Vous avez confirmé votre type d'import !</h5>
                          <p>
                            <strong>Type d'import : </strong>
                            {
                              {
                                prospects: 'Liste de prospects',
                                clients: 'Liste de clients',
                                all: 'Liste de prospects et de clients',
                              }[importType]
                            }
                          </p>
                          <button
                            onClick={this.resetImportType}
                            type="button"
                            className="as-cancel-link"
                          >
                            Annuler
                          </button>
                        </div>
                      </Col>
                    )}
                    {/* IMPORT METHOD */}
                    {importType && !importMethod && (
                      <Row className="as-import-options">
                        {/* Option 1 */}
                        <Col md={6}>
                          <Card
                            className="as-import-option"
                            onClick={() => {
                              this.pickImportMethod('file');
                            }}
                          >
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                  />
                                </svg>

                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Importer un fichier
                                  </h5>
                                </div>
                                <div className="card__body">
                                  <p>
                                    Sélectionnez un fichier .csv, .xls ou .txt
                                    sur votre ordinateur.
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* Option 2 */}
                        <Col md={6}>
                          <Card
                            className="as-import-option"
                            onClick={() => {
                              this.pickImportMethod('copypaste');
                            }}
                          >
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                  />
                                </svg>

                                <div className="card__title">
                                  <h5 className="bold-text">Copier-coller</h5>
                                </div>
                                <div className="card__body">
                                  <p>
                                    Copiez et collez les caves de votre fichier
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {/* CONFIRM IMPORT METHOD */}
                    {importMethod && (
                      <Col md={12} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="as-import-success as-next">
                          <h5>Vous avez confirmé votre méthode d'import !</h5>
                          <p>
                            <strong>Méthode d'import : </strong>
                            {
                              {
                                file:
                                  'Dépôt de fichier (.csv, .txt, .xls, .xlsx)',
                                copypaste: 'Copier-coller depuis un fichier',
                              }[importMethod]
                            }
                          </p>
                          <button
                            onClick={this.resetImportMethod}
                            type="button"
                            className="as-cancel-link"
                          >
                            Annuler
                          </button>
                        </div>
                      </Col>
                    )}
                    {/* IMPORTER */}
                    {importMethod === 'file' && (
                      <ImporterFile
                        importType={importType}
                        onFinish={this.handleUploadFinish}
                        embed
                      />
                    )}
                    {importMethod === 'copypaste' && (
                      <ImporterCopyPaste
                        importType={importType}
                        onFinish={this.handleUploadFinish}
                        embed
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Import);
