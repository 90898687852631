import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Overview from './overview';
import Mapping from './mapping';

class ImporterFile extends React.Component {
  state = {
    loaded: true,
    loading: false,
    _import: {
      doneUploading: false,
      doneOverviewing: false,
      doneMapping: false,
      doneRunning: false,
      filename: '',
      mapping: {},
      _object: {},
    },
    doneUpload: false,
    doneMapping: false,
    doneImporting: false,
  };

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  _showNotification(w) {
    return Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.fileInput = React.createRef();
    this.triggerFileInput = this.triggerFileInput.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleFileDragOver = this.handleFileDragOver.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleMappingChange = this.handleMappingChange.bind(this);
    this.resetImport = this.resetImport.bind(this);
    this.confirmOverview = this.confirmOverview.bind(this);
    this.confirmMapping = this.confirmMapping.bind(this);
    this.resetOverview = this.resetOverview.bind(this);
    this.cancelMapping = this.cancelMapping.bind(this);
    this.runImport = this.runImport.bind(this);

    window._unavailableFields = [];
  }

  triggerFileInput() {
    this.fileInput.current.click();
  }

  resetImport(evt) {
    evt.preventDefault();

    // this.fileInput.current.value = '';
    this.setState({
      _import: {
        doneUploading: false,
        doneOverviewing: false,
        _object: {},
        filename: '',
      },
    });
  }

  resetOverview(evt) {
    evt.preventDefault();
    this.setState({
      _import: { ...this.state._import, doneOverviewing: false },
    });
  }

  cancelMapping(evt) {
    evt.preventDefault();
    this.setState({
      _import: { ...this.state._import, doneMapping: false },
    });
  }

  confirmOverview(evt) {
    evt.preventDefault();
    this.setState({
      _import: { ...this.state._import, doneOverviewing: true },
    });
  }

  confirmMapping(evt) {
    evt.preventDefault();

    if (
      Object.entries(this.state._import.mapping).filter(
        (e) => e[1] !== undefined
      ).length === 0
    )
      return this._showError(
        "Veuillez mapper au moins l'un de vos champs d'import avant de continuer."
      );

    if (!Object.values(this.state._import.mapping).includes('name'))
      if (!this.props.embed)
        return this._showError(
          "Veuillez mapper au moins l'un de vos champs d'import au nom de la cave"
        );

    if (this.props.embed) {
      if (!Object.values(this.state._import.mapping).includes('siret'))
        return this._showError(
          "Veuillez mapper au moins l'un de vos champs d'import au numéro SIRET de la cave"
        );

      if (this.props.importType === 'all')
        if (
          !Object.values(this.state._import.mapping).includes(
            'bottlCommercialStatus'
          )
        )
          return this._showError(
            "Veuillez mapper au moins l'un de vos champs d'import au statut commercial de la cave"
          );
    }

    this.setState({
      _import: { ...this.state._import, doneMapping: true },
    });
  }

  handleFileDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    let file = null;
    if (evt.dataTransfer.items) {
      const item = evt.dataTransfer.items[0];
      if (item.kind !== 'file') return;
      file = item.getAsFile();
    } else {
      file = evt.dataTransfer.files[0];
    }

    this._uploadFile(file);
  }

  handleFileDragOver(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }

  handleFileUpload(evt) {
    evt.preventDefault();

    const fp = this.fileInput.current;

    if (fp.files.length === 0) return;

    this._uploadFile(fp.files[0]);
  }

  _uploadFile(file) {
    if (file.size / (1024 * 1024) > 25) {
      this._showError(
        'Veuillez utliser un fichier ne dépassant pas 25 mégaoctets.'
      );
      return;
    }

    if (!['csv', 'xlsx', 'txt'].includes(file.name.split('.').at(-1))) {
      this._showError(
        'Veuillez utliser un fichier au format csv, xlsx, ou txt'
      );
      return;
    }

    const {
      authentication: { user },
    } = this.props;
    let fd = new FormData();
    fd.append('file', file);
    fd.append('id', user.id);
    fd.append('token', user.token);
    fd.append('passphrase', user.passphrase);
    fd.append('type', 'file');

    if (this.props.embed)
      fd.append('shops_commercial_status', this.props.importType);

    this.setState({ loaded: false, loading: true });

    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/create`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((r) => {
        if (!r.data.content.error) {
          // Prevent duplicates
          if (r.data.content.import.header) {
            if (
              new Set(r.data.content.import.header).size !==
              r.data.content.import.header.length
            ) {
              this.setState({
                loaded: true,
                loading: false,
              });
              return this._showError(
                'Veuillez vous assurer que les entêtes de votre fichier soient sans doublons'
              );
            }
          }

          this.setState({
            loaded: true,
            loading: false,
            _import: {
              ...this.state._import,
              _object: r.data.content.import,
              filename: file.name,
              doneUploading: true,
            },
          });
        } else {
          this._showError(r.data.content.error);
          this.setState({ loaded: true, loading: false });
        }
      });
  }

  handleMappingChange(shop_column, csv_column) {
    this.setState({
      _import: {
        ...this.state._import,
        mapping: {
          ...this.state._import.mapping,
          [csv_column]:
            shop_column == 'Ne pas importer' ? undefined : shop_column,
        },
      },
    });
  }

  runImport() {
    this.setState({ loaded: false, loading: true });
    const {
      authentication: { user },
    } = this.props;

    // First, make mappings
    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/make-mappings`,
        {
          id: user.id,
          passphrase: user.passphrase,
          token: user.token,
          importId: this.state._import._object.pk,
          mappings: Object.entries(this.state._import.mapping).map((e) => ({
            import_column: e[0],
            shop_column: e[1],
          })),
        }
      )
      .then((r) => {
        if (!r.data.content.error) {
          axios
            .post(
              `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/run`,
              {
                id: user.id,
                passphrase: user.passphrase,
                token: user.token,
                importId: this.state._import._object.pk,
              }
            )
            .then((rb) => {
              if (!rb.data.content.error) {
                this.setState({
                  loaded: true,
                  loading: false,
                  _import: {
                    ...this.state._import,
                    doneRunning: true,
                  },
                });
                this._showNotification(
                  'Vos données ont bien été importées dans votre espace Finder'
                ).then((_) => {
                  if (!this.props.embed)
                    this.props.history.push('/finder/intelligence/imports');
                  else this.props.onFinish();
                });
              } else {
                this._showError(r.data.content.error);
                this.setState({ loaded: true, loading: false });
              }
            });
        } else {
          this._showError(r.data.content.error);
          this.setState({ loaded: true, loading: false });
        }
      });
  }

  render() {
    let { loaded, loading, _import } = this.state;

    return (
      <Container
        className={`${
          this.props.embed ? 'as-import-method-embed' : 'dashboard'
        }`}
      >
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && (
          <React.Fragment>
            {/* 1ST ROW */}
            {!this.props.embed && (
              <Row>
                <Col>
                  <Row>
                    <Col md={6}>
                      <h3 className="page-title">
                        Mes bases > Import > Fichier
                      </h3>
                    </Col>
                    <Col md={6} align="right">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        onClick={() => {
                          this.props.history.push(
                            '/finder/intelligence/import'
                          );
                        }}
                      >
                        Annuler
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}

            {/* MAIN CONTENT */}
            <Row>
              {!_import.doneRunning && (
                <Col>
                  <Row>
                    <Col md={12}>
                      {!this.props.embed ||
                        (this.props.embed && !_import.doneUploading && (
                          <p className="as-import-intro">
                            Téléchargez un fichier contenant toutes vos caves et
                            leurs informations.{' '}
                          </p>
                        ))}
                      <Row
                        className={`as-import-options ${
                          _import.doneUploading && this.props.embed
                            ? 'uploaded'
                            : ''
                        }`}
                      >
                        {/* FILE UPLOADER */}
                        {!_import.doneUploading && (
                          <Col md={12}>
                            <Card
                              className="as-import-dropzone"
                              onClick={this.triggerFileInput}
                            >
                              <CardBody className="dashboard__card-widget">
                                <div
                                  onDrop={this.handleFileDrop}
                                  onDragOver={this.handleFileDragOver}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                    />
                                  </svg>

                                  <div className="card__title">
                                    <h5 className="bold-text">
                                      Sélectionnez votre fichier, ou effectuez
                                      un glisser-déposer ici
                                    </h5>
                                  </div>
                                  <div className="card__body">
                                    <p>.csv, .xlsx, ou .txt</p>
                                  </div>
                                  <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={this.fileInput}
                                    accept=".csv, .xlsx, .txt"
                                    onChange={this.handleFileUpload}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {/* CONFIRM UPLOAD */}
                        {_import.doneUploading && (
                          <Col md={12}>
                            <div className="as-import-success">
                              <h5>Votre fichier a bien été importé !</h5>
                              <p>{_import.filename}</p>
                              <button
                                onClick={this.resetImport}
                                type="button"
                                className="as-cancel-link"
                              >
                                Annuler
                              </button>
                            </div>
                          </Col>
                        )}
                        {/* OVERVIEW */}
                        {_import.doneUploading && !_import.doneOverviewing && (
                          <>
                            <Col md={12} className="as-flex-column">
                              <Overview _import={_import._object} />
                              <button
                                type="button"
                                className="as-import-confirm-btn btn btn-primary"
                                onClick={this.confirmOverview}
                              >
                                Confirmer votre fichier
                              </button>
                            </Col>
                          </>
                        )}
                        {/* CONFIRM OVERVIEW */}
                        {_import.doneOverviewing && (
                          <Col md={12}>
                            <div className="as-import-success as-next">
                              <h5>
                                Vous avez confirmé l'aperçu de votre fichier !
                              </h5>
                              <p>
                                Délimiteur : "
                                {(_import._object.delimiter || '').replace(
                                  '\t',
                                  'Tabulation'
                                )}
                                "
                                <br />
                                Lignes: {_import._object.line_count}
                                <br />
                                Colonnes:{' '}
                                {_import._object.has_header == true
                                  ? `${_import._object.header.length}`
                                  : `${_import._object.sample_lines[0].length}`}
                              </p>
                              <button
                                onClick={this.resetOverview}
                                type="button"
                                className="as-cancel-link"
                              >
                                Annuler
                              </button>
                            </div>
                          </Col>
                        )}
                        {/* MAPPING */}
                        {_import.doneOverviewing && !_import.doneMapping && (
                          <>
                            <Col md={12} className="as-flex-column">
                              <Mapping
                                current={_import.mapping}
                                onChange={this.handleMappingChange}
                                _import={_import._object}
                                forVision={this.props.embed ? true : false}
                                visionType={this.props.importType || null}
                              />
                              <button
                                type="button"
                                className="as-import-confirm-btn btn btn-primary"
                                onClick={this.confirmMapping}
                              >
                                {this.props.embed
                                  ? 'Je confirme que la colonne SIRET est bien identifiée'
                                  : 'Confirmer votre mappage'}
                              </button>
                              {this.props.embed && (
                                <p
                                  style={{
                                    textAlign: 'center',
                                    marginBottom: '24px',
                                    marginTop: 0,
                                  }}
                                >
                                  Sinon, je revérifie que ma colonne des SIRET
                                  est bien intitulée avec le mot "SIRET"
                                </p>
                              )}
                            </Col>
                          </>
                        )}
                        {/* CONFIRM MAPPING */}
                        {_import.doneMapping && (
                          <Col md={12} className="as-flex-column">
                            <div className="as-import-success as-next">
                              {!this.props.embed && (
                                <>
                                  <h5>Vous avez confirmé votre mappage !</h5>
                                  <p>
                                    {
                                      Object.entries(_import.mapping).filter(
                                        (e) => e[1]
                                      ).length
                                    }{' '}
                                    attribut
                                    {Object.entries(_import.mapping).filter(
                                      (e) => e[1]
                                    ).length > 1 && 's'}
                                  </p>
                                </>
                              )}
                              {this.props.embed && (
                                <h5>
                                  Votre colonne SIRET est bien identifiée !
                                </h5>
                              )}
                              <button
                                onClick={this.cancelMapping}
                                type="button"
                                className="as-cancel-link"
                              >
                                Éditer
                              </button>
                            </div>
                            <button
                              type="button"
                              className="as-import-confirm-btn btn btn-primary"
                              onClick={this.runImport}
                            >
                              Lancer l'import de mon fichier
                            </button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(ImporterFile);
