import React from 'react';
import { connect } from 'react-redux'
// import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import Loading from '../App/Loading';
import InvoiceTable from './components/Table/InvoiceTable';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';

class InsightCampaigns extends React.Component {

  state = {
    loaded: false,
    loading: true,
    collection: [],
    invoices: [],
  }

  componentDidMount() {

    const { authentication: { user } } = this.props;
    const endpoint = `insight/collection`;

    if (this.props.sidebar.collapse) {
      this.props.changeSidebarVisibility();
    }
    
    this.setState({ 
      invoices: user.insight_customer_invoices,
      loaded: true,
      loading: false
    })
  }

  render() {
    
    const { loaded, loading } = this.state;
    console.log(this.state.invoices);
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
          {
            loaded && (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <h3 className="page-title">
                      Bottl.Insight
                    </h3>
                  </Col>
                </Row>
                <Row>
                  {
                    this.state.invoices.length > 0 && (
                      <InvoiceTable 
                        history={this.props.history} 
                        data={this.state.invoices} 
                        title={`Liste des factures`}
                        type={this.props.location.pathname}
                      />
                    )
                  }
                </Row>
              </React.Fragment>
            )
          }
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSidebarVisibility: (state) => dispatch(changeSidebarVisibility(state)),
});
const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps, mapDispatchToProps)(InsightCampaigns);