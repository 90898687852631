import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Sector, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const colors = [
  '#ffa600',
  '#ff7c43',
  '#f95d6a',
  '#d45087',
  '#a05195',
  '#665191',
  '#2f4b7c',
  '#003f5c',
  '#ff393b',
  '#ffa600',
  '#ff7c43',
  '#f95d6a',
  '#d45087',
  '#a05195',
  '#665191',
  '#2f4b7c',
  '#003f5c',
  '#ff393b',
]

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { left: 0 };
  return ({
    ...left,
    width: 320,
    lineHeight: '24px',
    position: 'absolute',
    bottom: 0
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />
          {entry.value}
        </li>
      ))
    }
  </ul>
);


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  
  return (
    <g>
      <text style={{ fontSize: '24px' }} x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <text style={{ fontSize: '16px' }} x={cx} y={cy} dy={28} textAnchor="middle" fill={fill}>
        {payload.percentage}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        style={{
          cursor: 'pointer'
        }}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
        style={{
          cursor: 'pointer'
        }}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${props.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {props.percentage}% 
      </text>
    </g>
  );
};

const renderTooltip = ({ payload }) => {
  
  return <React.Fragment>
    {
      payload.map((item, index) => {
        return <div key={`tooltip-${index}`} style={{
          opacity: .9,
          backgroundColor: '#fff',
          padding: '.5rem 1rem',
          border: '1px solid #dbdbdd',
          boxShadow: '0 10px 30px 1px rgb(0 0 0 / 6%)',
          borderRadius: '0.25rem'
        }}>{`${item.name}: ${item.value}%`}</div>
      })
    }
  </React.Fragment>
};

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-active-shape-y93si';

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { dir, data, label, col } = this.props;
    const dataWithColors = data.map((item, index) => {
      return {
        ...item,
        fill: colors[index]
      }
    })
    return (
      <Panel lg={col ? col : 12} xl={col ? col : 12} md={col ? col : 12} xs={12} title={label}>
        <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--insight-sector">
          <PieChart className="dashboard__chart-pie-container">
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              data={dataWithColors}
              innerRadius={230}
              outerRadius={300}
              fill="#8884d8"
              dataKey="percentage"
              style={{
                cursor: 'pointer'
              }}
              onClick={this.onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      </Panel>
    );
  }
}
