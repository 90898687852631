import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';

const colors = [
  '#ffa600',
  '#ff7c43',
  '#f95d6a',
  '#d45087',
  '#a05195',
  '#665191',
  '#2f4b7c',
  '#003f5c',
  '#ff393b',
]

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { left: 0 };
  return ({
    ...left,
    width: 320,
    lineHeight: '24px',
    position: 'absolute',
    bottom: 0
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />
          {entry.value}
        </li>
      ))
    }
  </ul>
);

const renderTooltip = ({ payload }) => {
  
  return <React.Fragment>
    {
      payload.map((item, index) => {
        return <div key={`tooltip-${index}`} style={{
          opacity: .9,
          backgroundColor: '#fff',
          padding: '.5rem 1rem',
          border: '1px solid #dbdbdd',
          boxShadow: '0 10px 30px 1px rgb(0 0 0 / 6%)',
          borderRadius: '0.25rem'
        }}>{`${item.name}: ${item.value}%`}</div>
      })
    }
  </React.Fragment>
};

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

class PieCharts extends PureComponent {

  render() {
    const { dir, data, label, col } = this.props;
    const dataWithColors = data.map((item, index) => {
      return {
        ...item,
        fill: colors[index]
      }
    })
    
    return (
      <Panel lg={col ? col : 8} xl={col ? col : 8} md={col ? col : 8} xs={12} title={label}>
        <div dir={dir}>
          <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--insight">
            <PieChart className="dashboard__chart-pie-container">
              <Pie
                data={dataWithColors}
                dataKey="value"
                cy={200}
                innerRadius={100}
                outerRadius={150}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  value
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);
                  return (
                    <text
                      x={x}
                      y={y}
                      fill="#cccccc"
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >
                      {value}%
                    </text>
                  );
                }}
                onMouseMove={this.onMouseMove}
              />
              <Tooltip content={renderTooltip} />
              <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Panel>
    );
  }
}

export default PieCharts;
