import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Loading from '../App/Loading';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMain';
import HorizontalForm from './components/HorizontalForm';

class Profile extends React.Component {
  state = {
    loaded: false,
    loading: true,
    profile: null,
    profileData: {},
  };

  componentDidMount() {
    // console.log(this.props.user.id);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/get-profile/`,
        {
          userid: this.props.user.id,
          id: this.props.user.id,
          token: this.props.user.token,
          passphrase: this.props.user.passphrase,
        }
      )
      .then((res) => {
        const data = res.data;
        this.setState({
          profile: data,
          loaded: true,
          loading: false,
          profileData: {
            civility: data.infos.civility,
            lastname: data.infos.lastname,
            firstname: data.infos.firstname,
            fonction: data.infos.fonction,
            email: data.infos.email,
            phone: data.infos.phone,
            societe: data.infos.societe,
            formejuridique: data.infos.formejuridique,
            siren: data.infos.siren,
            tva: data.infos.tva,
            rcs: data.infos.rcs,
            societe_street: data.infos.societe_street,
            societe_location: data.infos.societe_location,
            societe_zipcode: data.infos.societe_zipcode,
            pays: data.infos.pays,
            invoice_street: data.infos.invoice_street,
            invoice_location: data.infos.invoice_location,
            invoice_zipcode: data.infos.invoice_zipcode,
            invoice_country: data.infos.invoice_country,
            invoice_email: data.infos.invoice_email,
            societe_certification: data.infos.societe_certification,
          },
        });
      });
  }

  showResults = (values) => {
    // console.log(values);
  };

  render() {
    const { loaded, loading, profile } = this.state;
    var initialName = { name: 'Sherlynn' };
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {loaded && profile && (
          <div className="profile">
            <Row>
              <Col md={12} lg={12} xl={5}>
                <Row>
                  <ProfileMain data={this.state.profile} />
                  {/* <ProfileCalendar /> */}
                  {/* <ProfileTasks /> */}
                </Row>
              </Col>
              {/* <ProfileTabs /> */}
            </Row>
            <HorizontalForm
              user={this.props.user}
              data={this.state.profile}
              onSubmit={this.showResults}
              userid={this.props.user.id}
            />
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication,
});
export default connect(mapStateToProps)(Profile);
