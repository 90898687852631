import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Container, Row } from 'reactstrap';
import MatTable from './components/Table/MatTable';
import Loading from '../App/Loading';

class BrandpagesListing extends React.Component {

  constructor() {
    super();

    this.state = {
      loaded: false,
      loading: true,
      collection: []
    }
  }

  componentDidMount() {
    this.getCollection()
  }

  getCollection = () => {
    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/collection/`, {
      userid: this.props.authentication.user.id,
      token: this.props.authentication.user.token,
      passphrase: this.props.authentication.user.passphrase,
    }).then(res => {
      const data = res.data;
      this.setState({ 
        loaded: true, 
        loading: false,
        collection: data.collection
      })
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <MatTable 
                  history={this.props.history} 
                  data={this.state.collection} 
                  title={`Liste de vos pages`}
                  type={this.props.location.pathname}
                />
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(BrandpagesListing);
