import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';

class Bookmarks extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    invoices: [],
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.handleInvoiceOpen = this.handleInvoiceOpen.bind(this);
  }

  handleInvoiceOpen(link) {
    window.open(link, 'target=_blank');
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/invoices/list`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState(
          {
            loaded: true,
            loading: false,
            invoices: res.data.content.invoices,
          },
          () => {
            axios
              .post(
                `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/subscription/get`,
                {
                  id: user.id,
                  token: user.token,
                  passphrase: user.passphrase,
                }
              )
              .then((res) => {
                this.setState({
                  subscription: res.data.content.subscription,
                });
              });
          }
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  render() {
    let { loaded, loading, invoices, subscription } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {loaded && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Mes factures</h3>
              </Col>
            </Row>
            <Row>
              <div
                className={`${
                  !subscription || invoices.length === 0
                    ? 'as-messenger bg-white'
                    : 'as-invoices'
                }`}
              >
                {/* EMPTY VOID UNSUBSCRIBED STATE */}
                {!subscription && (
                  <div className="placeholder-wrapper bg-white">
                    <p className="placeholder">
                      Vous n'avez aucun abonnement à Finder pour l'instant.
                    </p>
                    <br />
                    <a
                      href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                      className="btn btn-primary"
                    >
                      Je m'abonne
                    </a>
                  </div>
                )}
                {subscription && invoices.length === 0 && (
                  <div className="placeholder-wrapper">
                    <p className="placeholder">
                      Vous n'avez aucune facture pour l'instant.
                    </p>
                  </div>
                )}
                {subscription && invoices.length > 0 && (
                  <div className="as-invoice-list">
                    {invoices.map((invoice, idx) => (
                      <div
                        onClick={() => this.handleInvoiceOpen(invoice.link)}
                        className="as-invoice"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span className="invoice-reference">
                          {invoice.stripe_reference}
                        </span>
                        <span className="invoice-price">
                          {(
                            Math.round((invoice.amount / 100) * 100) / 100
                          ).toFixed(2)}
                          €
                        </span>
                        <span className="invoice-date">
                          Le {moment(invoice.created_at).format('D/M/YYYY')}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Bookmarks);
