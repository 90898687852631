import React from 'react';
import Panel from '../../../../shared/components/Panel';
import { Row, Col } from 'reactstrap';

const Bars = (props) => {
  
  return (
    <Panel lg={props.col ? props.col : 8} xl={props.col ? props.col : 8} md={props.col ? props.col : 8} xs={12} title={props.label}>
      <div className="dashboard__sales-report">
        <Row>
          <Col md={12} xl={6} lg={6} xs={12}>
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>{
                  parseFloat(props.data.yesPercentage).toFixed(0)
                }%</p>
              </div>
              <div className="mobile-app-widget__title">
                <h5>Oui</h5>
              </div>
            </div>
          </Col>

          <Col md={12} xl={6} lg={6} xs={12}>
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>{
                  parseFloat(props.data.noPercentage).toFixed(0)
                }%</p>
              </div>
              <div className="mobile-app-widget__title">
                <h5>Non</h5>
              </div>
            </div>
          </Col>

        </Row>
      </div>
    </Panel>
  )
}

export default Bars;
