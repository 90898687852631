import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const rows = [
  {
    id: 'id', disablePadding: true, label: 'N° de demande',
  },
  {
    id: 'date', disablePadding: false, label: 'Date',
  },
  {
    id: 'shop', disablePadding: false, label: 'Cave',
  },
  {
    id: 'status', disablePadding: false, label: 'Statut',
  }
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const TableSamplesHead = ({
  order, 
  orderBy, 
  onRequestSort,
}) => (
  <TableHead>
    <TableRow>
      <TableCell />
      {rows.map(row => (
        <TableCell
          className="material-table__cell material-table__cell--sort material-table__cell-right"
          key={row.id}
          align={`left`}
          padding={row.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === row.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === row.id}
            direction={order}
            onClick={createSortHandler(row.id, onRequestSort)}
            className="material-table__sort-label"
            dir="ltr"
          >
            {row.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

TableSamplesHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default TableSamplesHead;
