import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import Loading from '../App/Loading';
import MatTable from './components/Table/MatTable';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';

class InsightCampaigns extends React.Component {

  state = {
    loaded: false,
    loading: true,
    collection: [],
  }

  componentDidMount() {

    const { authentication: { user } } = this.props;
    const endpoint = `insight/collection`;

    if (this.props.sidebar.collapse) {
      this.props.changeSidebarVisibility();
    }

    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
      id: user.id,
      token: user.token,
      passphrase: user.passphrase
    }).then(res => {
      
      this.setState({ 
        loaded: true, 
        loading: false,
        collection: res.data.content.collection
      })

      console.log(user);
    });
  }

  render() {
    
    const { loaded, loading } = this.state;

    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
          {
            loaded && (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <h3 className="page-title">
                      Bottl.Insight
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <MatTable 
                    history={this.props.history} 
                    data={this.state.collection} 
                    title={`Liste des études`}
                    type={this.props.location.pathname}
                  />
                </Row>
              </React.Fragment>
            )
          }
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSidebarVisibility: (state) => dispatch(changeSidebarVisibility(state)),
});
const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps, mapDispatchToProps)(InsightCampaigns);