import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Container, Row } from 'reactstrap';
import MatTable from './components/Subscriptions/MatTable';
import Loading from '../App/Loading';

class BrandpagesSubscriptionsListing extends React.Component {

  constructor() {
    super();

    this.state = {
      loaded: false,
      loading: true,
      collection: []
    }
  }

  componentDidMount() {
    this.getCollection()
  }

  getCollection = () => {
    const creds = {
      id: this.props.authentication.user.id,
      token: this.props.authentication.user.token,
      passphrase: this.props.authentication.user.passphrase
    };
    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/subscriber/${creds.id}/subscriptions/collection/`, creds)
    .then(res => {
      const data = res.data;
      
      this.setState({ 
        loaded: true, 
        loading: false,
        collection: data.content.subscriptions
      })
      console.log(data.content.subscriptions)
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <MatTable 
                  history={this.props.history} 
                  data={this.state.collection} 
                  title={`Liste de vos abonnements`}
                  type={this.props.location.pathname}
                />
              </Row>
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(BrandpagesSubscriptionsListing);
