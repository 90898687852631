import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Col, Container, Row, Card, CardBody, Badge, Table } from 'reactstrap';
import Loading from '../App/Loading';
import Alert from '../../shared/components/Alert';
import {
  AbsoluteData,
  TotalSellInSellOut,
  TotalNewImplantations,
  InvestmentsLevel,
  TotalAudience,
  CampaignsSummary,
  CampaignsHistogram,
} from './components/dashboardElements';
import { NavLink } from 'react-router-dom';
import Panel from '../../shared/components/Panel';
import Modal from '../../shared/components/Modal';
import Newsfeed from './components/Newsfeed';

class CampaignsDashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      loading: true,
      onlyMedia: false,
      TotalCampaignsWindows: 0,
      TotalCampaignsWindowsAverage: 0,
      TotalSellIn: 0,
      TotalSellOut: 0,
      TotalAudience: 0,
      TotalAmount: 0,
      TotalAmountAveragePerWindow: 0,
      TotalContactAverage: 0,
      NewImplantations: {
        number: 17,
        percentage: 0,
      },
      NewPremiumImplantations: {
        number: 13,
        percentage: 0,
      },
      TotalCampaigns: 2,
      CampaignsSummary: {},
      Newsfeed: [],
    };
  }

  componentDidMount() {
    const redirect = localStorage.getItem('redirect');
    
    if (this.props.location.search !== '') {
      const params = new URLSearchParams(this.props.location.search);
      if (params.get("content")) {
        switch (params.get("content")) {
          case 'insight':
            this.props.history.push(`/insight/campaigns`);
            break;
        
          default:
            break;
        }
      }
      
    } else if (redirect) {
      this.props.history.push(`/insight/campaigns`);
      localStorage.removeItem('redirect');
    } else {
      this.getIndicatorsFromApi();
      this.getNewsfeed();
    }
  }

  getIndicatorsFromApi = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/campaign/overall-dashboard-indicators/`,
        {
          userid: this.props.authentication.user.id,
        }
      )
      .then((res) => {
        const data = res.data;
        const campaigns = data.overallDashboardCampaignsSummary;
        let tradeCampaigns = [];

        if (Object.keys(campaigns).length > 0) {
          tradeCampaigns = Object.keys(campaigns).filter((i) => {
            return campaigns[i].campaign_type === 'TRADE';
          });
        }

        this.setState({
          loaded: true,
          loading: false,
          onlyMedia: tradeCampaigns.length === 0,
          TotalCampaignsWindows: data
            ? data.overallDashboardTotalCampaignsWindows
            : 0,
          TotalCampaignsWindowsAverage: data
            ? data.overallDashboardTotalCampaignsWindowsAverage
            : 0,
          TotalSellIn: data ? data.overallDashboardTotalSellIn : 0,
          TotalSellOut: data ? data.overallDashboardTotalSellOut : 0,
          TotalAudience: data ? data.overallDashboardTotalAudience : 0,
          TotalAmount: data ? data.overallDashboardTotalAmount : 0,
          TotalAmountAveragePerWindow: data
            ? data.overallDashboardTotalAmountAveragePerWindow
            : 0,
          TotalContactAverage: data
            ? data.overallDashboardTotalContactAverage
            : 0,
          NewImplantations: {
            number: data ? data.overallDashboardNewImplantations.number : 0,
            percentage: data
              ? data.overallDashboardNewImplantations.percentage
              : 0,
          },
          NewPremiumImplantations: {
            number: data
              ? data.overallDashboardNewPremiumImplantations.number
              : 0,
            percentage: data
              ? data.overallDashboardNewPremiumImplantations.percentage
              : 0,
          },
          TotalCampaigns: data ? data.overallDashboardTotalCampaigns : 0,
          CampaignsSummary: data ? data.overallDashboardCampaignsSummary : {},
          campaignsHistogram: data.campaignsHistogram,
        });
      });
  };

  // Newsfeed-related
  getNewsfeed = () => {
    let ignoredBlocks = localStorage.getItem('newsfeed_ignored_blocks');

    if (!ignoredBlocks) ignoredBlocks = [];
    else ignoredBlocks = JSON.parse(ignoredBlocks);

    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/b/fil-actualite/annonceur/`)
      .then((res) => {
        const data = res.data;

        this.setState({
          loaded: true,
          loading: false,
          Newsfeed: data.content.blocks.filter(
            (b) => !ignoredBlocks.includes(b['pk'])
          ),
        });
      });
  };

  // Newsfeed-related
  handleNewsBlockClose = (blockId) => {
    this.setState({
      Newsfeed: this.state.Newsfeed.filter((b) => b.pk != blockId),
    });
  };

  render() {
    const { loaded, loading } = this.state;
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {loaded && (
          <React.Fragment>
            {/* NEWSFEED */}
            <Row className="as-feed-wrapper">
              {this.state.Newsfeed.length > 0 && (
                <Newsfeed
                  blocks={this.state.Newsfeed}
                  onCloseBlock={this.handleNewsBlockClose}
                />
              )}
            </Row>

            <Row>
              <Col lg={12} xl={8} md={12} className="as-padded">
                <h3 className="page-title">Informations de prise en main</h3>
              </Col>
            </Row>
            {this.props.authentication.user.confirmation_email && (
              <Alert color="info">
                <p>N'oubliez pas de confirmer votre adresse email</p>
              </Alert>
            )}

            {process.env.DASHBOARD_ENV === 'bottl' ? (
              <React.Fragment>
                <Row>
                  <Col md={12} lg={12} xl={8}>
                    <Col md={12} lg={12} xl={12}>
                      <Card>
                        <CardBody>
                          <div className="project-summary">
                            <div className="card__title project-summary__card-title">
                              Bienvenue dans votre espace personnel.
                            </div>
                            <p>
                              Vous pouvez dès à présent travailler sur des campagnes en vitrines <NavLink to={`/campaigns/pending`}>(Bottl.window)</NavLink> et accéder à tous vos historiques de campagnes;<br />
                              renforcer votre connaissance des caves et activer votre prospection commerciale <NavLink to={`/finder/dashboard`}>(Bottl.finder)</NavLink>; <br />
                              voire rendre visibles des cavistes sur la plateforme <NavLink to={`/brandpages/new`}>(Bottl.discover)</NavLink> ; ou encore souscrire à nos études sur les cavistes sur <NavLink to={`/insight/campaigns`}>(Bottl.insights)</NavLink> et y accéder instantanément.
                            </p>
                            {/* <p>
                              Il vous suffit pour cela d'aller dans{' '}
                              <strong>
                                "Points de vente {`>`} Vitrines cavistes"
                              </strong>
                            </p>
                            <p>
                              <strong>
                                Pour l'ensemble des autres services, ils sont
                                tous actifs mais pas encore automatisés - c'est
                                en cours -.
                              </strong>
                            </p>
                            <p>
                              Envoyez-nous simplement un email:{' '}
                              <a href="mailto:chloe@bottl.fr">chloe@bottl.fr</a>
                              {` `}et dites-nous ce que vous désirez faire.
                              <br />
                              Nous vous rappelons aussi l'existence de notre
                              simulateur qui vous permet de bâtir instantanément
                              un plan d'actions et d'obtenir le budget
                              nécessaire {`> `}
                              <NavLink to={`/simulateur`}>Simulateur</NavLink>
                            </p>
                            <p>Merci pour votre confiance.</p>
                            <p>
                              Toute l'équipe de Bottl.
                              <br />
                              Téléphone : <strong>09 72 55 46 21</strong>
                            </p> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={12} lg={12} xl={8}>
                    <Panel
                      xs={12}
                      md={12}
                      lg={12}
                      title={`Commander votre panel des cavistes indépendants`}
                    >
                      <Table responsive hover>
                        <thead>
                          <tr>
                            <th></th>
                            <th style={{ textAlign: 'center' }}>Tarif</th>
                            <th style={{ textAlign: 'center' }}>Nombre</th>
                            <th style={{ textAlign: 'center' }}># Réponses</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                style={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                }}
                              >
                                Panel généraliste
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Badge color={`primary`}>2388 €</Badge>
                            </td>
                            <td style={{ textAlign: 'center' }}>4</td>
                            <td style={{ textAlign: 'center' }}>10</td>
                            <td style={{ textAlign: 'right' }}>
                              <Modal
                                color="primary"
                                title={`Merci d'avoir commandé votre Panel.`}
                                message={
                                  <React.Fragment>
                                    <p>
                                      Nous vous envoyons un devis électronique
                                      dans l'heure pour signature. Excelente
                                      journée.
                                    </p>

                                    <p>L'équipe Panel.</p>
                                  </React.Fragment>
                                }
                                btn="Je commande"
                                isBtn
                                ignoreCancel
                              ></Modal>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span
                                style={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                }}
                              >
                                Panel avancé whisky
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Badge color={`primary`}>9960 €</Badge>
                            </td>
                            <td style={{ textAlign: 'center' }}>4</td>
                            <td style={{ textAlign: 'center' }}>25</td>
                            <td style={{ textAlign: 'right' }}>
                              <Modal
                                color="primary"
                                title={`Merci d'avoir commandé votre Panel.`}
                                message={
                                  <React.Fragment>
                                    <p>
                                      Nous vous envoyons un devis électronique
                                      dans l'heure pour signature. Excelente
                                      journée.
                                    </p>

                                    <p>L'équipe Panel.</p>
                                  </React.Fragment>
                                }
                                btn="Je commande"
                                isBtn
                                ignoreCancel
                              ></Modal>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span
                                style={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                }}
                              >
                                Panel avancé rhum
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Badge color={`primary`}>9960 €</Badge>
                            </td>
                            <td style={{ textAlign: 'center' }}>4</td>
                            <td style={{ textAlign: 'center' }}>25</td>
                            <td style={{ textAlign: 'right' }}>
                              <Modal
                                color="primary"
                                title={`Merci d'avoir commandé votre Panel.`}
                                message={
                                  <React.Fragment>
                                    <p>
                                      Nous vous envoyons un devis électronique
                                      dans l'heure pour signature. Excelente
                                      journée.
                                    </p>

                                    <p>L'équipe Panel.</p>
                                  </React.Fragment>
                                }
                                btn="Je commande"
                                isBtn
                                ignoreCancel
                              ></Modal>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <span
                                style={{
                                  textTransform: 'uppercase',
                                  fontWeight: 'bold',
                                }}
                              >
                                Panel avancé champagne
                              </span>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              <Badge color={`primary`}>9960 €</Badge>
                            </td>
                            <td style={{ textAlign: 'center' }}>4</td>
                            <td style={{ textAlign: 'center' }}>25</td>
                            <td style={{ textAlign: 'right' }}>
                              <Modal
                                color="primary"
                                title={`Merci d'avoir commandé votre Panel.`}
                                message={
                                  <React.Fragment>
                                    <p>
                                      Nous vous envoyons un devis électronique
                                      dans l'heure pour signature. Excelente
                                      journée.
                                    </p>

                                    <p>L'équipe Panel.</p>
                                  </React.Fragment>
                                }
                                btn="Je commande"
                                isBtn
                                ignoreCancel
                              ></Modal>
                            </td>
                            <td style={{ textAlign: 'right' }}><Badge color={`primary`}>Je commande</Badge></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Panel>
                  </Col>
                </Row> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <AbsoluteData
                    totalCampaigns={this.state.TotalCampaigns}
                    totalCampaignsWindows={this.state.TotalCampaignsWindows}
                    TotalCampaignsWindowsAverage={
                      this.state.TotalCampaignsWindowsAverage
                    }
                  />
                  <CampaignsHistogram
                    media={this.state.onlyMedia}
                    data={this.state.campaignsHistogram}
                  />
                </Row>
                <Row>
                  {this.state.onlyMedia ? (
                    <React.Fragment>
                      <TotalAudience totalAudience={this.state.TotalAudience} />
                      <InvestmentsLevel
                        media={this.state.onlyMedia}
                        totalAmount={this.state.TotalAmount}
                        totalAmountAveragePerWindow={
                          this.state.TotalAmountAveragePerWindow
                        }
                        totalContactAverage={this.state.TotalContactAverage}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Col lg={3} xl={3} md={12} xs={12}>
                        <Row>
                          <TotalSellInSellOut
                            totalSellIn={this.state.TotalSellIn}
                            totalSellOut={this.state.TotalSellOut}
                          />
                        </Row>
                      </Col>
                      <Col lg={9} xl={9} md={12} xs={12}>
                        <Row>
                          <TotalNewImplantations
                            newImplantations={this.state.NewImplantations}
                            newPremiumImplantations={
                              this.state.NewPremiumImplantations
                            }
                          />
                          <TotalAudience
                            totalAudience={this.state.TotalAudience}
                          />
                          <InvestmentsLevel
                            media={this.state.onlyMedia}
                            totalAmount={this.state.TotalAmount}
                            totalAmountAveragePerWindow={
                              this.state.TotalAmountAveragePerWindow
                            }
                            totalContactAverage={this.state.TotalContactAverage}
                          />
                        </Row>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
                <Row>
                  <CampaignsSummary
                    campaignsSummary={this.state.CampaignsSummary}
                  />
                </Row>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(CampaignsDashboard);
