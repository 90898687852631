const initState = {
  modalForm: false,
  campaignNameForm: false,
  isLogged: false,
  infos: {},
};

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const TOGGLE_FORM = 'TOGGLE_FORM';
const TOGGLE_CAMPAIGN_NAME_FORM = 'TOGGLE_CAMPAIGN_NAME_FORM';

export default (state = initState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action };
    case LOGOUT:
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('miw_user_session');
      }
      return { ...initState };
    case TOGGLE_FORM:
      return { ...state, modalForm: !state.modalForm };
    case TOGGLE_CAMPAIGN_NAME_FORM:
      return { ...state, modalForm: !state.campaignNameForm };
    default:
      return state;
  }
};
