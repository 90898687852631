import React from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap';
import NumericFormat from 'react-number-format';
import InsightResponseTable from './Participation/Table';

export const Questions = (props) => {
  return (
    <React.Fragment>
      {
        props.questions.length > 0 && props.questions.map((question) => {
          return (
            <Row key={`question-${question.id}`}>
              <Col md={12}>
                <div xs={12} className="todo__item">
                  <Card>
                    <CardBody className="todo__item">
                      <div className="todo__info">
                        <div className="todo__header" style={{ display: 'block' }}>
                          <h4 style={{
                            fontSize: '16px',
                            marginBottom: '1rem'
                          }}>{question.entitled}</h4>
                        </div>
                        <div className="todo__content">
                          <Responses 
                            answers={question.answers} 
                            data={question.data} 
                            columns={question.columns} 
                            type={question.type_label}
                            question={question}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          )
        })
      }
    </React.Fragment>
  )
}

export const Responses = (props) => {
  const { question, data, columns, type, answers } = props;

  switch (type) {
    case 'SELECT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'FREE_TEXT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'YES_OR_NO_WITH_TEXT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'YES_OR_NO_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'PERCENTAGE_QUESTION_TYPE':
      return (
        <div className="todo__description">
          {
            answers.length > 0 && answers.map((answer, index) => {
              return (
                <NumericFormat 
                  key={`answer-${question.id}-${index}`} 
                  displayType="text" 
                  value={answer.answer} 
                  thousandsGroupStyle="thousand" 
                  thousandSeparator=" " 
                  decimalScale={2} 
                  decimalSeparator="," 
                  suffix={'%'}
                />
              )
            })
          }
        </div>
      )

    case 'NUMBER_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'AMOUNT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          {
            answers.length > 0 && answers.map((answer, index) => {
              return (
                <NumericFormat 
                  key={`answer-${question.id}-${index}`} 
                  displayType="text" 
                  value={answer.answer} 
                  thousandsGroupStyle="thousand" 
                  thousandSeparator=" " 
                  decimalScale={2} 
                  decimalSeparator="," 
                  suffix={' €'}
                />
              )
            })
          }
        </div>
      )

    case 'TABLE_QUESTION_TYPE':
      return (
        <InsightResponseTable 
          answers={answers}
          question={question.id}
          columns={columns} 
          rows={data}
        />
      )

    case 'SELECT_MULTIPLE_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    case 'DROPDOWN_WITH_TEXT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )

    default:
      return (
        <div className="todo__description">
          <Response answers={answers} />
        </div>
      )
  }
}

export const Response = (props) => {
  const { answers } = props;
  
  return (
    <React.Fragment>
      {
        answers.length > 0 && answers.map((answer, index) => {
          return (
            <React.Fragment key={`response-${index}-${answer.id}`} >
              {answer.answer}
            </React.Fragment>
          )
        })
      }
    </React.Fragment>
  )
  
}