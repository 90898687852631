import React, { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/fr'
import {
  CartesianGrid, 
  ComposedChart, 
  Line, 
  ResponsiveContainer, 
  Tooltip, 
  XAxis, 
  YAxis
} from 'recharts';
import { Table } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';
import StatisticsTooltipContent from './StatisticsTooltipContent';

moment.locale("fr");

const labels = {
  'total_visitors': 'Visites',
  'total_unique_visitors': 'Visiteurs uniques',
  'total_clicks_contact': 'Bouton contacter',
  'total_clicks_contact_requests': 'Bouton de demande de RDV'
}

const colors = ['#00f0bc', '#fbd307', '#b8e986', '#48b5ff', '#fb6207'];

export default ({ stats }) => {

  const graphData = Object.keys(stats.graph).map(item => {
    return {
      key: item,
      name: moment.unix(item).format('MMMM YYYY'),
      visitors: stats.graph[item].total_visitors,
      unique_visitors: stats.graph[item].total_unique_visitors
    }
  });
  const tableData = Object.keys(stats.general).map((item, index) => {
    return {
      id: index,
      head: labels[item] || item,
      data: Object.keys(stats.general[item].dates).map(i => stats.general[item].dates[i])
    }
  });

  return (
    <Panel
      xl={12}
      lg={12}
      md={12}
      title={`Statistiques de votre site`}
      subhead=""
    >
      <div>
        <ResponsiveContainer height={260}>
          <ComposedChart data={graphData} margin={{ top: 20, left: -15 }}>
            <XAxis dataKey="name" tickLine={true} padding={{ left: 20 }} />
            <YAxis tickLine={true} />
            <Tooltip content={<StatisticsTooltipContent />} />
            <CartesianGrid vertical={true} />

            <Line type="linear" name="Visites" dataKey="visitors" stroke={colors[0]} />
            <Line type="linear" name="Visiteurs uniques" dataKey="unique_visitors" stroke={colors[1]} />
            <Line type="linear" name="Menu: marque (clicks)" dataKey="brand_page" stroke={colors[2]} />

          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <hr />
      <div>
        <Table striped responsive>
          <tbody>
            <tr>
              <td></td>
              {
                graphData.map((item, index) => {
                  return (
                    <td key={`general-month-${index}`} style={{ textAlign: 'right' }}>
                      <strong>{item.name}</strong>
                    </td>
                  )
                })
              }
            </tr>
            {tableData.map((items, index) => (
              <tr key={`global-row-stat-${index}`}>
                <td className="td-head" style={{ textAlign: 'right' }}>
                  <strong>{items.head}</strong>
                  <span style={{
                    background: colors[index],
                    display: 'inline-block',
                    padding: '.2rem .5rem',
                    marginLeft: '.3rem'
                  }}>
                  </span>
                </td>
                <Fragment>
                  {items.data.map((item, i) => (
                    <td 
                      key={`global-stat-${i}`} 
                      style={{ textAlign: 'right' }}
                    >
                      {item}
                    </td>
                  ))}
                </Fragment>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};
