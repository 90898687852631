import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Overview extends React.Component {
  state = {
    showAll: false,
  };

  constructor(props) {
    super(props);

    this.viewAll = this.viewAll.bind(this);
  }
  viewAll() {
    this.setState({
      showAll: true,
    });
  }
  render() {
    let { showAll } = this.state;
    let { _import, isFull, type } = this.props;

    let headers = [];
    let rows = [];
    if (!isFull) {
      headers =
        _import.has_header == true
          ? _import.header
          : _import.sample_lines[0].map((cell, idx) => `CHAMP ${idx + 1}`);

      rows = _import.sample_lines;
    } else {
      headers =
        _import.has_header == true
          ? _import.rows[0]
          : _import.rows[0].map((cell, idx) => `CHAMP ${idx + 1}`);

      rows = _import.has_header == true ? _import.rows.slice(1) : _import.rows;
    }

    return (
      <div className="as-import-overview">
        {!isFull && type !== 'list' && <p>Aperçu de votre fichier</p>}
        {isFull && type !== 'list' && <p>Contenu de votre fichier</p>}

        {!isFull && type === 'list' && <p>Aperçu de votre liste</p>}
        {isFull && type === 'list' && <p>Contenu de votre liste</p>}

        <table className="as-table">
          <thead>
            <tr>
              {headers.map((h, idx) => (
                <th key={idx}>{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, ida) => (
              <tr
                key={ida}
                style={{ display: ida > 14 && !showAll ? 'none' : 'table-row' }}
              >
                {row.map((cell, idb) => (
                  <td key={idb}>
                    {cell.length > 30 ? `${cell.substr(0, 30)}...` : cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {rows.length > 15 && !showAll && (
          <Row style={{ marginTop: 24 }}>
            <Col md={12} align={'center'}>
              <Button color="primary" outline onClick={this.viewAll}>
                Voir tout
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Overview;
