import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import Swal from 'sweetalert2';

class Inmails extends React.Component {
  state = {
    loaded: false,
    loading: false,
    conversations: [],
    searching: false,
    currentConversation: null,
    message: '',
  };

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  _toBase64 = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.addEventListener('load', function (evt) {
        res(evt.target.result);
      });

      reader.readAsDataURL(file);
    });
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();
    this.thread = React.createRef();
    this.filePicker = React.createRef();

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAttachment = this.handleSubmitAttachment.bind(this);
    this.handleWrite = this.handleWrite.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.triggerAttachmentPicker = this.triggerAttachmentPicker.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/inmails/list`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        if (!this.props.match.params.id)
          this.setState({
            loaded: true,
            loading: false,
            conversations: res.data.content.conversations,
          });
        else {
          axios
            .post(
              `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}/${this.props.match.params.id}`,
              {
                id: user.id,
                token: user.token,
                passphrase: user.passphrase,
              }
            )
            .then((resx) => {
              this.setState(
                {
                  loaded: true,
                  loading: false,
                  conversations: res.data.content.conversations,
                  currentConversation: resx.data.content.conversation,
                },
                () => {
                  this.thread.current.scrollTop = this.thread.current.scrollHeight;
                }
              );
            })
            .catch((e) => this.props.history.push('/finder/inmails'));
        }
      })
      .catch((err) => {
        this.setState({ loaded: true, conversations: null });
      });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    const {
      authentication: { user },
    } = this.props;

    const newMessage = {
      recipientId: this.props.match.params.id,
      content: this.state.message,
    };

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/inmails`, {
        ...newMessage,
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((r) => {
        this.setState(
          {
            currentConversation: {
              ...this.state.currentConversation,
              messages: [
                ...this.state.currentConversation.messages,
                {
                  fk_sender: user.id,
                  fk_recipient: newMessage.recipientId,
                  content: newMessage.content,
                  sent_at: new Date(),
                },
              ],
            },
            message: '',
          },
          () => {
            this.thread.current.scrollTop = this.thread.current.scrollHeight;
          }
        );
      });
  }

  handleSearch(s) {
    this.setState({ searching: true });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/inmails/list`;

    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}?search=${s}`,
        {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        }
      )
      .then((res) => {
        if (!this.props.match.params.id)
          this.setState({
            conversations: res.data.content.conversations,
          });
        else {
          axios
            .post(
              `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}/${this.props.match.params.id}`,
              {
                id: user.id,
                token: user.token,
                passphrase: user.passphrase,
              }
            )
            .then((resx) => {
              this.setState({
                loaded: true,
                loading: false,
                conversations: res.data.content.conversations,
                currentConversation: resx.data.content.conversation,
              });
            })
            .catch((e) => this.props.history.push('/finder/inmails'));
        }
      });
  }

  handleSubmitAttachment(evt) {
    evt.preventDefault();

    const fp = this.filePicker.current;

    if (fp.files.length === 0) return;

    if (fp.files[0].size / (1024 * 1024) > 2) {
      this._showError(
        'Veuillez utliser des pièces jointes ne dépassant pas 2 mégaoctets.'
      );
      return;
    }

    this.setState({ loaded: false, loading: true });

    this._toBase64(fp.files[0]).then((b64) => {
      const {
        authentication: { user },
      } = this.props;

      const newMessage = {
        recipientId: this.props.match.params.id,
        attachment: b64,
      };

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/inmails`, {
          ...newMessage,
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        })
        .then((r) => {
          if (!r.data.content.error)
            this.setState(
              {
                loaded: true,
                loading: false,
                currentConversation: {
                  ...this.state.currentConversation,
                  messages: [
                    ...this.state.currentConversation.messages,
                    {
                      fk_sender: user.id,
                      fk_recipient: newMessage.recipientId,
                      content: r.data.content.inmail.content,
                      attachment: r.data.content.inmail.attachment,
                      sent_at: new Date(),
                    },
                  ],
                },
              },
              () => {
                this.thread.current.scrollTop = this.thread.current.scrollHeight;
              }
            );
          else {
            this._showError(r.data.content.error);
            this.setState({ loaded: true, loading: false }, () => {
              setTimeout(() => {
                this.thread.current.scrollTop = this.thread.current.scrollHeight;
              }, 500);
            });
          }
        });
    });
  }

  triggerAttachmentPicker(evt) {
    evt.preventDefault();
    this.filePicker.current.click();
  }

  handleWrite(evt) {
    this.setState({ message: evt.target.value });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/inmails/list`;

      axios
        .post(
          `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}/${this.props.match.params.id}`,
          {
            id: user.id,
            token: user.token,
            passphrase: user.passphrase,
          }
        )
        .then((resx) => {
          this.setState(
            {
              loaded: true,
              loading: false,
              currentConversation: resx.data.content.conversation,
            },
            () => {
              this.thread.current.scrollTop = this.thread.current.scrollHeight;
            }
          );
        })
        .catch((e) => this.props.history.push('/finder/inmails'));
    }
  }

  render() {
    let {
      loaded,
      loading,
      conversations,
      currentConversation,
      searching,
      message,
    } = this.state;
    const conversationId = this.props.match ? this.props.match.params.id : null;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {loaded && (
          <Row>
            <Col md={12}>
              <h3 className="page-title">Mes inmails</h3>
            </Col>
          </Row>
        )}
        {loaded && conversations != null && (
          <React.Fragment>
            <Row>
              <div className="as-messenger">
                <div
                  className={`as-left ${
                    conversations.length == 0 && !searching ? 'as-empty' : ''
                  }`}
                >
                  <div className="as-search">
                    <input
                      type="text"
                      name="search"
                      placeholder="Rechercher..."
                      onInput={(e) => this.handleSearch(e.target.value)}
                    />
                  </div>
                  {conversations.map((c, idx) => (
                    <Link
                      key={idx}
                      className={`as-conversation ${
                        conversationId == c.recipient_id ? 'as-active' : ''
                      }`}
                      to={`/finder/inmails/${c.recipient_id}`}
                    >
                      <div className="as-conversation-header">
                        <img
                          src={`${
                            c.recipient.img
                              ? `${process.env.REACT_APP_WEBROOT}/photos/users/${c.recipient_id}/${c.recipient.img}`
                              : `${process.env.REACT_APP_WEBROOT}/images/man.svg`
                          }`}
                          alt={`${c.recipient_id.firstname} ${c.recipient.lastname}`}
                        />
                        <span className="sender">
                          <strong>
                            {c.recipient.firstname} {c.recipient.lastname} (
                            {c.shop.name})
                          </strong>
                        </span>
                      </div>

                      <p>{c.last_message}</p>
                      <span className="time">
                        {moment(c.last_message_at).format('DD/MM/YYYY')}
                      </span>
                    </Link>
                  ))}
                </div>
                <div className="as-right">
                  {!currentConversation && conversations.length > 0 && (
                    <p className="placeholder">
                      Veuillez sélectionner une conversation dans le volet
                      gauche
                    </p>
                  )}
                  {!currentConversation &&
                    !conversations.length > 0 &&
                    !searching && (
                      <p className="placeholder">
                        Vous n'avez aucune conversation pour le moment
                      </p>
                    )}
                  {!conversations.length > 0 && searching && (
                    <p className="placeholder">
                      Aucune conversation ne correspond à votre recherche
                    </p>
                  )}
                  {currentConversation && conversations.length > 0 && (
                    <div className="as-dialog">
                      <div className="as-thread as-visible" ref={this.thread}>
                        {currentConversation.messages.map((m, midx) => (
                          <div
                            key={midx}
                            className={`as-message ${
                              m.fk_sender == this.props.authentication.user.id
                                ? 'as-sent'
                                : 'as-received'
                            }`}
                          >
                            {m.fk_sender !=
                              this.props.authentication.user.id && (
                              <React.Fragment>
                                <img
                                  src={`${
                                    currentConversation.sender.img
                                      ? `${process.env.REACT_APP_WEBROOT}/photos/users/${currentConversation.sender_id}/${currentConversation.sender.img}`
                                      : `${process.env.REACT_APP_WEBROOT}/images/man.svg`
                                  }`}
                                  alt={`${currentConversation.sender.firstname} ${currentConversation.sender.lastname}`}
                                />
                                <p className="content">
                                  {m.attachment && (
                                    <a
                                      target="_blank"
                                      href={`${process.env.REACT_APP_BOTTL_WEBROOT}/assets/img/uploads/${m.attachment}`}
                                    >
                                      {m.content}
                                    </a>
                                  )}
                                  {!m.attachment && <>{m.content}</>}
                                </p>
                                <span className="time">
                                  {moment(m.sent_at).format('DD/MM/YYYY')}
                                </span>
                              </React.Fragment>
                            )}

                            {m.fk_sender ==
                              this.props.authentication.user.id && (
                              <React.Fragment>
                                <span className="time">
                                  {moment(m.sent_at).format('DD/MM/YYYY')}
                                </span>
                                <p className="content">
                                  {m.attachment && (
                                    <a
                                      target="_blank"
                                      href={`${process.env.REACT_APP_BOTTL_WEBROOT}/assets/img/uploads/${m.attachment}`}
                                    >
                                      {m.content}
                                    </a>
                                  )}
                                  {!m.attachment && <>{m.content}</>}
                                </p>
                                <img
                                  src={`${
                                    currentConversation.recipient.img
                                      ? `${process.env.REACT_APP_WEBROOT}/photos/users/${currentConversation.recipient.id}/${currentConversation.recipient.img}`
                                      : `${process.env.REACT_APP_WEBROOT}/images/man.svg`
                                  }`}
                                  alt={`${currentConversation.recipient.firstname} ${currentConversation.recipient.lastname}`}
                                />
                              </React.Fragment>
                            )}
                          </div>
                        ))}
                      </div>

                      <form
                        className="as-footer-form"
                        onSubmit={this.handleSubmit}
                        ref={this.form}
                      >
                        <input
                          type="file"
                          name="attachment"
                          style={{ display: 'none' }}
                          accept=".jpg, .png, .jpeg, .pdf"
                          onChange={this.handleSubmitAttachment}
                          ref={this.filePicker}
                        />

                        <button
                          onClick={this.triggerAttachmentPicker}
                          type="button"
                          className="as-attachment-picker"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                            />
                          </svg>
                        </button>

                        <input
                          type="text"
                          name="message"
                          placeholder="Commencez à écrire..."
                          onChange={this.handleWrite}
                          value={message}
                        />
                        <button type="submit" className="as-send-btn">
                          Envoyer
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && conversations == null && (
          <div className="inmails-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Inmails);
