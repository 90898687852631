import React, { Component, Fragment } from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { history } from '../../helpers';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    if (process.env === 'development') {
      console.log(process.env);
    }

    // if (this.props.location.search.includes('?_t=')) {
    //   const dashboardTokenString = this.props.location.search.replace('?_t=', '').split('&');
    //   const dashboardToken = dashboardTokenString[0];
    //   if (typeof dashboardTokenString[1] !== 'undefined') {
    //     const param = dashboardTokenString[1];
    //     localStorage.setItem('redirect', param);
    //   }

    //   this.token = dashboardToken;
    //   this.props.tokenLogin(this.token);
    // }
    window.addEventListener('load', () => {
      setTimeout(() => this.setState({ loading: false, loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              {!loaded && (
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path
                        fill="#5b7da5"
                        d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div>
                <Router history={history} />
              </div>
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);
