import React from 'react';
import { connect } from 'react-redux';
// import axios from 'axios';
import Loading from '../App/Loading';
import {
  // Col, 
  Container,
  Row,
  Button,
} from 'reactstrap';
import Panel from '../../shared/components/Panel';

class BrandPagesNewItem extends React.Component {

  configurator = () => {
    if (typeof window !== 'undefined') {
      window.open(`${process.env.REACT_APP_BOTTL_WEBROOT}/brandpages-onboarding/`);
    }
  }

  render() {
    return (
      <Container className="dashboard">
        <React.Fragment>
          <Row>
            <Panel
              xs={12}
              md={12}
              lg={6}
              color="primary"
              title={`Créer une nouvelle page pour votre marque`}
              subhead={`NOTE: Afin de créer une nouvelle page vous devez souscrire à un nouvel abonnement.`}
            >
              <Button color="primary" outline size="sm" onClick={this.configurator}>
                Souscrire à un nouvel abonnement et créer ma page
              </Button>
            </Panel>
          </Row>
        </React.Fragment>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication
});
export default connect(mapStateToProps)(BrandPagesNewItem);
