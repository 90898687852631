import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ImportedShopsList from './Import/list';

class Dashboard extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    importedShops: [],
  };

  constructor(props) {
    super(props);

    this.shopOpen = this.shopOpen.bind(this);
    this.importOpen = this.importOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imported-shops/latest`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((res) => {
            this.setState({
              importedShops: res.data.content.shops,
              loaded: true,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  shopOpen(id) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/IMP-${id}-.html?is_imported=1`,
      '_blank'
    );
  }

  importOpen(id) {
    this.props.history.push('/finder/intelligence/imports/' + id);
  }

  render() {
    let { loaded, loading, subscription, importedShops } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            {/* 1ST ROW */}
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <h3 className="page-title">Mes bases</h3>
                  </Col>
                  {importedShops.length > 0 && (
                    <Col md={6} align="right">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        // to="/finder/intelligence/import"
                        onClick={() => {
                          this.props.history.push(
                            '/finder/intelligence/import'
                          );
                        }}
                      >
                        Faire un nouvel import
                      </Button>
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        onClick={() => {
                          this.props.history.push(
                            '/finder/intelligence/imports'
                          );
                        }}
                      >
                        Voir mes imports passés
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {importedShops.length === 0 && (
              <Row>
                <Col md={12}>
                  <div className="subscription-placeholder">
                    <div className="placeholder-wrapper">
                      <p
                        style={{ textAlign: 'center' }}
                        className="placeholder"
                      >
                        Ici, vous pouvez charger votre ou vos bases de données
                        de cavistes et les visualiser dans Finder.
                        <br />
                        Cela vous permet de stocker vos données dans Finder, de
                        les mettre à jour quand vous voulez, de les enrichir, et
                        de bien en profiter.
                        <br />
                        Nous ne récupérons aucune donnée, ce sont strictement
                        les vôtres.
                      </p>
                      <br />
                      <button
                        onClick={() => {
                          this.props.history.push(
                            '/finder/intelligence/import'
                          );
                        }}
                        className="btn btn-primary"
                      >
                        Nouvel import
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {importedShops.length > 0 && (
              <>
                <Row>
                  <Col md={12}>
                    <div className="as-import-shops-overview">
                      <p>Liste de vos dernières caves importées</p>
                      <ImportedShopsList
                        shops={importedShops}
                        shownControls={['view', 'view_import']}
                        onShopOpen={this.shopOpen}
                        onImportOpen={this.importOpen}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Dashboard);
