import React, { PureComponent } from 'react';
import config from 'config';
import axios from 'axios';
import { Card, CardBody, Row, Col, Button, ButtonToolbar } from 'reactstrap';
// import { Field, reduxForm } from 'redux-form';
// import EyeIcon from 'mdi-react/EyeIcon';
// import EmailIcon from 'mdi-react/EmailIcon';
// import AccountSearchIcon from 'mdi-react/AccountSearchIcon';
// import PropTypes from 'prop-types';
// import renderFileInputField from '../../../shared/components/form/FileInput';
// import renderSelectField from '../../../shared/components/form/Select';
import Select from 'react-select';
// import renderMultiSelectField from '../../../shared/components/form/MultiSelect';

class HorizontalForm extends PureComponent {
  static propTypes = {
    // t: PropTypes.func.isRequired,
    // handleSubmit: PropTypes.func.isRequired,
    // reset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      data: { infos, civilities, countries, formesJuridiques },
    } = props;

    this.state = {
      showPassword: false,
      data: {
        civility: infos.civility,
        lastname: infos.lastname,
        firstname: infos.firstname,
        fonction: infos.fonction,
        email: infos.email,
        phone: infos.phone,
        societe: infos.societe,
        formejuridique: infos.formejuridique,
        siren: infos.siren,
        tva: infos.tva,
        rcs: infos.rcs,
        societe_street: infos.societe_street,
        societe_location: infos.societe_location,
        societe_zipcode: infos.societe_zipcode,
        pays: infos.pays,
        invoice_street: infos.invoice_street,
        invoice_location: infos.invoice_location,
        invoice_zipcode: infos.invoice_zipcode,
        invoice_country: infos.invoice_country,
        invoice_email: infos.invoice_email,
        societe_certification: infos.societe_certification,
      },
    };

    this.initialValues = this.state.data;
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/set-profile/`,
        {
          data: this.state.data,
          userid: this.props.userid,
          id: this.props.user.id,
          token: this.props.user.token,
          passphrase: this.props.user.passphrase,
        }
      )
      .then((res) => {
        const data = res.data;
        // console.log(data);
      });
  };

  handleChangeCivility = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        civility: data.value,
      },
    });
  };

  handleChangeFormeJuridique = (data) => {
    this.setState({
      data: {
        ...this.state.data,
        formejuridique: data.value,
      },
    });
  };

  reset = () => {
    // console.log(this.state);
  };

  changeValue = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const {
      reset,
      data: { infos, civilities, countries, formesJuridiques },
    } = this.props;

    const { showPassword } = this.state;
    return (
      <React.Fragment>
        {/** infos personnelles */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Vos informations personnelles</h5>
                </div>

                <form
                  className="form form--vertical"
                  onSubmit={this.handleSubmit}
                >
                  <div className="form__half">
                    <div
                      className="form__form-group"
                      style={{ marginBottom: 10 }}
                    >
                      <span className="form__form-group-label">
                        Votre civilité
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="civility"
                          defaultValue={{
                            label: this.initialValues.civility,
                            value: this.initialValues.civility,
                          }}
                          onChange={this.handleChangeCivility}
                          options={civilities.map((item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })}
                          clearable={false}
                          className="react-select"
                          classNamePrefix="react-select"
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Votre adresse email
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="email"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.email || ''}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Votre n° de téléphone
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="phone"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.phone || ''}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Votre nom</span>
                      <div className="form__form-group-field">
                        <input
                          name="lastname"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.lastname || ''}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Votre prénom
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="firstname"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.firstname || ''}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Votre fonction
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="fonction"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.fonction || ''}
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={this.handleSubmit}>
                      Enregistrer
                    </Button>
                    {/* <Button type="button" onClick={this.reset}>
                      Annuler
                    </Button> */}
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/** update mot de passe */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">
                    Modification de votre mot de passe
                  </h5>
                </div>

                <form
                  className="form form--vertical"
                  onSubmit={this.handleSubmit}
                >
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Mot de passe actuel
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="password"
                          // component="input"
                          type="password"
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Nouveau mot de passe
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="new_password"
                          // component="input"
                          type="password"
                          onChange={this.changeValue}
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Enregistrer
                    </Button>
                    {/* <Button type="button" onClick={this.reset}>
                      Annuler
                    </Button> */}
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/** Informations société */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">
                    Informations sur votre entreprise
                  </h5>
                </div>

                <form
                  className="form form--vertical"
                  onSubmit={this.handleSubmit}
                >
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Nom de votre société
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="societe"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.societe || ''}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        SIREN de votre société
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="siren"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.siren || ''}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Numéro de rue et nom de la voie
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="societe_street"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.societe_street || ''}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ville</span>
                      <div className="form__form-group-field">
                        <input
                          name="societe_location"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={
                            this.initialValues.societe_location || ''
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__half">
                    <div
                      className="form__form-group"
                      style={{ marginBottom: 10 }}
                    >
                      <span className="form__form-group-label">
                        Forme juridique de votre société
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="civility"
                          defaultValue={
                            this.initialValues.formejuridique
                              ? {
                                  label:
                                    formesJuridiques[
                                      this.initialValues.formejuridique
                                    ],
                                  value: this.initialValues.formejuridique,
                                }
                              : []
                          }
                          placeholder={<div>Sélectionner</div>}
                          onChange={this.handleChangeFormeJuridique}
                          options={Object.keys(formesJuridiques).map((id) => {
                            return {
                              value: id,
                              label: formesJuridiques[id],
                            };
                          })}
                          clearable={false}
                          className="react-select"
                          classNamePrefix="react-select"
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        N°TVA de votre société
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="tva"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.tva || ''}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Ville d'immatriculation au RCS
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="rcs"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.rcs || ''}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Code postal
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="societe_zipcode"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={
                            this.initialValues.societe_zipcode || ''
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Enregistrer
                    </Button>
                    {/* <Button type="button" onClick={this.reset}>
                      Annuler
                    </Button> */}
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/** Facturation */}
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">
                    Adresse de facturation si différent
                  </h5>
                </div>

                <form
                  className="form form--vertical"
                  onSubmit={this.handleSubmit}
                >
                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Numéro et nom de la voie
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="invoice_street"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.invoice_street || ''}
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Code postal
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="invoice_zipcode"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={
                            this.initialValues.invoice_zipcode || ''
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form__half">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ville</span>
                      <div className="form__form-group-field">
                        <input
                          name="invoice_location"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={
                            this.initialValues.invoice_location || ''
                          }
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Email de facturation
                      </span>
                      <div className="form__form-group-field">
                        <input
                          name="invoice_email"
                          // component="input"
                          type="text"
                          onChange={this.changeValue}
                          defaultValue={this.initialValues.invoice_email || ''}
                        />
                      </div>
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Enregistrer
                    </Button>
                    {/* <Button type="button" onClick={this.reset}>
                      Annuler
                    </Button> */}
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default HorizontalForm;
