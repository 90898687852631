import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Loading from '../App/Loading';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
// import BottlDataTable from './components/DataTable';
import Map from './components/Campaign/Map'
import Panel from '../../shared/components/Panel';
import NumericFormat from 'react-number-format';
import { Questions } from './components/QuestionResponseSummary';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';

class InsightCampaign extends React.Component {

  state = {
    loaded: false,
    loading: true,
    collection: [],
    campaign: [],
    participations_map: [],
    participations_count: 0,
    selected: []
  }

  componentDidMount() {
    
    const { authentication: { user } } = this.props;
    const endpoint = `insight/campaign/${this.props.match.params.id}`;

    if (!this.props.sidebar.collapse) {
      this.props.changeSidebarVisibility();
    }

    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
      id: user.id,
      token: user.token,
      passphrase: user.passphrase
    }).then(res => {
      this.setState({ 
        loaded: true, 
        loading: false,
        campaign: res.data.content.campaign,
        participations_map: res.data.content.participations_map,
        participations_count: res.data.content.participations_count,
        collection: res.data.content.participations,
        selected: typeof res.data.content._questions !== 'undefined' ? (
          res.data.content._questions
        ) : []
      })
    });
  }

  render() {
    
    const { loaded, loading, campaign, collection } = this.state;
    const { history, match, authentication: { user } } = this.props;
    
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
          {
            loaded && (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <h3 className="page-title">CAMPAGNE <strong>{campaign.label}</strong></h3>
                    <h4 className="page-subhead subhead" style={{marginBottom:'.5rem'}}>
                      Type de campagne : <strong>Insight</strong>
                    </h4>
                    <h3 className="page-subhead subhead">
                      <Link to={`/insight/campaigns`}>
                        Retour à la liste des campagnes <ChevronRightIcon />
                      </Link>
                    </h3>
                  </Col>
                </Row>
                <Row style={{ alignItems: 'flex-end' }}>
                  <Panel lg={4} xl={4} md={12} xs={12}>
                    <div className="dashboard__stat dashboard__stat--budget">
                      <div className="dashboard__stat-main">
                        <p className="dashboard__stat-main-title">Nb. de participations</p>
                      <p className="dashboard__stat-main-number">{this.state.participations_count}</p>
                        <hr />
                      </div>
                      <div className="dashboard__stat-main">
                        <p className="dashboard__stat-main-title">Pourcentage des cavistes français</p>
                        <p className="dashboard__stat-main-number">
                          <NumericFormat 
                            displayType="text" 
                            value={this.state.participations_map.caviste_percentage} 
                            thousandsGroupStyle="thousand" 
                            thousandSeparator=" " 
                            decimalScale={2} 
                            decimalSeparator="," 
                            suffix={'%'}
                          />
                        </p>
                        <hr />
                      </div>
                      <div className="dashboard__stat-main">
                        <p className="dashboard__stat-main-title">Pourcentage des cavistes indépendants</p>
                        <p className="dashboard__stat-main-number">
                          <NumericFormat 
                            displayType="text" 
                            value={this.state.participations_map.caviste_independant_percentage} 
                            thousandsGroupStyle="thousand" 
                            thousandSeparator=" " 
                            decimalScale={2} 
                            decimalSeparator="," 
                            suffix={'%'}
                          />
                        </p>
                        <hr />
                      </div>
                    </div>
                  </Panel>
                  <Map items={this.state.participations_map.all}  />
                </Row>
                <Row style={{marginTop: '4rem'}}>
                  <Col md={12}>
                    <h3 className="page-title" style={{margin:0}}>SYNTHÈSE DES RÉPONSES</h3>
                  </Col>
                </Row>
                
                <React.Fragment>
                  {this.state.collection.length > 0 && this.state.collection.map((item, index) => {
                    return (
                      <React.Fragment key={`category-${index}`}>
                        <Row style={{ marginBottom: '1rem', marginTop: '4rem' }}>
                          <Col md={12}>
                            <h4>{item.category
                            .replace('VOTRE', '')
                            .replace(' & VOUS','')
                            .replace('VOS', '') }</h4>
                          </Col>
                        </Row>
                        <Questions questions={item.questions} />
                      </React.Fragment>
                    )
                  })}
                </React.Fragment>

                <Row>
                  <Col md={12} lg={12}>
                    {/* <h4 style={{ marginBottom: '1rem' }}>Liste des participations</h4> */}
                    {/* <BottlDataTable 
                      endpoint={participationsEndpoint} 
                      history={history}
                      user={user} 
                      link={`/insight/participation/${campaign.id}`}
                      columns={[
                        {
                          name: 'Nom de la cave',
                          selector: row => row.name,
                        },
                        {
                          name: 'N° de participation',
                          selector: row => row.id,
                        },
                      ]}
                    /> */}
                  </Col>
                </Row>
              </React.Fragment>
            )
          }
      </Container>
    )
  }
}


const mapDispatchToProps = (dispatch) => ({
  changeSidebarVisibility: (state) => dispatch(changeSidebarVisibility(state)),
});
const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps, mapDispatchToProps)(InsightCampaign);