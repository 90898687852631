import React, { Component } from 'react';
import { Collapse } from 'reactstrap';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class SidebarCategory extends Component {
  // static propTypes = {
  //   title: PropTypes.string.isRequired,
  //   icon: PropTypes.string,
  //   isNew: PropTypes.bool,
  //   children: PropTypes.arrayOf(PropTypes.element).isRequired,
  // };

  static defaultProps = {
    icon: '',
    image: '',
    isNew: false,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  };

  componentDidMount() {
    const redirect = localStorage.getItem('redirect');
    if (redirect && this.props.title === 'Business intelligence') {
      this.toggle();
    }
  }

  render() {
    const {
      style,
      title,
      image,
      icon,
      isNew,
      children,
      hasBadgeNew,
    } = this.props;
    const { collapse } = this.state;
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });

    return (
      <div className={categoryClass}>
        <button
          type="button"
          className="sidebar__link sidebar__category"
          onClick={this.toggle}
        >
          {icon ? (
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
          ) : (
            ''
          )}
          {image ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '-6px',
                marginLeft: '-6px',
              }}
            >
              <img style={{ width: '24px' }} src={image} alt={title} />
            </div>
          ) : (
            ''
          )}
          <p className="sidebar__link-title" style={style}>
            {title}
            {isNew && <span className="sidebar__category-new" />}
            {hasBadgeNew && <span className="as-badge-new">nouveau</span>}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>{children}</div>
          </ul>
        </Collapse>
      </div>
    );
  }
}
