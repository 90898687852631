import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import Loading from '../../App/Loading';
import {
  Container,
  Row,
  Button,
  Col,
  // Card,
  // CardBody,
} from 'reactstrap';
// import Panel from '../../../shared/components/Panel';
import { BasicNotification } from '../../../shared/components/Notification';
import SettingsIcon from 'mdi-react/SettingsIcon';

/** Features */
// import NotificationsManager from './components/Notifications';
// import Sponsoring from './components/Sponsoring';

/** Stats & tables */
import Visits from './components/Visits';
import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import Statistics from './components/Statistics';
import StatisticsClicks from './components/StatisticsClicks';
import TablePreorders from './components/TablePreorders';
import TableSamples from './components/TableSamples';
import StatisticsDiscoverGeneral from './components/StatisticsDiscoverGeneral';

let notifications = null;
const showNotification = ({ notification }) => {
  notifications.notice({
    content: notification,
    duration: 15,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: `right-up`,
  });
};

class Brandpage extends React.Component {
  state = {
    brandpage: null,
    notifications_emails: [],
    emails: null,
    loaded: false,
    sponsorship_email: null,
    stats: null,
    preorders_loaded: false,
    products_requests_loaded: false,
    preorders: [],
    products_requests: [],
  };

  /** Mount */
  componentDidMount() {
    NotificationSystem.newInstance(
      { style: { top: 65 } },
      (n) => (notifications = n)
    );

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/item/`, {
        userid: this.props.user.id,
        brandpageid: this.props.match.params.id,
      })
      .then((res) => {
        const data = res.data;
        const emails = data.notifications_emails
          ? data.notifications_emails
              .map((item) => {
                return item.email;
              })
              .join('\n')
          : [];

        this.setState({
          loaded: true,
          loading: false,
          brandpage: data.item,
          emails: emails,
          notifications_emails: data.notifications_emails || [],
          stats: data.stats,
        });
      });

    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/preorders/`,
        {
          userid: this.props.user.id,
          token: this.props.user.token,
          brandpageid: this.props.match.params.id,
        }
      )
      .then((res) => {
        const data = res.data;
        this.setState({
          ...this.state,
          preorders_loaded: true,
          preorders: data.preorders,
        });
      });

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/samples/`, {
        userid: this.props.user.id,
        token: this.props.user.token,
        brandpageid: this.props.match.params.id,
      })
      .then((res) => {
        const data = res.data;
        this.setState({
          ...this.state,
          products_requests_loaded: true,
          products_requests: data.products_requests || [],
        });
      });
  }

  /** Preorders validation handler */
  handleValidatePreorder = (preorderId) => {
    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/preorder/validate/`,
        {
          userid: this.props.user.id,
          token: this.props.user.token,
          brandpageid: this.props.match.params.id,
          preorderid: preorderId,
        }
      )
      .then((res) => {
        const data = res.data;
        if (data.preorders.length > 0) {
          this.setState({
            ...this.state,
            preorders: data.preorders,
          });
        }
      });
  };

  /** Samples requests validation handler */
  handleValidateSamplesRequest = (requestId) => {
    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/samplerequest/validate/`,
        {
          userid: this.props.user.id,
          token: this.props.user.token,
          brandpageid: this.props.match.params.id,
          requestid: requestId,
        }
      )
      .then((res) => {
        const data = res.data;
        if (data.products_requests.length > 0) {
          this.setState({
            ...this.state,
            products_requests: data.products_requests,
          });
        }
      });
  };

  /** Sponsors emails handler */
  handleSponsorshipEmail = (e) => {
    this.setState({ sponsorship_email: e.target.value });
  };

  /** Sponsors emails validation */
  sendSponsoringRequest = () => {
    const _self = this;
    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/sponsoring/request/`,
        {
          userid: this.props.user.id,
          brandpageid: parseInt(this.props.match.params.id),
          token: this.props.user.token,
          passphrase: this.props.user.passphrase,
          email: this.state.sponsorship_email,
        }
      )
      .then((res) => {
        if (res.data.error) {
          const message = res.data.error;
          _self.showNotification(message);
        }
        _self.setState({ sponsorship_email: null });
      });
  };

  /** Notifications additional emails validation */
  submitEmails = (e) => {
    e.preventDefault();
    const emails = document.getElementById('emails').value.split('\n');
    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT}/brandpage/notifications/emails/`,
        {
          userid: this.props.user.id,
          brandpageid: this.props.match.params.id,
          emails: emails,
        }
      )
      .then((res) => {
        console.log(res);
      });
  };

  /** Configurator redirect */
  configurator = () => {
    if (typeof window !== 'undefined') {
      window.open(
        `${process.env.REACT_APP_BOTTL_WEBROOT}/producteur/profile/${this.state.brandpage.url_key}/`
      );
    }
  };

  /** Subscription settings redirect */
  subscription = () => {
    if (typeof window !== 'undefined') {
      window.open(
        `${process.env.REACT_APP_BOTTL_WEBROOT}/producteur/profile/${this.state.brandpage.url_key}/`
      );
    }
  };

  /** Page notifications handler */
  showNotification = (message) => {
    return showNotification({
      notification: (
        <BasicNotification title="Informations" message={message} />
      ),
    });
  };

  render() {
    const { loaded, loading, brandpage } = this.state;
    console.log(this.state);
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {loaded && (
          <React.Fragment>
            <Col>
              <Row>
                <Col md={6}>
                  <h3>{`Votre site: ${
                    brandpage.name || '"Page sans nom"'
                  }`}</h3>
                </Col>
                <Col md={6} align="right">
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    onClick={this.configurator}
                  >
                    Accéder au configurateur
                  </Button>
                  <Route
                    render={({ history }) => (
                      <Button
                        color="primary"
                        className="icon"
                        outline
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/brandpages/subscription/${brandpage.id}`
                          )
                        }
                      >
                        <p>
                          <SettingsIcon /> Gérer mon abonnement
                        </p>
                      </Button>
                    )}
                  />
                </Col>
              </Row>
            </Col>

            {/* <NotificationsManager /> */}
            {/* <Sponsoring /> */}

            <Col>
              <Row>
                <Visits stats={this.state.stats.graph} />
                <TotalPageViews stats={this.state.stats.graph} />
                <NewUsers stats={this.state.stats.graph} />
              </Row>
            </Col>

            {/* <StatisticsDiscoverGeneral stats={this.state.stats} /> */}
            {/* <Statistics stats={this.state.stats} /> */}
            {/* <StatisticsClicks stats={this.state.stats} /> */}
            {this.state.products_requests_loaded &&
              this.state.products_requests && (
                <TableSamples
                  data={this.state.products_requests}
                  handleValidateSamplesRequest={
                    this.handleValidateSamplesRequest
                  }
                />
              )}
            {this.state.preorders_loaded && this.state.preorders && (
              <TablePreorders
                data={this.state.preorders}
                handleValidatePreorder={this.handleValidatePreorder}
              />
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.authentication,
});
export default connect(mapStateToProps)(Brandpage);
