import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../redux/actions/userActions';
import { LogInForm } from './components/LogInForm';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.token = null;
    if (process.env.NODE_ENV === 'development') {
      console.log(process.env);
    }
  }

  componentDidMount() {
    if (typeof this.props.location !== 'undefined') {
      if (this.props.location.search !== '') {
        if (this.props.location.search.includes('?_t=')) {
          const dashboardTokenString = this.props.location.search
            .replace('?_t=', '')
            .split('&');
          const dashboardToken = dashboardTokenString[0];
          if (typeof dashboardTokenString[1] !== 'undefined') {
            const param = dashboardTokenString[1];
            localStorage.setItem('redirect', param);
          }

          this.token = dashboardToken;
          this.props.tokenLogin(this.token);
        }
      }
    }
  }

  render() {
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            {!this.token && (
              <React.Fragment>
                <div className="topbar__logo" style={{ marginBottom: '30px' }}>
                  <img
                    src={`img/logo/logo_${process.env.DASHBOARD_ENV}_dark.png`}
                  />
                </div>
                <LogInForm />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication;
  return { loggingIn };
};

const actionCreators = {
  tokenLogin: userActions.tokenLogin,
};

export default connect(mapStateToProps, actionCreators)(LogIn);
