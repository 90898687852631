import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ImportedShopsList from '../Intelligence/Import/list';

class Dashboard extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    importedShops: [],
  };

  constructor(props) {
    super(props);

    this.shopOpen = this.shopOpen.bind(this);
    this.importOpen = this.importOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/vision/dashboard`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((res) => {
            this.setState({
              stats: res.data.content.stats,
              importedShops: res.data.content.shops,
              loaded: true,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  shopOpen(id) {
    const shop = this.state.importedShops.find((s) => s.id == id);

    if (shop.fk_shop)
      window.open(
        `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/${shop.fk_shop}-.html`,
        '_blank'
      );
    else
      window.open(
        `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/IMP-${id}-.html?is_imported=1`,
        '_blank'
      );
  }

  importOpen(id) {
    this.props.history.push('/finder/vision/imports/' + id);
  }

  render() {
    let { loaded, loading, subscription, importedShops, stats } = this.state;

    return (
      <Container className="as-finder-vision dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            {/* 1ST ROW */}
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <h3 className="page-title">Finder Vision</h3>
                  </Col>
                  {importedShops.length > 0 && (
                    <Col md={6} align="right">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        onClick={() => {
                          this.props.history.push('/finder/vision/import');
                        }}
                      >
                        Faire un nouvel import
                      </Button>
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        onClick={() => {
                          this.props.history.push('/finder/vision/imports');
                        }}
                      >
                        Voir mes imports passés
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {importedShops.length === 0 && (
              <Row>
                <Col md={12}>
                  <div className="subscription-placeholder">
                    <div className="placeholder-wrapper">
                      <p className="placeholder">
                        Vous n'avez effectué aucun import pour l'instant
                      </p>
                      <br />
                      <button
                        onClick={() => {
                          this.props.history.push('/finder/vision/import');
                        }}
                        className="btn btn-primary"
                      >
                        Nouvel import
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {importedShops.length > 0 && (
              <>
                <Row>
                  {/* METRIC 1 */}
                  <Col md={12} xl={4} lg={6} xs={12}>
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Nombre de prospects</h5>
                          </div>
                          <div className="dashboard__total">
                            <p className="dashboard__total-stat">
                              {stats.counts['Prospect']} cave
                              {stats.counts['Prospect'] > 1 ? 's' : ''}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* METRIC 2 */}
                  <Col md={12} xl={4} lg={6} xs={12}>
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Nombre de clients</h5>
                          </div>
                          <div className="dashboard__total">
                            <p className="dashboard__total-stat">
                              {stats.counts['Client']} cave
                              {stats.counts['Client'] > 1 ? 's' : ''}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* METRIC 3 */}
                  <Col md={12} xl={4} lg={6} xs={12}>
                    <Card>
                      <CardBody className="dashboard__card-widget">
                        <div>
                          <div className="card__title">
                            <h5 className="bold-text">Taux de couverture</h5>
                          </div>
                          <div className="dashboard__total">
                            <p className="dashboard__total-stat">
                              {Math.round(stats.cover_rate * 100) / 100}% (
                              {stats.total_known}/{stats.total_to_know})
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md={12}>
                    <div className="mt-0 as-import-shops-overview">
                      <p>Dernières caves importées</p>
                      <ImportedShopsList
                        shops={importedShops.slice(0, 8)}
                        shownControls={['view', 'view_import']}
                        onShopOpen={this.shopOpen}
                        onImportOpen={this.importOpen}
                        fields={[
                          {
                            name: 'siret',
                            label: 'Numéro SIRET',
                            width: '15%',
                          },
                          {
                            name: 'bottlCommercialStatus',
                            label: 'Statut',
                            width: '15%',
                          },
                        ]}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Dashboard);
