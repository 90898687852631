import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import { Container, Row, Col } from 'reactstrap';
import Loading from '../App/Loading';
import { Questions } from './components/QuestionResponseData';
import { Link } from 'react-router-dom';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

class InsightParticipation extends React.Component {

  state = {
    loaded: false,
    loading: true,
    data: [],
    answers: [],
    campaign: {}
  }

  componentDidMount() {
    
    const { authentication: { user } } = this.props;
    const id = this.props.match.params.id;
    const campaignid = this.props.match.params.campaignid;
    const endpoint = `insight/campaign/${campaignid}/participations/${id}`;

    axios.post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
      id: user.id,
      token: user.token,
      passphrase: user.passphrase
    }).then(res => {
      console.log(res.data.content)
      this.setState({
        loaded: true,
        loading: false,
        campaign: res.data.content.campaign,
        data: res.data.content.participation,
        answers: res.data.content.answers,
      })
    });
  }

  render() {
    
    const { loaded, loading, campaign, answers, data } = this.state;
    const { match } = this.props;
    
    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {
          loaded && (
            <React.Fragment>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">Campagne <strong>{campaign.label}</strong></h3>
                  <h4 className="page-subhead subhead" style={{marginBottom:'.5rem'}}>
                    Réponses du caviste <strong>"{data.cave_name}"</strong>
                  </h4>
                  <h3 className="page-subhead subhead">
                    <Link to={`/insight/campaign/${match.params.campaignid}`}>
                      Retour à la campagne <strong>{campaign.label}</strong> <ChevronRightIcon />
                    </Link>
                  </h3>
                </Col>
              </Row>
              {
                answers.length > 0 && answers.map((item, index) => {
                  return (
                    <React.Fragment key={`category-${index}`}>
                      <Row style={{ marginBottom: '1rem' }}>
                        <Col md={12}>
                          <h4>{item.category}</h4>
                        </Col>
                      </Row>
                      <Questions questions={item.questions} />
                    </React.Fragment>
                  )
                })
              }
            </React.Fragment>
          )
        }
      </Container>
    )
  }
}


const mapStateToProps = (state) => ({
  ...state
});
export default connect(mapStateToProps)(InsightParticipation);