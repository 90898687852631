import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MappingInput from './mapping.input';

class Mapping extends React.Component {
  render() {
    let { _import, current } = this.props;

    current = current || {};
    window._unavailableFields = Object.values(current);

    let headers =
      _import.has_header == true
        ? _import.header
        : _import.sample_lines[0].map((cell, idx) => `CHAMP ${idx + 1}`);
    const originalHeaders = [...headers];

    const samples = _import.sample_lines.slice(0, 3);

    if (this.props.forVision && this.props.visionType !== 'all') {
      if (Object.values(current).includes('siret')) {
        const relevantHeader = Object.entries(current).find(
          (e) => e[1] === 'siret'
        )[0];

        if (_import.has_header == true)
          headers = headers.filter((h) => h === relevantHeader);
        else
          headers = headers.filter(
            (h, idx) => `__col${idx + 1}__` === relevantHeader
          );
      }
    }

    return (
      <div className="as-import-mapping">
        {!this.props.forVision && <p>Données de mappage</p>}
        {this.props.forVision && (
          <>
            <p>Vérifiez que la colonne de SIRET est bien identifiée</p>
            <p>
              <small>
                Assurez-vous que la colonne des SIRET de votre base contienne
                bien le mot "SIRET"
              </small>
            </p>
          </>
        )}

        <Row className="as-mapping-header">
          <Col md={3}>
            <span className="cell">ENTÊTE</span>
          </Col>
          <Col md={5}>
            <span className="cell">DONNÉES</span>
          </Col>
          <Col md={4}>
            <span className="cell">ATTRIBUT DE CAVE</span>
          </Col>
        </Row>

        <div className="as-mapping-rows">
          {headers.map((cell, ida) => (
            <Row key={`${cell}_${ida}`} className="as-mapping-row">
              <Col md={3}>
                <span key={`${cell}_${ida}`} className="as-cell simple">
                  {cell}
                </span>
              </Col>
              <Col md={5}>
                <div key={`${cell}_${ida}`} className="as-cell multi">
                  {samples.map((sample, idb) => (
                    <span key={`${ida}_${idb}`} className="sub-cell">
                      {sample[
                        originalHeaders.indexOf(cell, ida > 1 ? ida - 1 : ida)
                      ].length > 30
                        ? `${sample[
                            originalHeaders.indexOf(
                              cell,
                              ida > 1 ? ida - 1 : ida
                            )
                          ].substr(0, 30)}...`
                        : sample[
                            originalHeaders.indexOf(
                              cell,
                              ida > 1 ? ida - 1 : ida
                            )
                          ]}
                    </span>
                  ))}
                </div>
              </Col>
              <Col md={4}>
                <div key={`${cell}_${ida}`} className="as-cell input">
                  <MappingInput
                    key={`${cell}_${ida}`}
                    csvColumn={
                      _import.has_header == true
                        ? cell
                        : `__col${originalHeaders.indexOf(cell) + 1}__`
                    }
                    current={
                      _import.has_header == true
                        ? current[cell]
                        : current[`__col${originalHeaders.indexOf(cell) + 1}__`]
                    }
                    onChange={this.props.onChange}
                    // unavailableFields={Object.values(current)}
                    forVision={this.props.forVision}
                    visionType={this.props.visionType}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    );
  }
}

export default Mapping;
