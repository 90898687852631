import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class MatTable extends PureComponent {
  state = {
    order: 'asc',
    orderBy: 'id',
    selected: new Map([]),
    data: [],
    page: 0,
    rowsPerPage: 10,
  };

  componentDidMount() {
    this.setState({
      data: [...this.props.data],
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    this.props.onShopOpen(id);
    // this.props.history.push(`/brandpage/${id}`);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  render() {
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <>
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="page-title">{this.props.title}</h3>
            </div>
            <div className="material-table__wrap">
              <Paper>
                <TableContainer>
                  <Table className="material-table">
                    <MatTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                    />
                    <TableBody>
                      {data
                        .sort(getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((d) => {
                          return (
                            <TableRow
                              className="as-row material-table__row"
                              onClick={(event) => this.handleClick(event, d.id)}
                              tabIndex={-1}
                              key={d.id}
                            >
                              <TableCell className="material-table__cell material-table__cell-right">
                                <img
                                  style={{
                                    width: '42px',
                                    height: '42px',
                                    marginRight: '12px',
                                    borderRadius: '50px',
                                    border: '1px solid #01efba',
                                  }}
                                  src={
                                    d.image_src
                                      ? d.image_src
                                      : d.imported_image_url
                                      ? d.imported_image_url
                                      : `${process.env.REACT_APP_BOTTL_WEBROOT}/photos/inconnu/cave_generique.png`
                                  }
                                  alt={d.name}
                                />
                                <span>{d.name || 'Non renseigné'}</span>
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.locality || 'Non renseigné'}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.postal_code || 'Non renseigné'}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.gerant_firstname && d.gerant_lastname
                                  ? `${d.gerant_firstname} ${d.gerant_lastname}`
                                  : d.gerant_firstname
                                  ? d.gerant_firstname
                                  : 'Non renseigné'}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right as-ta-right">
                                <button
                                  type="button"
                                  className="mb-0 btn btn-primary"
                                  onClick={(e) => {
                                    this.props.onShowUpdateForm(d.id);
                                    e.stopPropagation();
                                  }}
                                >
                                  Modifier
                                </button>
                                <button
                                  type="button"
                                  className="mb-0 btn btn-danger"
                                  onClick={(e) => {
                                    this.props.onShopDelete(d.id);
                                    e.stopPropagation();
                                  }}
                                >
                                  Supprimer
                                </button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Page précédente' }}
              nextIconButtonProps={{ 'aria-label': 'Page suivante' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 100]}
              dir="ltr"
              labelRowsPerPage={''}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} sur ${count}`
              }
              SelectProps={{
                inputProps: { 'aria-label': 'Lignes par page' },
                native: false,
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}
