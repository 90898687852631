import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';

class Team extends React.Component {
  state = {
    loaded: false,
    loading: false,
    hasTeam: false,
    members: [],
    currentMember: null,
    subscription: null,
    isCreating: false,
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.formCreate = React.createRef();
    this.formUpdate = React.createRef();
    this.fieldEmail = React.createRef();
    this.handleFormCreateOpening = this.handleFormCreateOpening.bind(this);
    this.handleFormCreateClosing = this.handleFormCreateClosing.bind(this);
    this.handleSubmitInvite = this.handleSubmitInvite.bind(this);
    this.handleMemberUpdate = this.handleMemberUpdate.bind(this);
    this.handleMemberDeletion = this.handleMemberDeletion.bind(this);
    this.handleSubscriptionConfirm = this.handleSubscriptionConfirm.bind(this);
    this.transferOwnership = this.transferOwnership.bind(this);
  }

  _formToJSON(f) {
    const inputs = f.querySelectorAll('input, select, textarea');
    let payload = {};

    for (let i = 0; i < inputs.length; i++) {
      const k = inputs[i].getAttribute('name');
      const v = inputs[i].value;
      payload[k] = v;
    }

    return payload;
  }

  handleFormCreateOpening() {
    this.setState({ isCreating: true });
  }
  handleFormCreateClosing() {
    this.setState({ isCreating: false }, () => {
      if (this.state.currentMember)
        this.fieldEmail.current.value = this.state.currentMember.email;
    });
  }

  handleSubmitInvite(evt) {
    evt.preventDefault();

    let result = this.formCreate.current.reportValidity();

    if (!result) return false;

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/team/members/invite/simulate`;

    const newMember = this._formToJSON(this.formCreate.current);

    // @TODO Async proration
    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        ...newMember,
      })
      .then((res) => {
        const {
          amountTotal,
          amountPerDay,
          amountPlan,
          remainingDays,
        } = res.data.content;

        Swal.fire({
          title: 'Information',
          html: `L'invitation de ce nouveau membre va déclencher le paiement de son abonnement.
             <br /><br />
             Vous allez être facturé de ${amountTotal}€,<br />
             soit ${amountPerDay}€ par jour (Plan à ${amountPlan}€ par mois) x ${remainingDays},<br />
             qui est le nombre de jours restant de votre abonnement.`,
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: 'Confirmer',
          cancelButtonText: `Annuler`,
        }).then((result) => {
          this.setState({ loading: false, loaded: true });

          if (result.isDenied) return;
          if (!result.isConfirmed) return;

          this.setState({ loading: true, loaded: false });

          endpoint = `finder/team/members/invite`;
          axios
            .post(
              `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`,
              {
                id: user.id,
                token: user.token,
                passphrase: user.passphrase,
                ...newMember,
              }
            )
            .then((res) => {
              this.props.history.push('/finder/team/' + res.data.content.id);

              this.setState(
                {
                  loaded: true,
                  loading: false,
                  currentMember: {
                    ...newMember,
                    id: res.data.content.id,
                    quota_inmails: res.data.content.quota_inmails,
                  },
                  members: [
                    ...this.state.members,
                    {
                      ...newMember,
                      id: res.data.content.id,
                      quota_inmails: res.data.content.quota_inmails,
                    },
                  ],
                  isCreating: false,
                },
                () => {
                  this._showNotification(
                    "Un email d'invitation a été envoyé à votre nouveau membre"
                  );
                  this.fieldEmail.current.value = newMember.email;
                }
              );
            });
        });
      });
  }

  handleMemberUpdate(e) {
    e.preventDefault();

    let result = this.formUpdate.current.reportValidity();

    if (!result) return false;

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/team/members/${this.state.currentMember.id}/update`;

    const newEmail = this.fieldEmail.current.value;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        email: newEmail,
      })
      .then((res) => {
        this.setState(
          {
            loaded: true,
            loading: false,
            currentMember: {
              ...this.state.currentMember,
              email: newEmail,
            },
            members: this.state.members.map((m) =>
              m.id != this.state.currentMember.id
                ? m
                : {
                    ...this.state.currentMember,
                    email: newEmail,
                  }
            ),
          },
          () => {
            this._showNotification('Ce membre a bien été mis à jour');
            if (res.data.content.was_invited)
              this._showNotification(
                "Un nouvel email d'invitation a été envoyé à ce membre"
              );
            this.fieldEmail.current.value = newEmail;
          }
        );
      });
  }

  handleMemberDeletion() {
    Swal.fire({
      title: 'Attention',
      text: `La suppression d'un membre est irréversible, et coupera immédiatement l'accès Finder de celui-ci. La baisse du montant mensuel de votre abonnement aura lieu lors du renouvellement de votre cycle de facturation.`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/team/members/${this.state.currentMember.id}/delete`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        })
        .then((res) => {
          this.setState(
            {
              loaded: true,
              loading: false,
              members: this.state.members.filter(
                (m) => m.id != this.state.currentMember.id
              ),
              currentMember: null,
            },
            () => {
              this._showNotification(
                'Le membre a bien été supprimé de votre équipe, et votre abonnement mis à jour'
              );
            }
          );
        });
    });
  }

  handleSubscriptionConfirm() {
    Swal.fire({
      title: 'Information',
      text: `Vous vous apprêtez à confirmer votre abonnement. Cela mettra fin à votre période d'essai afin de débloquer toutes les fonctionnalités de votre Plan.`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/teams/early-confirm-subscription`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        })
        .then((res) => {
          this.setState(
            {
              loaded: true,
              loading: false,
              members: this.state.members.filter(
                (m) => m.id != this.state.currentMember.id
              ),
              currentMember: null,
            },
            () => {
              this._showNotification(
                'Votre abonnement a bien été confirmé, et nous vous en remercions.'
              );
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          );
        });
    });
  }

  transferOwnership() {
    Swal.fire({
      title: 'Attention',
      text: `En transférant la propriété à ce membre, celui-ci deviendra l'ayant-droit sur votre équipe, et vous perdrez ce statut. Il pourra inviter d'autres membres, modifier l'abonnement, et gérer la facturation.`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/team/members/${this.state.currentMember.id}/make-owner`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        })
        .then((res) => {
          this.setState(
            {
              loaded: true,
              loading: false,
              currentMember: null,
            },
            () => {
              this._showNotification(
                "La propriété de l'équipe a bien été transférée à ce membre."
              );
              setTimeout(() => {
                window.location = '/finder/subscription';
              }, 3000);
            }
          );
        });
    });
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/team/members/list`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState(
          {
            loaded: true,
            loading: false,
            hasTeam: true,
            members: res.data.content.members,
            currentMember: this.props.match.params.id
              ? res.data.content.members.find(
                  (m) => m.id == this.props.match.params.id
                )
              : null,
          },
          () => {
            if (this.state.currentMember)
              this.fieldEmail.current.value = this.state.currentMember.email;
            axios
              .post(
                `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/subscription/get`,
                {
                  id: user.id,
                  token: user.token,
                  passphrase: user.passphrase,
                }
              )
              .then((res) => {
                this.setState({
                  subscription: res.data.content.subscription,
                });
              });
          }
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
          hasTeam: false,
          members: [],
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.match.params.id
    ) {
      const {
        authentication: { user },
      } = this.props;

      if (this.state.members.some((m) => m.id == this.props.match.params.id))
        this.setState(
          {
            currentMember: this.state.members.find(
              (m) => m.id == this.props.match.params.id
            ),
          },
          () => {
            this.fieldEmail.current.value = this.state.currentMember.email;
          }
        );
    }
  }

  render() {
    let {
      isCreating,
      loaded,
      loading,
      members,
      currentMember,
      hasTeam,
      subscription,
    } = this.state;
    const memberId = this.props.match ? this.props.match.params.id : null;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {loaded && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Mon équipe</h3>
              </Col>
            </Row>
            <Row>
              <div className="as-messenger">
                <div
                  className={`as-left ${members.length == 0 ? 'as-empty' : ''}`}
                >
                  <div className="as-members-wrapper">
                    {members.map((m, idx) => (
                      <Link
                        key={idx}
                        className={`as-member ${
                          memberId == m.id ? 'as-active' : ''
                        }`}
                        to={`/finder/team/${m.id}`}
                      >
                        <div className="as-member-header">
                          <img
                            src={`${
                              m.img
                                ? m.img
                                : `${process.env.REACT_APP_WEBROOT}/images/man.svg`
                            }`}
                            alt={`${m.firstname} ${m.lastname}`}
                          />
                          <span className="sender">
                            <strong>
                              {m.firstname} {m.lastname}
                            </strong>
                          </span>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="as-footer-widget">
                    <button
                      onClick={this.handleFormCreateOpening}
                      type="button"
                      className="btn btn-primary"
                    >
                      Inviter un nouveau membre
                    </button>
                  </div>
                </div>
                <div className="as-right">
                  {/* EMPTY FULL STATE */}
                  {!currentMember && members.length > 0 && !isCreating && (
                    <p className="placeholder">
                      Veuillez sélectionner un membre dans le volet gauche
                    </p>
                  )}
                  {/* EMPTY VOID STATE */}
                  {!currentMember &&
                    !members.length > 0 &&
                    !isCreating &&
                    hasTeam &&
                    subscription &&
                    // !moment(
                    //   subscription.team.stripe_trial_ends_at
                    // ).isAfter() && (
                    true && (
                      <div className="placeholder-wrapper">
                        <p
                          className="placeholder"
                          style={{ maxWidth: '720px', textAlign: 'center' }}
                        >
                          Invitez autant de membres que vous voulez, vous
                          accédez ainsi à leur consommation de fiches et
                          d'inmails et vous n'êtes débité que sur la carte de
                          crédit que vous nous avez communiqué pour votre
                          compte.
                          <br />
                          <br />
                          Mais attention, aujourd'hui, chaque membre déclenche
                          la facturation d'un nouvel abonnement - le même que le
                          vôtre - et le ou les membres de votre équipe
                          consommeront les crédits de votre compte.
                          <br />
                          <br />
                        </p>
                        <button
                          onClick={this.handleFormCreateOpening}
                          type="button"
                          className="btn btn-primary"
                        >
                          Inviter un nouveau membre
                        </button>
                      </div>
                    )}
                  {/* EMPTY VOID UNSUBSCRIBED STATE */}
                  {!hasTeam && (
                    <div className="placeholder-wrapper">
                      <p className="placeholder">
                        Vous n'avez aucun abonnement à Finder pour l'instant.
                      </p>
                      <br />
                      <a
                        href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                        className="btn btn-primary"
                      >
                        Je m'abonne
                      </a>
                    </div>
                  )}
                  {/* SINGLE PICKED / UPDATE FORM STATE */}
                  {currentMember && members.length > 0 && !isCreating && (
                    <form
                      className="as-member-panel"
                      onSubmit={this.handleMemberUpdate}
                      ref={this.formUpdate}
                    >
                      <div className="as-member-body">
                        <h3>
                          {currentMember.firstname} {currentMember.lastname}
                        </h3>
                        <div className="as-form">
                          <div>
                            <label htmlFor="firstname">Prénom</label>
                            <input
                              id="firstname"
                              className="as-field"
                              type="text"
                              value={currentMember.firstname}
                              disabled
                            />
                          </div>
                          <div>
                            <label htmlFor="lastname">Nom</label>
                            <input
                              id="lastname"
                              className="as-field"
                              type="text"
                              value={currentMember.lastname}
                              disabled
                            />
                          </div>
                          <div>
                            <label htmlFor="email">Adresse email</label>
                            <input
                              id="email"
                              className="as-field"
                              type="email"
                              name="email"
                              placeholder="Renseignez une adresse email"
                              ref={this.fieldEmail}
                              required
                              disabled={
                                currentMember.accepted_at ? true : false
                              }
                            />
                          </div>
                          <hr />
                          <div>
                            <label htmlFor="quota_inmails">Plan</label>
                            <input
                              id="plan"
                              className="as-field"
                              type="text"
                              value={currentMember.plan}
                              disabled
                            />
                          </div>
                          <div>
                            <label htmlFor="quota_inmails">
                              Montant mensuel
                            </label>
                            <input
                              id="amount"
                              className="as-field"
                              type="text"
                              value={`${currentMember.amount}€ par mois`}
                              disabled
                            />
                          </div>
                          <div>
                            <label htmlFor="quota_inmails">
                              Nombre d'inmails disponibles ce mois-ci
                            </label>
                            <input
                              id="quota_inmails"
                              className="as-field"
                              type="text"
                              value={currentMember.quota_inmails}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="as-member-controls">
                        <button
                          onClick={this.handleMemberDeletion}
                          className="btn btn-danger"
                          type="button"
                        >
                          Supprimer
                        </button>
                        {!currentMember.accepted_at && (
                          <button className="btn btn-primary" type="submit">
                            Enregistrer
                          </button>
                        )}
                        {currentMember.accepted_at && (
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.transferOwnership}
                          >
                            Transférer la propriété
                          </button>
                        )}
                      </div>
                    </form>
                  )}
                  {/* CREATE FORM STATE */}
                  {isCreating && (
                    <form
                      ref={this.formCreate}
                      onSubmit={this.handleSubmitInvite}
                      className="as-form"
                    >
                      <button
                        onClick={this.handleFormCreateClosing}
                        className="closer"
                        type="button"
                      >
                        &times;
                      </button>
                      <h2>Inviter un nouveau membre</h2>
                      <div>
                        <label htmlFor="firstname">Prénom</label>
                        <input
                          id="firstname"
                          className="as-field"
                          type="text"
                          name="firstname"
                          placeholder="Renseignez un prénom"
                          minLength="1"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="lastname">Nom</label>
                        <input
                          id="lastname"
                          className="as-field"
                          type="text"
                          name="lastname"
                          placeholder="Renseignez un nom"
                          minLength="1"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="email">Adresse email</label>
                        <input
                          id="email"
                          className="as-field"
                          type="email"
                          name="email"
                          placeholder="Renseignez une adresse email"
                          minLength="4"
                          required
                        />
                      </div>
                      <button
                        onClick={this.handleSubmitInvite}
                        type="button"
                        className="btn btn-primary"
                        type="submit"
                      >
                        Inviter ce membre
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Team);
