import React from 'react'
import { Row, Col } from 'reactstrap';
import Panel from '../../../shared/components/Panel';
import NumericFormat from 'react-number-format';
import PieCharts from './Campaign/PieChart'
import PieChartsSector from './Campaign/PieChartSector'
import Bars from './Campaign/Bars'
import HBars from './Campaign/HBars'
import InsightResponseTable from './Participation/Table'
import { Table } from 'reactstrap';

export const Questions = (props) => {
  return (
    <Row>
      {
        props.questions.length > 0 && props.questions.map((question) => {
          return (
            <React.Fragment key={`question-${question.id}`}>
              {
                <React.Fragment>
                  {
                    (question.type_label !== 'SELECT_QUESTION_TYPE'
                      && question.type_label !== 'SELECT_MULTIPLE_QUESTION_TYPE'
                      && (question.type_label !== 'TABLE_QUESTION_TYPE')
                      && question.type_label !== 'YES_OR_NO_WITH_TEXT_QUESTION_TYPE'
                      && question.type_label !== 'YES_OR_NO_QUESTION_TYPE'
                      && question.type_label !== 'NUMBER_QUESTION_TYPE'
                      && question.type_label !== 'COMMENT') ? (
                      <React.Fragment>
                        {
                          (question.type_label === 'FREE_TEXT_QUESTION_TYPE' && !question.average) ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            <React.Fragment>
                              {question.checksum !== 'c4521ad99461f3c968f4033c59872063' ? (
                                <Col md={question.cols} lg={question.cols} xl={question.cols} sm={12} xs={12} >
                                  <Row>
                                    <Panel md={12} lg={12} xl={12} sm={12} xs={12} title={question.entitled}>
                                      <Responses
                                        data={question.data}
                                        columns={question.columns}
                                        type={question.type_label}
                                        question={question}
                                      />
                                    </Panel>
                                  </Row>
                                </Col>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </React.Fragment>
                          )
                        }
                      </React.Fragment>
                    ) : (
                      <Responses
                        data={question.data}
                        columns={question.columns}
                        type={question.type_label}
                        question={question}
                      />
                    )
                  }
                </React.Fragment>
              }
            </React.Fragment>
          )
        })
      }
    </Row>
  )
}

export const Responses = (props) => {
  const { question, data, columns, type } = props;

  switch (type) {
    case 'SELECT_QUESTION_TYPE':
      if (question.checksum === 'fe913bc32ace7f95770a0458bed01855') {
        return (
          <PieCharts col={question.cols} label={'Fréquence des dégustations'} data={question.data.map((item) => {
            return {
              name: item.label === 'Autre (à préciser ci-dessous)' ? 'Autre' : item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.count,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      } else if (question.checksum === '1e0c199c2fe1f84473b9ff3aedc2c27e') {
        return (
          <PieCharts col={question.cols} label={question.entitled} data={question.data.map((item) => {
            return {
              name: item.label === 'Autre (à préciser ci-dessous)' ? 'Autre' : item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.count,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      } else {
        return (
          <PieCharts col={question.cols} label={question.entitled} data={question.data.map((item) => {
            return {
              name: item.label === 'Autre (à préciser ci-dessous)' ? 'Autre' : item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.count,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      }

    case 'SELECT_MULTIPLE_QUESTION_TYPE':
      return (
        <HBars
          label={question.entitled}
          xKey={'x'}
          yKey={'y'}
          type={'percentage'}
          cols={question.cols}
          data={question.data.filter((item) => {
            return item.label !== 'Autre (à préciser ci-dessous)'
          }).map((item) => {
            return {
              y: item.label.toString(),
              x: parseFloat(item.percentage.toFixed(2)),
            }
          })}
        />
      )

    case 'FREE_TEXT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                <strong><NumericFormat
                  displayType="text"
                  value={question.average}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=" "
                  decimalScale={0}
                  decimalSeparator=","
                /></strong>
              </p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>En moyenne</h5>
            </div>
          </div>
        </div>
      )

    case 'YES_OR_NO_WITH_TEXT_QUESTION_TYPE':
      return (
        <React.Fragment>
          <Bars col={question.cols} label={question.entitled} data={question.data} />
          <HBars
            label={question.entitled_detail}
            type={'unit'}
            cols={12}
            data={Object.keys(question.data.detailedResponses).filter((item) => {
              return item.label !== 'Autre (à préciser ci-dessous)'
            }).map((key) => {
              const item = question.data.detailedResponses[key];
              return {
                y: item.label.toString(),
                x: item.count,
              }
            })}
          />
        </React.Fragment>
      )

    case 'YES_OR_NO_QUESTION_TYPE':
      if (question.checksum === 'e3354927be0e7b4c01a122ea437c4b33') {
        return <Bars col={question.cols} label={question.entitled} data={question.data} />
      } else if (question.checksum === 'f90f1e3cb0700ec3323d01fd31c2b918') {
        return <Bars col={question.cols} label={question.entitled} data={question.data} />
      } else {
        return <Bars col={question.cols} label={question.entitled} data={question.data} />
      }

    case 'PERCENTAGE_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                <strong><NumericFormat
                  displayType="text"
                  value={parseFloat(question.average.toFixed(2))}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=" "
                  decimalScale={2}
                  decimalSeparator=","
                  suffix={'%'}
                /></strong>
              </p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>En moyenne</h5>
            </div>
          </div>
        </div>
      )

    case 'NUMBER_QUESTION_TYPE':
      return (
        <NumberQuestionType question={question} label={question.entitled} col={question.cols} />
      )

    case 'AMOUNT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          <div className="mobile-app-widget">
            <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
              <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                <strong><NumericFormat
                  displayType="text"
                  value={question.average}
                  thousandsGroupStyle="thousand"
                  thousandSeparator=" "
                  decimalScale={0}
                  decimalSeparator=","
                  suffix={' €'}
                /></strong>
              </p>
            </div>
            <div className="mobile-app-widget__title">
              <h5>En moyenne</h5>
            </div>
          </div>
        </div>
      )

    case 'TABLE_QUESTION_TYPE':
      if (question.checksum === '9991fde369e7835568b0677230735464') {
        return (
          <PieCharts col={question.cols} label={question.entitled} data={question.data.map((item) => {
            return {
              name: item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.sum,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      } else if (
        question.checksum === '7b5d278ce6746ea47737b5653bf2f14e' ||
        question.checksum === '5d20cfed6438d91a225f466679c269ed'
      ) {
        return (
          <PieCharts col={question.cols} label={question.entitled} data={question.data.map((item) => {
            return {
              name: item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.sum,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      } else if (
        question.checksum === '2f2494c311cbdc34f61efe7a188d76d7' ||
        question.checksum === '9e3862861dc06e8574b99406086f885b' ||
        question.checksum === '963ccb828d32aef66268114988e95624'
      ) {
        const data = [
          { label: 'Whisky écossais', percentage: 57, sum: 75 },
          { label: 'Whisky irlandais', percentage: 7.5, sum: 10 },
          { label: 'Whisky américains', percentage: 6, sum: 8 },
          { label: 'Whisky japonais', percentage: 11.4, sum: 15 },
          { label: 'Whisky français', percentage: 10.6, sum: 14 },
          { label: "Whisky d'autres origines", percentage: 7.5, sum: 10 },
        ];
        // return (
        //   <PieCharts col={question.cols} label={question.entitled} data={data.map((item) => {
        //     return {
        //       name: item.label,
        //       value: parseFloat(item.percentage.toFixed(2)),
        //       total: item.sum,
        //       percentage: parseFloat(item.percentage.toFixed(2)),
        //     }
        //   })} />
        // )
        return (
          <Panel
            lg={question.cols ? question.cols : 8}
            xl={question.cols ? question.cols : 8}
            md={question.cols ? question.cols : 8}
            xs={12}
            title={question.entitled}
          >
            <Table responsive striped className="dashboard__table-orders notStripped">
              <thead>
                <tr>
                  <th></th>
                  {
                    question.columns.map((item, index) => {
                      return (
                        <th key={`column-${index}`}>
                          {item.label}
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>

              <tbody>
                {
                  question.data.map((item, index) => {
                    return (
                      <tr key={`row-${index}`}>
                        <td><strong>{item.label}</strong></td>
                        <td>{item.percentage.toFixed(2)}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Panel>
        )
      } else if (
        question.checksum === '7f8091026c8a4ca0a414548a95066856' ||
        question.checksum === 'a3abcc781ded559734abcc8a9bed282f' ||
        question.checksum === 'adb47dc60fca7723f3dcde9fff0be1b2' ||
        question.checksum === '848bf4f68edf3e2b32c17d11bb91e066' ||
        question.checksum === '2c66e0be5de3a3a7aca367f788501ccf' ||
        question.checksum === 'd83b8a92207ff745bc90325ed1fed78d'
      ) {
        return (
          <PieCharts col={question.cols} label={question.entitled} data={question.data.map((item) => {
            return {
              name: item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              total: item.sum,
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })} />
        )
      } else if (question.checksum === '3793d9b3398fc85a4ece0e3f0ee245fb') {
        return (
          <Panel
            lg={question.cols ? question.cols : 8}
            xl={question.cols ? question.cols : 8}
            md={question.cols ? question.cols : 8}
            xs={12}
            title={question.entitled}
          >
            <Table responsive striped className="dashboard__table-orders notStripped">
              <thead>
                <tr>
                  <th></th>
                  {
                    question.columns.map((item, index) => {
                      return (
                        <th key={`column-${index}`}>
                          {item.label}
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>

              <tbody>
                {
                  question.data.map((item, index) => {
                    return (
                      <tr key={`row-${index}`}>
                        <td><strong>{item.label}</strong></td>
                        <td>{item.columns[0].value.toFixed(2)}</td>
                        <td>{item.columns[1].value.toFixed(2)}%</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Panel>
        )
      } else if (question.checksum === '93c73c113e6dd4729bae5cb9ce7c6001') {
        return (
          <InsightResponseTable
            col={question.cols}
            label={question.entitled}
            columns={columns}
            rows={data}
          />
        )
      } else if (question.checksum === '558933da18a91ceaa5c5942639b4a525') {
        return <PieChartsSector
          label={question.entitled}
          col={question.cols}
          data={question.data.filter((item) => {
            return item.label !== 'Autre (à préciser ci-dessous)'
          }).map((item) => {
            return {
              name: item.label,
              value: parseFloat(item.percentage.toFixed(2)),
              percentage: parseFloat(item.percentage.toFixed(2)),
            }
          })}
        />
      } else if (
        question.checksum === '27bbe8af7536071d135a17c02f8e5549' ||
        question.checksum === 'fd6d31a73e9b7666a247fc521dd147b3' ||
        question.checksum === '3e9031f1a7b5d965f68ed9285dee2f4e' ||
        question.checksum === '5bfcc5f1976853404f0f8468d0f12201'
      ) {
        console.log(question.data)
        return (
          <React.Fragment>
            {
              (typeof question.data !== 'undefined' && question.data) && (
                <React.Fragment>
                  {
                    Object.keys(question.data).length > 0 && (
                      Object.keys(question.data).map((item, index) => {
                        return (
                          <Panel lg={question.cols} xl={question.cols} md={question.cols} xs={12} title={`${item}`} key={`best_seller_${index}_${item}`}>
                            <Table responsive striped className="dashboard__table-orders notStripped">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Occurences</th>
                                  <th>%</th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  Object.keys(question.data[item]).map((i, id) => {
                                    return (
                                      <tr>
                                        <td>{question.data[item][i].value}</td>
                                        <td>{parseFloat(question.data[item][i].occurencies).toFixed(2)}</td>
                                        <td>{parseFloat(question.data[item][i].percentage).toFixed(2)}%</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </Table>
                          </Panel>
                        )
                      })
                    )
                  }
                </React.Fragment>
              )
            }
          </React.Fragment>

        )
      } else if (
        question.checksum === 'a57460931859ea68580a1dc91905cbe0' ||
        question.checksum === '11eb7e8c675d05e7d75f8831bf20bb6b' ||
        question.checksum === '73e0a328d3d16b6f36a0f5e2554c74fd' ||
        question.checksum === '618001b48cc536db58862b76fec32292' ||
        question.checksum === '37f3576374056406771fe490ceb992d6' ||
        question.checksum === 'f76b971f098072fcd4d78eeb34999c7a'
      ) {
        return (
          <React.Fragment>
            {
              (typeof question.data !== 'undefined' && question.data.length > 0) && (
                <Panel
                  lg={question.cols}
                  xl={question.cols}
                  md={question.cols}
                  xs={12}
                  title={`${question.entitled}`}
                >
                  <Table responsive striped className="dashboard__table-orders notStripped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Occurences</th>
                        <th>%</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        question.data.map((item, index) => {
                          return (
                            <tr>
                              <td>{item.value}</td>
                              <td>{parseFloat(item.occurencies).toFixed(2)}</td>
                              <td>{parseFloat(item.percentage).toFixed(2)}%</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Panel>
              )
            }
          </React.Fragment>
        )

      } else if (question.checksum === 'be816b40705464d5b81e2b685f16f451') {
        return (
          <React.Fragment>
            {
              (question.data !== null && question.data.length > 0) && (
                <Panel lg={question.cols} xl={question.cols} md={question.cols} xs={12} title="Selon vous, quelles sont les appellations tendances du moment auprès de votre clientèle ?">
                  <Table responsive striped className="dashboard__table-orders notStripped">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Occurences</th>
                        <th>%</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        question.data.map((item) => {
                          return (
                            <tr>
                              <td>{item.value}</td>
                              <td>{item.occurencies}</td>
                              <td>{parseFloat(item.percentage).toFixed(2)}%</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Panel>
              )
            }
          </React.Fragment>
        )
      } else {
        return (
          <div></div>
        )
      }

    case 'DROPDOWN_WITH_TEXT_QUESTION_TYPE':
      return (
        <div className="todo__description">
          {/* <Response answers={answers} /> */}
        </div>
      )

    case 'COMMENT':
      return (
        <Panel
          md={question.cols}
          lg={question.cols}
          xl={question.cols}
          sm={12}
          xs={12}
        >
          <div className="todo__description">
            <p>{question.comment}</p>
          </div>
        </Panel>
      )

    default:
      return (
        <div className="todo__description">
          {/* <Response answers={answers} /> */}
        </div>
      )
  }
}


const NumberQuestionType = ({ question, col, label }) => {
  switch (question.checksum) {
    case 'a078ca43a4a399caa8470917bab6822a':
      return (
        <Panel lg={col} xl={col} md={col} xs={12} title={label}>
          <div className="todo__description">
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                  <strong><NumericFormat
                    displayType="text"
                    value={question.average}
                    thousandsGroupStyle="thousand"
                    thousandSeparator=" "
                    decimalScale={0}
                    decimalSeparator=","
                    suffix={' M²'}
                  /></strong>
                </p>
              </div>
              <div className="mobile-app-widget__title">
                <h5>En moyenne</h5>
              </div>
            </div>
          </div>
        </Panel>
      )

    default:
      if (question.checksum === '2b68081ca856b670991ccdbb035fa0b1' || question.checksum === '09c12aa173f58fb8045e68bf91469d53' || question.checksum === '100d5a55044809b04012c9e86c10990a') {
        return (
          <Panel lg={question.cols} xl={question.cols} md={question.cols} xs={12} title={label}>
            <div className="todo__description">
              <div className="mobile-app-widget">
                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                  <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                    <strong><NumericFormat
                      displayType="text"
                      value={question.average}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=" "
                      decimalScale={2}
                      decimalSeparator=","
                    /></strong>
                  </p>
                </div>
                <div className="mobile-app-widget__title">
                  <h5>En moyenne</h5>
                </div>
              </div>
            </div>
          </Panel>
        )
      } else {
        return (
          <Panel lg={question.cols} xl={question.cols} md={question.cols} xs={12} title={label}>
            <div className="todo__description">
              <div className="mobile-app-widget">
                <div className="mobile-app-widget__top-line mobile-app-widget__top-line--turquoise">
                  <p className="mobile-app-widget__total-stat" style={{ fontSize: '56px' }}>
                    <strong><NumericFormat
                      displayType="text"
                      value={question.average}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=" "
                      decimalScale={0}
                      decimalSeparator=","
                    /></strong>
                  </p>
                </div>
                <div className="mobile-app-widget__title">
                  <h5>En moyenne</h5>
                </div>
              </div>
            </div>
          </Panel>
        )
      }
  }
}