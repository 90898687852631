import React, { Fragment } from 'react';
import moment from 'moment';
import {
  // Bar, 
  CartesianGrid, 
  ComposedChart, 
  Line, 
  ResponsiveContainer, 
  Tooltip, 
  XAxis, 
  YAxis
} from 'recharts';
import { Table } from 'reactstrap';
import Panel from '../../../../shared/components/Panel';
import StatisticsTooltipContent from './StatisticsTooltipContent';

moment.locale("fr");

const labels = {
  'total_clicks_contact': 'Bouton contacter',
  'total_clicks_contact_requests': 'Bouton de demande de RDV',
  'total_clicks_preorders_requests': `Bouton de pré-commande`,
  'total_clicks_sample_requests': `Demande d'échantillon`
}

const colors = [
  '#48b5ff', 
  '#fb6207',
  '#ce94fb',
  '#073afb',
  '#b8e986', 
  '#00f0bc', 
  '#fbd307', 
];

export default ({ stats }) => {
  
  const graphData = Object.keys(stats.graph).map(item => {
    return {
      key: item,
      name: moment.unix(item).format('MMMM YYYY'),
      brand_page: stats.graph[item]['Menu : marque'],
      products_page: stats.graph[item]['Menu : produits'],
      team_page: stats.graph[item]['Menu : équipe'],
      total_clicks_contact: stats.graph[item].total_clicks_contact,
      total_clicks_contact_requests: stats.graph[item].total_clicks_contact_requests,
      total_clicks_preorders_requests: stats.graph[item].total_clicks_preorders_requests,
      total_clicks_sample_requests: stats.graph[item].total_clicks_sample_requests,
      Facebook: stats.graph[item].Facebook,
    }
  });

  const tableData = Object.keys(stats.events).map((item, index) => {
    return {
      id: index,
      head: labels[item] || item,
      data: Object.keys(stats.events[item].dates).map(i => stats.events[item].dates[i])
    }
  });
  
  return (
    <Panel
      xl={12}
      lg={12}
      md={12}
      title={`Statistiques de clicks`}
      subhead=""
    >
      <div>
        <ResponsiveContainer height={260}>
          <ComposedChart data={graphData} margin={{ top: 20, left: -15 }}>
            <XAxis dataKey="name" tickLine={true} padding={{ left: 20 }} />
            <YAxis tickLine={true} />
            <Tooltip content={<StatisticsTooltipContent />} />
            <CartesianGrid vertical={true} />
            
            <Line type="linear" name="Bouton contacter" dataKey="total_clicks_contact" stroke={colors[0]} />
            <Line type="linear" name="Bouton de demande de RDV" dataKey="total_clicks_contact_requests" stroke={colors[1]} />
            <Line type="linear" name="Demande d'échantillon" dataKey="total_clicks_sample_requests" stroke={colors[2]} />
            <Line type="linear" name="Bouton de pré-commande" dataKey="total_clicks_preorders_requests" stroke={colors[3]} />
            <Line type="linear" name="Menu: équipe" dataKey="team_page" stroke={colors[4]} />
            <Line type="linear" name="Menu: marque" dataKey="brand_page" stroke={colors[5]} />
            <Line type="linear" name="Menu: produits" dataKey="products_page" stroke={colors[6]} />

          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <hr />
      <div>
        <Table striped responsive>
          <tbody>
            <tr>
              <td></td>
              {
                graphData.map((item, index) => {
                  return (
                    <td key={`general-month-${index}`} style={{ textAlign: 'right' }}>
                      <strong>{item.name}</strong>
                    </td>
                  )
                })
              }
            </tr>
            {tableData.map((items, index) => (
              <tr key={`global-row-stat-${index}`}>
                <td className="td-head" style={{ textAlign: 'right' }}>
                  <strong>{items.head}</strong>
                  <span style={{
                    background: colors[index],
                    display: 'inline-block',
                    padding: '.2rem .5rem',
                    marginLeft: '.3rem'
                  }}>
                  </span>
                </td>
                <Fragment>
                  {items.data.map((item, i) => (
                    <td
                      key={`global-stat-${i}`}
                      style={{ textAlign: 'right' }}
                    >
                      {item}
                    </td>
                  ))}
                </Fragment>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};