import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import moment from 'moment';
import Loading from '../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';

class Bookmarks extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    lists: [],
    currentList: null,
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.formUpdate = React.createRef();

    this.handleListCreate = this.handleListCreate.bind(this);
    this.handleListUpdate = this.handleListUpdate.bind(this);
    this.handleListDelete = this.handleListDelete.bind(this);
    this.handleListOpen = this.handleListOpen.bind(this);
    this.handleListExport = this.handleListExport.bind(this);
    this.handleShopOpen = this.handleShopOpen.bind(this);
  }

  _formToJSON(f) {
    const inputs = f.querySelectorAll('input, select, textarea');
    let payload = {};

    for (let i = 0; i < inputs.length; i++) {
      const k = inputs[i].getAttribute('name');
      const v = inputs[i].value;
      payload[k] = v;
    }

    return payload;
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/bookmarks/list`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState(
          {
            loaded: true,
            loading: false,
            lists: res.data.content.lists,
            currentList: this.props.match.params.id
              ? res.data.content.lists.find(
                  (l) => l.pk == this.props.match.params.id
                )
              : res.data.content.lists[0],
          },
          () => {
            axios
              .post(
                `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/subscription/get`,
                {
                  id: user.id,
                  token: user.token,
                  passphrase: user.passphrase,
                }
              )
              .then((res) => {
                this.setState({
                  subscription: res.data.content.subscription,
                });
              });
          }
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.match.params.id
    ) {
      const {
        authentication: { user },
      } = this.props;

      if (this.state.lists.some((l) => l.pk == this.props.match.params.id))
        this.setState({
          currentList: this.state.lists.find(
            (l) => l.pk == this.props.match.params.id
          ),
        });
    }
  }

  handleListCreate() {
    const newName = prompt(
      'Veuillez renseigner un nom pour votre nouvelle liste : '
    );

    if (!newName) return;

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/bookmark-lists`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        name: newName,
      })
      .then((res) => {
        this.setState({
          loaded: true,
          loading: false,
          lists: [
            ...this.state.lists,
            { ...res.data.content.list, bookmarks: [] },
          ],
          currentList: { ...res.data.content.list, bookmarks: [] },
        });

        this._showNotification('Votre liste de favoris a bien été créée.');
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  handleListUpdate() {
    const fields = this._formToJSON(this.formUpdate.current);

    if (!fields.name) return;

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/bookmark-lists/update`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        name: fields.name,
        listId: this.state.currentList.pk,
      })
      .then((res) => {
        this.setState({
          loaded: true,
          loading: false,
          lists: this.state.lists.map((l) =>
            l.pk == this.state.currentList.pk ? { ...l, name: fields.name } : l
          ),

          currentList: { ...this.state.currentList, name: fields.name },
        });

        this._showNotification(
          'Votre liste de favoris a bien été enregistrée.'
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  handleListDelete() {
    Swal.fire({
      title: 'Attention',
      text: `La suppression d'une liste de favoris est irréversible, voulez-vous continuer ?`,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: `Annuler`,
    }).then((result) => {
      if (result.isDenied) return;
      if (!result.isConfirmed) return;

      this.setState({ loading: true, loaded: false });

      const {
        authentication: { user },
      } = this.props;
      let endpoint = `finder/bookmark-lists/delete`;

      axios
        .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
          listId: this.state.currentList.pk,
        })
        .then((res) => {
          this.setState({
            loaded: true,
            loading: false,
            lists: this.state.lists.filter(
              (l) => l.pk != this.state.currentList.pk
            ),
            currentList: null,
          });

          this._showNotification(
            'Votre liste de favoris a bien été supprimée.'
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            loaded: true,
            loading: false,
          });
        });
    });
  }

  handleListOpen() {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/?bookmark_list=${this.state.currentList.pk}`,
      '_target=blank'
    );
  }

  handleShopOpen(id) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/${id}-.html`,
      '_target=blank'
    );
  }

  handleListExport() {
    if (this.state.subscription.plan.name === 'Essential') return;

    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/bookmark-lists/${this.state.currentList.pk}/export`;

    axios
      .post(
        `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`,
        {
          id: user.id,
          token: user.token,
          passphrase: user.passphrase,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        this.setState(
          {
            loaded: true,
            loading: false,
          },
          () => {
            this._showNotification(
              'Votre liste de favoris a bien été exportée.'
            );

            const blob = new Blob([res.data], { type: 'text/csv' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = downloadUrl;
            anchor.click();

            // Close the download URL
            window.URL.revokeObjectURL(downloadUrl);
          }
        );
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  render() {
    let { loaded, loading, lists, subscription, currentList } = this.state;
    const listId = currentList ? currentList.pk : null;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {loaded && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <h3 className="page-title">Mes listes de favoris</h3>
              </Col>
            </Row>
            <Row>
              <div className="as-messenger">
                <div
                  className={`as-left ${lists.length == 0 ? 'as-empty' : ''}`}
                >
                  <div className="as-lists-wrapper">
                    {lists.map((l, idx) => (
                      <Link
                        key={idx}
                        className={`as-member as-bookmark-list ${
                          l.pk == listId ? 'active' : ''
                        }`}
                        to={`/finder/bookmarks/${l.pk}`}
                      >
                        <div className="as-member-header">
                          <span className="sender">
                            <strong>
                              {l.name.replace('@DEFAULT@', 'Tous les favoris')}
                            </strong>
                          </span>
                        </div>
                      </Link>
                    ))}
                  </div>

                  {subscription &&
                    (subscription.plan.name !== 'Essential' ||
                      (subscription.plan.name === 'Essential' &&
                        lists.length < 3)) &&
                    lists.length < 10 && (
                      <div className="as-footer-widget">
                        <button
                          onClick={this.handleListCreate}
                          type="button"
                          className="btn btn-primary"
                        >
                          Créer une nouvelle liste
                        </button>
                      </div>
                    )}
                </div>
                <div className="as-right">
                  {/* EMPTY FULL STATE */}
                  {lists.length > 0 && !currentList && subscription && (
                    <p className="placeholder">
                      Veuillez sélectionner une liste dans le volet gauche
                    </p>
                  )}
                  {/* SINGLE PICKED / UPDATE FORM STATE */}
                  {currentList && subscription && (
                    <form
                      className="as-member-panel"
                      onSubmit={this.handleListUpdate}
                      ref={this.formUpdate}
                      key={currentList.pk}
                    >
                      <div className="as-member-body">
                        <h3>
                          {currentList.name.replace(
                            '@DEFAULT@',
                            'Tous les favoris'
                          )}
                        </h3>
                        <div className="as-form">
                          <div>
                            <label htmlFor="name">Nom de la liste</label>
                            <input
                              id="name"
                              className="as-field"
                              type="text"
                              name="name"
                              defaultValue={currentList.name.replace(
                                '@DEFAULT@',
                                'Tous les favoris'
                              )}
                              disabled={
                                currentList.is_writable == true ? false : true
                              }
                            />
                          </div>
                        </div>
                        <div className="as-bookmarks">
                          {currentList.bookmarks.length > 0 && (
                            <div className="collapse show">
                              <div className="panel__content">
                                <div className="table-responsive">
                                  <table className="dashboard__table-orders notStripped table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Commerce</th>
                                        <th>Téléphone</th>
                                        <th>Ville</th>
                                        <th>Note d'image</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {currentList.bookmarks.map((s) => (
                                        <tr
                                          key={s.id}
                                          onClick={() =>
                                            this.handleShopOpen(s.id)
                                          }
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <td className="dashboard__table-orders-title">
                                            <div className="dashboard__table-orders-img-wrap">
                                              <div
                                                className="dashboard__table-orders-img"
                                                style={{
                                                  backgroundImage: `url(${s.image_src})`,
                                                }}
                                              ></div>
                                            </div>
                                            <span className="catalog-item__link">
                                              {s.name}
                                            </span>
                                          </td>
                                          <td style={{ width: '25%' }}>
                                            <span className="catalog-item__link">
                                              {s.phone}
                                            </span>
                                          </td>
                                          <td style={{ width: '15%' }}>
                                            <span className="catalog-item__link">
                                              {s.locality}
                                            </span>
                                          </td>
                                          <td
                                            className="dashboard__table-orders-total"
                                            style={{ width: '15%' }}
                                          >
                                            <div className="dashboard__table-orders-amount">
                                              {[
                                                ...Array(
                                                  (parseInt(s.image || 0) + 1 >
                                                  5
                                                    ? 5
                                                    : parseInt(s.image || 0) +
                                                      1) * 2
                                                ).keys(),
                                              ].map((k) => (
                                                <div key={k}></div>
                                              ))}
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                          {currentList.bookmarks.length === 0 && (
                            <p className="as-bookmark-placeholder">
                              Vous n'avez aucun favoris dans cette liste
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="as-member-controls">
                        {currentList.is_writable == true && (
                          <button
                            onClick={this.handleListDelete}
                            className="btn btn-danger"
                            type="button"
                          >
                            Supprimer
                          </button>
                        )}
                        {currentList.bookmarks.length > 0 && (
                          <button
                            className="btn btn-primary"
                            onClick={this.handleListOpen}
                            type="button"
                          >
                            Ouvrir dans Finder
                          </button>
                        )}
                        {currentList.is_writable == true && (
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={this.handleListUpdate}
                          >
                            Enregistrer
                          </button>
                        )}

                        <button
                          className="btn btn-primary as-has-tooltip"
                          type="button"
                          onClick={this.handleListExport}
                        >
                          <i className="as-tooltip">
                            {subscription.plan.name !== 'Essential'
                              ? 'Dans la limite de 50 caves exportées'
                              : 'Utilisable en passant au plan supérieur'}
                          </i>
                          Exporter sur Excel
                        </button>
                      </div>
                    </form>
                  )}
                  {/* EMPTY VOID UNSUBSCRIBED STATE */}
                  {!subscription && (
                    <div className="placeholder-wrapper">
                      <p className="placeholder">
                        Vous n'avez aucun abonnement à Finder pour l'instant.
                      </p>
                      <br />
                      <a
                        href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                        className="btn btn-primary"
                      >
                        Je m'abonne
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Bookmarks);
