import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row, Button, Card, CardBody } from 'reactstrap';
import moment from 'moment';
moment.locale('fr');
import Loading from '../../../App/Loading';
import Link from 'react-router-dom/Link';
import NotificationSystem from 'rc-notification';
import Swal from 'sweetalert2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class Import extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
  };

  constructor(props) {
    super(props);

    this.goToFileImport = this.goToFileImport.bind(this);
    this.goToCopyPasteImport = this.goToCopyPasteImport.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
          loaded: true,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  goToFileImport() {
    this.props.history.push('/finder/intelligence/import/file');
  }

  goToCopyPasteImport() {
    this.props.history.push('/finder/intelligence/import/copypaste');
  }

  render() {
    let { loaded, loading, subscription } = this.state;

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded && subscription && subscription.team.is_subscribed == true && (
          <React.Fragment>
            {/* 1ST ROW */}
            <Row>
              <Col>
                <Row>
                  <Col md={6}>
                    <h3 className="page-title">Mes bases > Import</h3>
                  </Col>
                  <Col md={6} align="right">
                    <Button
                      color="primary"
                      outline
                      size="sm"
                      onClick={() => {
                        this.props.history.push('/finder/intelligence');
                      }}
                    >
                      Retour
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* MAIN CONTENT */}
            <Row>
              <Col>
                <Row>
                  <Col md={12}>
                    <p className="as-import-intro">
                      Ajoutez des caves en masse en téléchargeant un fichier ou
                      par simple copier-coller depuis un fichier.{' '}
                    </p>
                    <Row className="as-import-options">
                      {/* Option 1 */}
                      <Col md={6}>
                        <Card
                          className="as-import-option"
                          onClick={this.goToFileImport}
                        >
                          <CardBody className="dashboard__card-widget">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>

                              <div className="card__title">
                                <h5 className="bold-text">
                                  Importer un fichier
                                </h5>
                              </div>
                              <div className="card__body">
                                <p>
                                  Sélectionnez un fichier .csv, .xls ou .txt sur
                                  votre ordinateur.
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      {/* Option 2 */}
                      <Col md={6}>
                        <Card
                          className="as-import-option"
                          onClick={this.goToCopyPasteImport}
                        >
                          <CardBody className="dashboard__card-widget">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                />
                              </svg>

                              <div className="card__title">
                                <h5 className="bold-text">Copier-coller</h5>
                              </div>
                              <div className="card__body">
                                <p>
                                  Copiez et collez les caves de votre fichier
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Import);
