import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InvoiceTableHead from './InvoiceTableHead';
import InvoiceTableToolbar from './InvoiceTableToolbar';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

export default class InvoiceTable extends PureComponent {
  state = {
    order: 'asc',
    orderBy: 'id',
    selected: new Map([]),
    data: [],
    page: 0,
    rowsPerPage: 10,
  };

  componentDidMount() {
    this.setState({
      data: this.props.data.map((item, index) => {
        return {
          id: item.id,
          link: item.link,
          created_at: item.created_at,
          number: item.number,
        }
      })
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') { order = 'asc'; }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    if (typeof window !== 'undefined') {
      window.open(id)
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  render() {
    const {
      data, order, orderBy, selected, rowsPerPage, page,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));
    
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h3 className="page-title">{this.props.title}</h3>
            </div>
            <InvoiceTableToolbar
              numSelected={[...selected].filter(el => el[1]).length}
              handleDeleteSelected={this.handleDeleteSelected}
              onRequestSort={this.handleRequestSort}
            />
            <div className="material-table__wrap">
              <Paper>
                <TableContainer>
                  <Table className="material-table">
                    <InvoiceTableHead
                      numSelected={[...selected].filter(el => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                    />
                    <TableBody>
                      {data
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((d) => {
                          const isSelected = this.isSelected(d.id);
                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              onClick={event => this.handleClick(event, d.link)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={d.id}
                              selected={isSelected}
                            >
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.number}
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.created_at}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <TablePagination
              component="div"
              className="material-table__pagination"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Page précédente' }}
              nextIconButtonProps={{ 'aria-label': 'Page suivante' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 100]}
              dir="ltr"
              labelRowsPerPage={''}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
              SelectProps={{
                inputProps: { 'aria-label': 'Lignes par page' },
                native: false,
              }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}
