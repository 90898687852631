import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import TablePreordersFilterButton from './TablePreordersFilterButton';

const TablePreordersToolbar = ({ onRequestSort }) => (
  <div className="material-table__toolbar-wrap">
    <Toolbar className="material-table__toolbar">
      <div>
        <TablePreordersFilterButton onRequestSort={onRequestSort} />
      </div>
    </Toolbar>
  </div>
);

TablePreordersToolbar.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
};

export default TablePreordersToolbar;
