import React from 'react';
import {
  Card, CardBody, Col, Row, Button, Table
} from 'reactstrap';
// import { StripeProvider, Elements } from 'react-stripe-elements';
// import CheckoutForm from './CheckoutForm';
// import CardBasic from './CardBasic';
// import CardPremium from './CardPremium';

const Summary = ({
  user,
  canceled,
  // current_plan,
  // invoices,
  // is_free,
  // plan_data,
  // plans,
  subscription,
  subscriptionid,
  cancel,
  loadingCancel,
  // changeSubscription
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="project-summary">
                <p className="typography-message">
                  Veuillez trouver les informations concernant l'abonnement auquel vous avez souscrit. Vous pouvez le modifier à tout instant.<br />
                  Vous pouvez aussi résilier votre abonnement sans frais.
                </p>
                <p className="typography-message">
                  <strong>Astuce</strong> : Les plans que nous proposons évoluent constamment
                  pour apporter chaque fois plus de services. Nous vous encourageons à les regarder de temps en temps pour re-choisir le plan qui répond le mieux à vos besoins.
                </p>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>

      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody style={{
              opacity: canceled ? '.3' : 1
            }}>
              <div className="project-summary">
                <div className="card__title project-summary__card-title">
                  <h5 className="bold-text">Votre abonnement actuel</h5>
                </div>
              </div>
              <div className="product-card">
                <div className="product-card__info">
                  <h1 className="product-card__title">{subscription.plan_details.name} ({subscription.plan_details.interval})</h1>
                  <h3 className="product-card__price">{subscription.plan_amount}<sup>(ht)</sup></h3>
                </div>
                <p>
                  {
                    !canceled && (
                      <a href={`${process.env.REACT_APP_BOTTL_WEBROOT}/brandpages-onboarding/change/${subscriptionid}/`}>
                        Changer d'abonnement
                      </a>
                    )
                  }
                  {/* {
                    !canceled && (
                      <React.Fragment>
                        {` `}| <a href={`${process.env.REACT_APP_BOTTL_WEBROOT}/brandpages-onboarding/change/${subscriptionid}/`}>
                          Changer de moyen de paiement
                        </a>
                      </React.Fragment>
                    )
                  } */}
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody style={{
              opacity: canceled ? '.3' : 1
            }}>
              <StripeProvider apiKey="pk_live_cxkcfQane9foSynrdGPaQe1R00JaC85ZOc">
                <Elements>
                  <CheckoutForm
                    user={user}
                    subscription={subscription}
                  />
                </Elements>
              </StripeProvider>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="project-summary">
                <div className="card__title project-summary__card-title">
                  <h5 className="bold-text">Liste de mes dernières factures</h5>
                </div>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <td>Montant</td>
                      <td>Statut</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {subscription._invoices.map((item, index) => {
                      return (
                      <tr key={index}>
                          <td>{item.status === 'paid' ? item.amount_paid_format : item.amount_due_format} €</td>
                        <td>
                          {item.status === 'paid' ? 'Payée' : (
                            <React.Fragment>
                              En attente de paiement...
                              <a 
                                href={item.hosted_invoice_url} 
                                target="_blank"
                                className="small-row-buttons"
                              >
                                Payer cette facture
                              </a>
                            </React.Fragment>
                          )
                          }
                        </td>
                        <td><a href={item.invoice_pdf} className="small-row-buttons" target="_blank">Télécharger</a></td>
                      </tr>
                    )})}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>

      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="project-summary">
                <div className="card__title project-summary__card-title">
                  <h5 className="bold-text">Résiliation</h5>
                </div>
                {
                  canceled ? (
                    <Button disabled>
                      Résilier mon abonnement {subscription.plan_details.name}
                    </Button>
                  ) : (
                    <Button 
                      onClick={cancel} 
                      color="danger"
                    >
                      {
                        loadingCancel 
                        ? 'Traitement en cours...' 
                        : `Résilier mon abonnement ${subscription.plan_details.name}`
                      }
                    </Button>
                  )
                }
              </div>
            </CardBody>
          </Card>

        </Col>
      </Row>
    </React.Fragment>
  )
};

export default Summary;
