/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { ResponsiveContainer } from 'recharts';
import {
  VerticalGridLines,
  HorizontalGridLines,
  HorizontalBarSeries,
  XAxis,
  FlexibleWidthXYPlot,
  YAxis
} from 'react-vis';
import Panel from '../../../../shared/components/Panel';

class HBars extends PureComponent {

  render() {
    const { data, label, type, cols } = this.props;
    
    data.sort((a, b) => {
      if ( a.x < b.x ){
        return -1;
      }
      if ( a.x > b.x ){
        return 1;
      }
      return 0;
    });

    return (
      <Panel lg={cols} xl={cols} md={cols} xs={12} title={label}>
        <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--big">
          <FlexibleWidthXYPlot
            yType="ordinal"
            height={300}
            width={window.innerWidth * 0.85} margin={{ left: 280 }} 
            stackBy="x"
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            {
              type === 'unit' ? (
                <XAxis 
                  tickValues={Array.from(Array(100).keys())}
                  tickFormat={function tickFormat(d){
                    return d !== 0 && `x${d}`
                  }}
                />
              ) : (
                <XAxis 
                  tickFormat={function tickFormat(d){
                    return `${d}%`
                  }}
                />
              )
            }
            <YAxis
              style={{
                text: {stroke: 'none', fill: '#6b6b76', fontWeight: 600}
              }}
            />
            <HorizontalBarSeries
              data={data}
              colorType="literal"
              color="#0088a3"
            />
          </FlexibleWidthXYPlot>
        </ResponsiveContainer>
      </Panel>
    );
  }
}

export default HBars;
