import React from 'react';
import moment from 'moment';
import 'moment/locale/fr'
import {
  CartesianGrid, 
  ComposedChart, 
  Line, 
  ResponsiveContainer, 
  Tooltip, 
  XAxis, 
  YAxis
} from 'recharts';
import Panel from '../../../../shared/components/Panel';
import StatisticsTooltipContent from './StatisticsTooltipContent';

moment.locale("fr");

const colors = ['#00f0bc', '#fbd307', '#b8e986', '#48b5ff', '#fb6207'];

export default ({ stats }) => {

  const graphData = Object.keys(stats.discover_general).map(item => {
    return {
      key: item,
      name: moment.unix(item).format('MMMM YYYY'),
      views: stats.discover_general[item].views,
      visitors: stats.discover_general[item].visitors,
      unique_visitors: stats.discover_general[item].unique_visitors,
      connected_visitors: stats.discover_general[item].connected_visitors,
    }
  });

  return (
    <Panel
      xl={12}
      lg={12}
      md={12}
      title={`Statistiques générales de Discover`}
      subhead=""
    >
      <div>
        <ResponsiveContainer height={260}>
          <ComposedChart data={graphData} margin={{ top: 20, left: -15 }}>
            <XAxis dataKey="name" tickLine={true} padding={{ left: 20 }} />
            <YAxis tickLine={true} />
            <Tooltip content={<StatisticsTooltipContent />} />
            <CartesianGrid vertical={true} />
            
            <Line type="linear" name="Pages vues" dataKey="views" stroke={colors[0]} />
            <Line type="linear" name="Visiteurs" dataKey="visitors" stroke={colors[1]} />
            <Line type="linear" name="Visiteurs uniques" dataKey="unique_visitors" stroke={colors[1]} />
            <Line type="linear" name="Visiteurs connectés" dataKey="connected_visitors" stroke={colors[2]} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
};
