/* eslint-disable react/no-array-index-key */
import React from 'react';
import axios from 'axios';
import NotificationSystem from 'rc-notification';
import { 
  Card, CardBody, Table, 
  Col, Button, ButtonToolbar
} from 'reactstrap';
import { BasicNotification } from '../../../shared/components/Notification';
import Alert from '../../../shared/components/Alert';
import Modal from '../../../shared/components/Modal';

let notifications = null;
const showNotification = ({notification}) => {
  notifications.notice({
    content: notification,
    duration: 15,
    closable: true,
    style: { top: 0, left: 'calc(100vw - 100%)' },
    className: `right-up`,
  });
};

class DevisDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      requested: false,
      openModal: false,
      signatureMessage: `Votre espace annonceur ne permet pas actuellement de savoir si vous avez signé ce devis de façon électronique.
      Si c'est le cas, un grand merci pour votre confiance (et nous allons insérer dans votre espace personnel le pdf de votre devis signé à l'instant).
      Si ce n'est pas le cas ou que vous rencontrez des difficultés, n'hésitez pas à nous contacter au 09 72 55 46 21 ou à prendre contact par chat.”`
    }
  }

  componentDidMount() {
    NotificationSystem.newInstance({ style: { top: 65 } }, n => notifications = n);
  }

  componentWillUnmount() {
    notifications.destroy();
  }

  requested = () => {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/user/dashboards/annonceurs/requested/`, {
      'simulationid': parseInt(this.props.data.id)
    }).then(res => {
      const data = res.data;
      if (data.success) {
        const message = `Merci d’avoir validé votre sélection de commerces et de nous avoir demandé un devis !
        Vous recevrez une notification dans votre boite email et dans votre espace personnel dès que le devis sera prêt.`
        this.showNotification(message);
        this.setState({requested: true});
      }
    })
  }

  sign = () => {
    const _self = this;
    
    setTimeout(function () { 
      _self.modalRef.props.toggle();
     }, 10000);
    
     if (typeof window !== 'undefined') {
      window.open(this.props.data.link);
    }
  }

  isRequested = () => {
    const r = this.props.data.requested;
    return (r !== null || r === 1);
  }

  isDeployed = () => {
    const d = this.props.data.deployed;
    const s = this.props.data.status;
    return (d && parseInt(s) < 2);
  }

  showNotification = (message) => {
    return showNotification({
      notification: <BasicNotification title="Informations" message={message} />
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          ignoreCancel
          color="primary"
          title="Merci!"
          btn="Default"
          isBtn={false}
          message={this.state.signatureMessage}
          modalRef={el => this.modalRef = el}
        />
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="project-summary">
                <h2 className="bold-text">Détails de votre simulation</h2>
                <table className="project-summary__info notStripped">
                  <tbody>
                    {
                      parseInt(this.props.data.status) === 2 && (
                        <tr>
                          <th><strong>Ce devis est signé. Retrouvez le dans votre espace "mes devis"</strong></th>
                          <td></td>
                        </tr>
                      )
                    }
                    <tr>
                      <th>Date de simulation: {this.props.data.devis_date}</th>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                {
                  ((this.props.data.deployed || this.isRequested()) && parseInt(this.props.data.status) < 2) && (
                    <Alert color="info">
                      <p>
                        {
                          this.isDeployed() ? (
                            <React.Fragment>
                              Le devis <strong>N°{this.props.data.nodevis}</strong> est disponible à la signature.
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              Votre devis est <strong>en cours de préparation.</strong>
                            </React.Fragment>
                          )
                        }
                      </p>
                    </Alert>
                  )
                }
                <div className="card__title project-summary__card-title">
                  {
                    (this.state.requested || this.isRequested()) ? (
                      <Button 
                        color="primary" 
                        disabled 
                        className="project-summary__btn" 
                        size="sm"
                        style={{marginLeft: 0}}
                      >
                        Demander un devis
                      </Button>
                    ) : (
                      <Button 
                        color="primary" 
                        className="project-summary__btn" 
                        size="sm" 
                        onClick={this.requested}
                        style={{marginLeft: 0}}
                      >
                        Demander un devis
                      </Button>
                    )
                  }
                  {
                    this.isDeployed() ? (
                      <Button 
                        color="primary" 
                        className="project-summary__btn" 
                        size="sm" 
                        onClick={this.sign}
                      >
                        Signer le devis
                      </Button>
                    ) : (
                      <Button 
                        color="primary" 
                        disabled 
                        className="project-summary__btn" 
                        size="sm"
                      >
                        Signer le devis
                      </Button>
                    )
                  }
                </div>
                <br />
                <h3>Présentation de l'enveloppe budgétaire</h3>
                <hr />
                <div className="project-summary__stats">
                  <div className="project-summary__stat">
                    <p>{this.props.data.window_count} <span>Vitrines</span></p>
                  </div>
                  {this.props.data.plan && (
                    <div className="project-summary__stat">
                      <p>{this.props.data.plan.locationMin}/5 <span>Emplacement</span></p>
                    </div>
                    )
                  }
                  {this.props.data.plan && (
                    <div className="project-summary__stat">
                      <p>{this.props.data.plan.imageMin}/5 <span>Image</span></p>
                    </div>
                    )
                  }
                </div>
                <React.Fragment>
                  <hr />
                  <Table className="table--bordered notStripped" responsive>
                    <tbody>
                      <tr style={{ border: 'none' }}>
                        <td>Réservation de</td>
                        <td>{this.props.data.window_count} vitrines</td>
                        <td rowSpan="7" style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '20%'
                        }}>
                          <h4>{this.props.data.display.total_location_vitrines} €</h4>
                        </td>
                      </tr>

                      <tr>
                        <td>Catégorie(s)</td>
                        { this.props.data.plan && ( <td>{this.props.data.plan.shopsCategory_tags}</td> ) }
                      </tr>

                      <tr>
                        <td>Lieu</td>
                        <td>{this.props.data.location}</td>
                      </tr>

                      <tr>
                        <td>Note d'emplacement</td>
                        { this.props.data.plan && ( <td>{this.props.data.plan.locationMin}/5</td> ) }
                      </tr>

                      <tr>
                        <td>Note d'image</td>
                        { this.props.data.plan && ( <td>{this.props.data.plan.imageMin}/5</td> ) }
                      </tr>

                      <tr>
                        <td>du</td>
                        <td>{this.props.data.date_from.formatted}</td>
                      </tr>

                      <tr>
                        <td>au</td>
                        <td>{this.props.data.date_to.formatted}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  { this.props.data.plan && ( <h4>Habillage: {this.props.data.plan.product_name}</h4> ) }
                  <br />
                  <Table className="table--bordered notStripped" responsive>
                    <tbody>
                      {
                        this.props.data.display.product_description.map((item, index) => {
                          return (
                            <tr key={`product-line-${index}`} style={{ border: 'none' }}>
                              <td>• {item}</td>
                              {
                                index === 0 && (
                                  <td rowSpan={this.props.data.display.product_description.length} style={{
                                    verticalAlign: 'middle',
                                    textAlign: 'center',
                                    width: '20%',
                                    border: 'none'
                                  }}>
                                    <h4>{this.props.data.display.total_habillage} €</h4>
                                  </td>
                                )
                              }
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  <hr />
                  <h4>Honoraires incluant: </h4>
                  <br />
                  <Table className="table--bordered notStripped" responsive>
                    <tbody>
                      <tr style={{ border: 'none' }}>
                        <td>• Recrutement de commerces</td>
                        <td rowSpan="6" style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          width: '20%'
                        }}>
                          <h4>{this.props.data.display.total_honoraires} €</h4>
                        </td>
                      </tr>

                      <tr>
                        <td>• Plans techniques de vitrines</td>
                      </tr>

                      <tr>
                        <td>• Gestion des BAT</td>
                      </tr>

                      <tr>
                        <td>• Photos témoins</td>
                      </tr>

                      <tr>
                        <td>• Gestion de campagne</td>
                      </tr>

                      <tr>
                        <td>• Rapport de campagne</td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr />
                  <h4>Options: </h4>
                  <br />
                  {
                    Object.keys(this.props.data.options_array).length > 0 && (
                      <React.Fragment>
                        {
                          Object.keys(this.props.data.options_array).map((i, index) => {
                            if (this.props.data.options_array[i].id === 2) {
                              return (
                                <Table className="table--bordered notStripped" responsive key={`option-${index}`}>
                                  <tbody>
                                    <tr>
                                      <td>{this.props.data.options_array[i].label}</td>
                                      <td rowSpan="1" style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        width: '20%',
                                        border: 'none'
                                      }}>
                                        <h4>{this.props.data.options_array[i].price}</h4>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              )
                            } else {
                              return (
                                <Table className="table--bordered notStripped" responsive key={`option-${index}`}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {this.props.data.options_array[i].label}{` `}
                                        ({this.props.data.options_array[i].quantity})
                                      </td>
                                      <td rowSpan="1" style={{
                                        verticalAlign: 'middle',
                                        textAlign: 'center',
                                        width: '20%',
                                        border: 'none'
                                      }}>
                                        {
                                          this.props.data.options_array[i].quantity > 0 ? (
                                            <h4>{this.props.data.options_array[i].price_raw}.00 €</h4>
                                          ) : (
                                            <h4>0.00 €</h4>
                                          )
                                        }
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              )
                            }
                          })
                        }
                      </React.Fragment>
                    )
                  }
                  <hr />
                  <div className="invoice__total">
                    <p className="invoice__grand-total">
                      Total: {this.props.data.price_without_options} €
                    </p>
                    <ButtonToolbar className="invoice__toolbar">
                      {
                        (this.state.requested || this.isRequested()) ? (
                          <Button color="primary" disabled>Demander un devis</Button>
                        ) : (
                          <Button 
                            color="primary" 
                            onClick={this.requested}
                          >
                            Demander un devis
                          </Button>
                        )
                      }
                      {
                        this.isDeployed() ? (
                          <Button color="primary" onClick={this.sign} >Signer le devis</Button>
                        ) : (
                          <Button color="primary" disabled>Signer le devis</Button>
                        )
                      }
                    </ButtonToolbar>
                  </div>
                </React.Fragment>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

export default DevisDetails;
