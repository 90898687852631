import React from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Swal from 'sweetalert2';
import axios from 'axios';

class ProfileMain extends React.Component {
  constructor(props) {
    super(props);

    this.avatarPicker = React.createRef();

    this.openAvatarPicker = this.openAvatarPicker.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
  }

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  openAvatarPicker() {
    this.avatarPicker.current.click();
  }

  handleAvatarChange(evt) {
    evt.preventDefault();

    const fp = this.avatarPicker.current;

    if (fp.files.length === 0) return;

    const file = fp.files[0];

    if (file.size / (1024 * 1024) > 4) {
      this._showError(
        'Veuillez choisir un fichier ne dépassant pas 4 mégaoctets.'
      );
      return;
    }

    if (!['jpg', 'png'].includes(file.name.split('.').at(-1))) {
      this._showError('Veuillez choisir un fichier au format jpg, ou png');
      return;
    }

    const user = JSON.parse(localStorage.getItem('user'));

    let fd = new FormData();
    fd.append('file', file);
    fd.append('id', user.id);
    fd.append('token', user.token);
    fd.append('passphrase', user.passphrase);

    axios
      .post(`${process.env.REACT_APP_WEBROOT}/api/user/change-avatar/`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((r) => {
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user, avatar: r.data.avatar })
        );
        window.location.reload();
      });
  }

  render() {
    const { data } = this.props;

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__avatar">
                <input
                  style={{ display: 'none' }}
                  type="file"
                  ref={this.avatarPicker}
                  onChange={this.handleAvatarChange}
                  accept=".jpg,.png"
                />
                <img
                  style={{ cursor: 'pointer' }}
                  src={`${data.infos.avatar}`}
                  alt="avatar"
                  onClick={this.openAvatarPicker}
                />
              </div>
              <div className="profile__data">
                <p className="profile__name">{`${data.infos.firstname} ${data.infos.lastname}`}</p>
                {data.infos.fonction && (
                  <p className="profile__work">{`${data.infos.fonction}`}</p>
                )}
                <p className="profile__contact">{`${data.infos.email}`}</p>
                <p className="profile__contact" dir="ltr">
                  <NumberFormat
                    value={data.infos.phone}
                    displayType={'text'}
                    format="## ## ## ## ##"
                  />
                </p>
                {/* <Button color="primary" className="icon profile__btn"><p><MessageTextOutlineIcon /> Message</p></Button> */}
              </div>
            </div>
            <div className="profile__stats">
              <div className="profile__stat">
                <p className="profile__stat-number">{data.campaigns.pending}</p>
                <p className="profile__stat-title">
                  Campagne{data.campaigns.pending > 1 ? 's' : ''} en cours
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">
                  {data.campaigns.completed}
                </p>
                <p className="profile__stat-title">
                  Campagne{data.campaigns.completed > 1 ? 's' : ''} terminée
                  {data.campaigns.completed > 1 ? 's' : ''}
                </p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">{data.simulations}</p>
                <p className="profile__stat-title">
                  Simulation{data.simulations > 1 ? 's' : ''}
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default ProfileMain;
